/* eslint-disable unicorn/no-null */
export const StaticModuleLinkedContent = {
    entities: [
        {
            id: 1,
            type: "imageGallery",
            content_id: 1,
            label: "Gallery - Kerry",
            title_copy_id: 1,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2019-12-03T14:00:00.000Z",
            forceLarge: false,
            createdAt: "2019-12-18T05:53:59.000Z",
            updatedAt: "2020-02-26T04:54:16.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 6,
                    contentId: 1,
                    tagId: 3,
                    createdAt: "2020-02-05T22:46:35.000Z",
                    updatedAt: "2020-02-05T22:46:35.000Z",
                    tag: {
                        id: 3,
                        tag_id: "image",
                        label: "Image",
                        createdAt: "2019-12-18T03:20:02.000Z",
                        updatedAt: "2019-12-18T03:20:02.000Z",
                    },
                },
                {
                    id: 7,
                    contentId: 1,
                    tagId: 9,
                    createdAt: "2020-02-05T22:46:44.000Z",
                    updatedAt: "2020-02-05T22:46:44.000Z",
                    tag: {
                        id: 9,
                        tag_id: "kerry",
                        label: "Kerry",
                        createdAt: "2020-02-04T01:25:00.000Z",
                        updatedAt: "2020-02-04T01:25:00.000Z",
                    },
                },
                {
                    id: 9,
                    contentId: 1,
                    tagId: 14,
                    createdAt: "2020-02-05T22:46:53.000Z",
                    updatedAt: "2020-02-05T22:46:53.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 225,
                    contentId: 1,
                    tagId: 26,
                    createdAt: "2020-02-26T04:54:21.000Z",
                    updatedAt: "2020-02-26T04:54:21.000Z",
                    tag: {
                        id: 26,
                        tag_id: "gallery",
                        label: "Gallery",
                        createdAt: "2020-02-26T04:50:05.000Z",
                        updatedAt: "2020-02-26T04:50:05.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 1,
                label: "Gallery - Kerry",
                createdAt: "2019-12-18T05:53:19.000Z",
                updatedAt: "2020-02-26T04:54:09.000Z",
                imageGalleryImages: [
                    {
                        id: 13,
                        imageGalleryId: 1,
                        imageId: 13,
                        createdAt: "2020-02-26T04:47:54.000Z",
                        updatedAt: "2020-02-26T04:47:54.000Z",
                        image: {
                            id: 13,
                            url: null,
                            fileId: 198,
                            thumbnailFileId: 199,
                            label: "gallery_kerry_1",
                            createdAt: "2020-02-26T04:32:35.000Z",
                            updatedAt: "2020-02-26T04:36:13.000Z",
                            thumbnailFile: {
                                id: 199,
                                size: 31924,
                                path: require("../images/Files/gallery_kerry_1_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"1c191ca84ef3942f250a758796c580b3"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:36:12.000Z",
                                updatedAt: "2020-02-26T04:36:12.000Z",
                            },
                            file: {
                                id: 198,
                                size: 133130,
                                path: require("../images/Files/gallery_kerry_1.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"e03796d42ff7fc0bbab1a041fc12e9eb"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:36:07.000Z",
                                updatedAt: "2020-02-26T04:36:07.000Z",
                            },
                        },
                    },
                    {
                        id: 14,
                        imageGalleryId: 1,
                        imageId: 14,
                        createdAt: "2020-02-26T04:47:56.000Z",
                        updatedAt: "2020-02-26T04:47:56.000Z",
                        image: {
                            id: 14,
                            url: null,
                            fileId: 201,
                            thumbnailFileId: 200,
                            label: "gallery_kerry_2",
                            createdAt: "2020-02-26T04:32:38.000Z",
                            updatedAt: "2020-02-26T04:36:31.000Z",
                            thumbnailFile: {
                                id: 200,
                                size: 28245,
                                path: require("../images/Files/gallery_kerry_2_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"e9bb5131cf87a41237662e3da4978576"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:36:26.000Z",
                                updatedAt: "2020-02-26T04:36:26.000Z",
                            },
                            file: {
                                id: 201,
                                size: 122063,
                                path: require("../images/Files/gallery_kerry_2.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"1fba03c869b2a43a046133dd716f1840"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:36:29.000Z",
                                updatedAt: "2020-02-26T04:36:29.000Z",
                            },
                        },
                    },
                    {
                        id: 15,
                        imageGalleryId: 1,
                        imageId: 15,
                        createdAt: "2020-02-26T04:47:59.000Z",
                        updatedAt: "2020-02-26T04:47:59.000Z",
                        image: {
                            id: 15,
                            url: null,
                            fileId: 203,
                            thumbnailFileId: 202,
                            label: "gallery_kerry_3",
                            createdAt: "2020-02-26T04:32:41.000Z",
                            updatedAt: "2020-02-26T04:36:45.000Z",
                            thumbnailFile: {
                                id: 202,
                                size: 34701,
                                path: require("../images/Files/gallery_kerry_3_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"c844101996ef8d973cd3c68aaeac585b"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:36:42.000Z",
                                updatedAt: "2020-02-26T04:36:42.000Z",
                            },
                            file: {
                                id: 203,
                                size: 152243,
                                path: require("../images/Files/gallery_kerry_3.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"f68d17b7a9c9182ccbb238843b80ee82"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:36:44.000Z",
                                updatedAt: "2020-02-26T04:36:44.000Z",
                            },
                        },
                    },
                    {
                        id: 16,
                        imageGalleryId: 1,
                        imageId: 16,
                        createdAt: "2020-02-26T04:48:02.000Z",
                        updatedAt: "2020-02-26T04:48:02.000Z",
                        image: {
                            id: 16,
                            url: null,
                            fileId: 204,
                            thumbnailFileId: 205,
                            label: "gallery_kerry_4",
                            createdAt: "2020-02-26T04:32:43.000Z",
                            updatedAt: "2020-02-26T04:36:57.000Z",
                            thumbnailFile: {
                                id: 205,
                                size: 39528,
                                path: require("../images/Files/gallery_kerry_4_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"59d40172fe4060cff784df17dcc8fdbd"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:36:56.000Z",
                                updatedAt: "2020-02-26T04:36:56.000Z",
                            },
                            file: {
                                id: 204,
                                size: 161623,
                                path: require("../images/Files/gallery_kerry_4.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"f2f86e5183da4a0dbfae172c5ac2cee1"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:36:55.000Z",
                                updatedAt: "2020-02-26T04:36:55.000Z",
                            },
                        },
                    },
                    {
                        id: 17,
                        imageGalleryId: 1,
                        imageId: 17,
                        createdAt: "2020-02-26T04:48:05.000Z",
                        updatedAt: "2020-02-26T04:48:05.000Z",
                        image: {
                            id: 17,
                            url: null,
                            fileId: 207,
                            thumbnailFileId: 206,
                            label: "gallery_kerry_5",
                            createdAt: "2020-02-26T04:32:45.000Z",
                            updatedAt: "2020-02-26T04:37:11.000Z",
                            thumbnailFile: {
                                id: 206,
                                size: 37832,
                                path: require("../images/Files/gallery_kerry_5_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"97e3b8d2f9758bf4113202d5f69ca1f4"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:37:08.000Z",
                                updatedAt: "2020-02-26T04:37:08.000Z",
                            },
                            file: {
                                id: 207,
                                size: 163789,
                                path: require("../images/Files/gallery_kerry_5.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"f313c940e5ed45688e1c7ee681b6fe77"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:37:09.000Z",
                                updatedAt: "2020-02-26T04:37:09.000Z",
                            },
                        },
                    },
                    {
                        id: 18,
                        imageGalleryId: 1,
                        imageId: 18,
                        createdAt: "2020-02-26T04:48:08.000Z",
                        updatedAt: "2020-02-26T04:48:08.000Z",
                        image: {
                            id: 18,
                            url: null,
                            fileId: 209,
                            thumbnailFileId: 208,
                            label: "gallery_kerry_6",
                            createdAt: "2020-02-26T04:33:06.000Z",
                            updatedAt: "2020-02-26T04:37:21.000Z",
                            thumbnailFile: {
                                id: 208,
                                size: 38544,
                                path: require("../images/Files/gallery_kerry_6_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"0270f7720e955c2201ac6efb33632986"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:37:17.000Z",
                                updatedAt: "2020-02-26T04:37:17.000Z",
                            },
                            file: {
                                id: 209,
                                size: 162478,
                                path: require("../images/Files/gallery_kerry_6.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"119d5160bbb571b92d86ec5e60b0d9de"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:37:20.000Z",
                                updatedAt: "2020-02-26T04:37:20.000Z",
                            },
                        },
                    },
                ],
            },
        },
        {
            id: 9,
            type: "quiz",
            content_id: 3,
            label: "Which character are you?",
            title_copy_id: 9,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-01-09T04:42:21.000Z",
            forceLarge: false,
            createdAt: "2020-01-10T04:42:21.000Z",
            updatedAt: "2020-02-25T22:06:34.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 10,
                    contentId: 9,
                    tagId: 9,
                    createdAt: "2020-02-05T22:47:04.000Z",
                    updatedAt: "2020-02-05T22:47:04.000Z",
                    tag: {
                        id: 9,
                        tag_id: "kerry",
                        label: "Kerry",
                        createdAt: "2020-02-04T01:25:00.000Z",
                        updatedAt: "2020-02-04T01:25:00.000Z",
                    },
                },
                {
                    id: 11,
                    contentId: 9,
                    tagId: 11,
                    createdAt: "2020-02-05T22:47:06.000Z",
                    updatedAt: "2020-02-05T22:47:06.000Z",
                    tag: {
                        id: 11,
                        tag_id: "yarra",
                        label: "Yarra",
                        createdAt: "2020-02-04T01:25:09.000Z",
                        updatedAt: "2020-02-04T01:25:09.000Z",
                    },
                },
                {
                    id: 12,
                    contentId: 9,
                    tagId: 14,
                    createdAt: "2020-02-05T22:47:08.000Z",
                    updatedAt: "2020-02-05T22:47:08.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 13,
                    contentId: 9,
                    tagId: 10,
                    createdAt: "2020-02-05T22:47:09.000Z",
                    updatedAt: "2020-02-05T22:47:09.000Z",
                    tag: {
                        id: 10,
                        tag_id: "petra",
                        label: "Petra",
                        createdAt: "2020-02-04T01:25:05.000Z",
                        updatedAt: "2020-02-04T01:25:05.000Z",
                    },
                },
                {
                    id: 14,
                    contentId: 9,
                    tagId: 12,
                    createdAt: "2020-02-05T22:47:13.000Z",
                    updatedAt: "2020-02-05T22:47:13.000Z",
                    tag: {
                        id: 12,
                        tag_id: "bernard",
                        label: "Bernard",
                        createdAt: "2020-02-04T01:25:16.000Z",
                        updatedAt: "2020-02-04T01:25:16.000Z",
                    },
                },
                {
                    id: 43,
                    contentId: 9,
                    tagId: 13,
                    createdAt: "2020-02-05T22:53:07.000Z",
                    updatedAt: "2020-02-05T22:53:07.000Z",
                    tag: {
                        id: 13,
                        tag_id: "quiz",
                        label: "Quiz",
                        createdAt: "2020-02-04T01:25:21.000Z",
                        updatedAt: "2020-02-04T01:25:21.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 3,
                label: "Which character are you?",
                copyId: null,
                thumbnailFileId: 280,
                quizType: 0,
                createdAt: "2020-01-10T04:22:39.000Z",
                updatedAt: "2020-02-26T06:39:26.000Z",
                copy: null,
                quizQuestions: [
                    {
                        id: 4,
                        label: "Your favourite weekend activity is:",
                        copyId: null,
                        thumbnailFileId: null,
                        createdAt: "2020-01-10T04:24:13.000Z",
                        updatedAt: "2020-01-10T04:25:47.000Z",
                        quizId: 3,
                        thumbnailFile: null,
                        quizAnswers: [
                            {
                                id: 5,
                                label: "Alone time",
                                copyId: null,
                                answerOrder: null,
                                score: '{"7":1}',
                                quizQuestionId: 4,
                                thumbnailFileId: 63,
                                createdAt: "2020-01-10T04:25:57.000Z",
                                updatedAt: "2020-02-20T02:38:30.000Z",
                                thumbnailFile: {
                                    id: 63,
                                    size: 30611,
                                    path: require("../images/Files/Q1Answer1_Alonetime_AntonDarius_1.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"26066f9a928582bd2fc6b1b1efdb929c"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Anton Darius on Unsplash",
                                    createdAt: "2020-02-20T02:38:22.000Z",
                                    updatedAt: "2020-02-20T02:38:28.000Z",
                                },
                            },
                            {
                                id: 6,
                                label: "Hanging with friends",
                                copyId: null,
                                answerOrder: null,
                                score: '{"9":1}',
                                quizQuestionId: 4,
                                thumbnailFileId: 64,
                                createdAt: "2020-01-10T04:26:04.000Z",
                                updatedAt: "2020-02-20T02:38:56.000Z",
                                thumbnailFile: {
                                    id: 64,
                                    size: 39853,
                                    path: require("../images/Files/Q1Answer2_Hangingwithfriends_HelenaLopes.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"c6029a4e02aa99bbdb93257e03c058bb"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Helena Lopes on Unsplash",
                                    createdAt: "2020-02-20T02:38:52.000Z",
                                    updatedAt: "2020-02-20T02:38:54.000Z",
                                },
                            },
                            {
                                id: 7,
                                label: "Exploring new places",
                                copyId: null,
                                answerOrder: null,
                                score: '{"6":1}',
                                quizQuestionId: 4,
                                thumbnailFileId: 65,
                                createdAt: "2020-01-10T04:26:11.000Z",
                                updatedAt: "2020-02-20T02:39:11.000Z",
                                thumbnailFile: {
                                    id: 65,
                                    size: 51127,
                                    path: require("../images/Files/Q1Answer3_Exploringnewplaces_KalenEmsley.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"28c94af58099a1ddffc84a3f393783d7"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Kalen Emsley on Unsplash",
                                    createdAt: "2020-02-20T02:39:07.000Z",
                                    updatedAt: "2020-02-20T02:39:10.000Z",
                                },
                            },
                            {
                                id: 8,
                                label: "Curling up with a book",
                                copyId: null,
                                answerOrder: null,
                                score: '{"8":1}',
                                quizQuestionId: 4,
                                thumbnailFileId: 66,
                                createdAt: "2020-01-10T04:26:21.000Z",
                                updatedAt: "2020-02-20T02:39:27.000Z",
                                thumbnailFile: {
                                    id: 66,
                                    size: 37922,
                                    path: require("../images/Files/Q1Answer4_Curlingupwithbook_AwarKurdish_1.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"5903dcbf29f388c241e8d8e99887d71c"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by awar kurdish on Unsplash",
                                    createdAt: "2020-02-20T02:39:20.000Z",
                                    updatedAt: "2020-02-20T02:39:25.000Z",
                                },
                            },
                            {
                                id: 9,
                                label: "Cooking",
                                copyId: null,
                                answerOrder: null,
                                score: '{"11":1}',
                                quizQuestionId: 4,
                                thumbnailFileId: 67,
                                createdAt: "2020-01-10T04:26:27.000Z",
                                updatedAt: "2020-02-20T02:39:46.000Z",
                                thumbnailFile: {
                                    id: 67,
                                    size: 48936,
                                    path: require("../images/Files/Q1Answer5_Cooking_AaronThomas.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"a4c4a51d89ef0e724ee7911e16a47e4a"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Aaron Thomas on Unsplash",
                                    createdAt: "2020-02-20T02:39:37.000Z",
                                    updatedAt: "2020-02-20T02:39:41.000Z",
                                },
                            },
                            {
                                id: 10,
                                label: "Sleeping",
                                copyId: null,
                                answerOrder: null,
                                score: '{"10":1}',
                                quizQuestionId: 4,
                                thumbnailFileId: 68,
                                createdAt: "2020-01-10T04:26:31.000Z",
                                updatedAt: "2020-02-20T02:40:02.000Z",
                                thumbnailFile: {
                                    id: 68,
                                    size: 29061,
                                    path: require("../images/Files/Q1Answer6_Sleeping_Dog_RegineTholen.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"9a4d90f853c536d99c67fabe6d3c6ae4"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Regine Tholen on Unsplash",
                                    createdAt: "2020-02-20T02:39:55.000Z",
                                    updatedAt: "2020-02-20T02:40:53.000Z",
                                },
                            },
                        ],
                    },
                    {
                        id: 5,
                        label: "What scares you?",
                        copyId: null,
                        thumbnailFileId: null,
                        createdAt: "2020-01-10T04:24:22.000Z",
                        updatedAt: "2020-01-10T04:24:22.000Z",
                        quizId: 3,
                        thumbnailFile: null,
                        quizAnswers: [
                            {
                                id: 11,
                                label: "Nothing! I am fearless",
                                copyId: null,
                                answerOrder: null,
                                score: '{"6":1}',
                                quizQuestionId: 5,
                                thumbnailFileId: 69,
                                createdAt: "2020-01-10T04:28:43.000Z",
                                updatedAt: "2020-02-20T02:41:25.000Z",
                                thumbnailFile: {
                                    id: 69,
                                    size: 29982,
                                    path: require("../images/Files/Q2Answer1_Nothingfearless_KamilPietrzak.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"05725a9c894f3edbda2c72eea15d7a56"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Kamil Pietrzak on Unsplash",
                                    createdAt: "2020-02-20T02:41:20.000Z",
                                    updatedAt: "2020-02-20T02:41:22.000Z",
                                },
                            },
                            {
                                id: 12,
                                label: "Spiders",
                                copyId: null,
                                answerOrder: null,
                                score: '{"11":1}',
                                quizQuestionId: 5,
                                thumbnailFileId: 70,
                                createdAt: "2020-01-10T04:28:47.000Z",
                                updatedAt: "2020-02-20T02:41:44.000Z",
                                thumbnailFile: {
                                    id: 70,
                                    size: 58943,
                                    path: require("../images/Files/Q2Answer2_Spiders_KrzysztofNiewolny.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"55df1755302b9d2ad439b5af3c799850"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Krzysztof Niewolny on Unsplash",
                                    createdAt: "2020-02-20T02:41:34.000Z",
                                    updatedAt: "2020-02-20T02:41:39.000Z",
                                },
                            },
                            {
                                id: 13,
                                label: "The dark",
                                copyId: null,
                                answerOrder: null,
                                score: '{"9":1}',
                                quizQuestionId: 5,
                                thumbnailFileId: 71,
                                createdAt: "2020-01-10T04:28:51.000Z",
                                updatedAt: "2020-02-20T02:42:02.000Z",
                                thumbnailFile: {
                                    id: 71,
                                    size: 33669,
                                    path: require("../images/Files/Q2Answer3_TheDark_RosieFraser.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"8dd56544356c5a90b579d122300a6ee5"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Rosie Fraser on Unsplash",
                                    createdAt: "2020-02-20T02:41:55.000Z",
                                    updatedAt: "2020-02-20T02:42:00.000Z",
                                },
                            },
                            {
                                id: 14,
                                label: "Noone laughing at my jokes",
                                copyId: null,
                                answerOrder: null,
                                score: '{"8":1}',
                                quizQuestionId: 5,
                                thumbnailFileId: 72,
                                createdAt: "2020-01-10T04:28:58.000Z",
                                updatedAt: "2020-02-20T02:42:19.000Z",
                                thumbnailFile: {
                                    id: 72,
                                    size: 28122,
                                    path: require("../images/Files/Q2Answer4_Noonelaughing_AlexLauzon.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"3dc6b34202b9867ca6537eacbb422d26"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by alex lauzon on Unsplash",
                                    createdAt: "2020-02-20T02:42:10.000Z",
                                    updatedAt: "2020-02-20T02:42:15.000Z",
                                },
                            },
                            {
                                id: 15,
                                label: "Vegetables",
                                copyId: null,
                                answerOrder: null,
                                score: '{"10":1}',
                                quizQuestionId: 5,
                                thumbnailFileId: 73,
                                createdAt: "2020-01-10T04:29:04.000Z",
                                updatedAt: "2020-02-20T02:42:34.000Z",
                                thumbnailFile: {
                                    id: 73,
                                    size: 75832,
                                    path: require("../images/Files/Q2Answer5_Vegetables_ChantalGarnier.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"24e106ee4fe8aa8ba65277afc50c1819"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Chantal Garnier on Unsplash",
                                    createdAt: "2020-02-20T02:42:27.000Z",
                                    updatedAt: "2020-02-20T02:42:31.000Z",
                                },
                            },
                            {
                                id: 16,
                                label: "Heights",
                                copyId: null,
                                answerOrder: null,
                                score: '{"7":1}',
                                quizQuestionId: 5,
                                thumbnailFileId: 74,
                                createdAt: "2020-01-10T04:29:08.000Z",
                                updatedAt: "2020-02-20T02:42:51.000Z",
                                thumbnailFile: {
                                    id: 74,
                                    size: 45500,
                                    path: require("../images/Files/Q2Answer6_Heights_CaseyHorner.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"a6c4efaa8933a372ae421917593072bf"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Casey Horner on Unsplash",
                                    createdAt: "2020-02-20T02:42:42.000Z",
                                    updatedAt: "2020-02-20T02:42:48.000Z",
                                },
                            },
                        ],
                    },
                    {
                        id: 6,
                        label: "What is your favourite food?",
                        copyId: null,
                        thumbnailFileId: null,
                        createdAt: "2020-01-10T04:24:28.000Z",
                        updatedAt: "2020-01-10T04:24:28.000Z",
                        quizId: 3,
                        thumbnailFile: null,
                        quizAnswers: [
                            {
                                id: 17,
                                label: "Pizza",
                                copyId: null,
                                answerOrder: null,
                                score: '{"11":1}',
                                quizQuestionId: 6,
                                thumbnailFileId: 75,
                                createdAt: "2020-01-10T04:30:33.000Z",
                                updatedAt: "2020-02-20T02:43:15.000Z",
                                thumbnailFile: {
                                    id: 75,
                                    size: 70143,
                                    path: require("../images/Files/Q3Answer1_Pizza_VitaMarijaMurenaite.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"a8ff66052a7f565edb661478fc8c50e4"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Vita Marija Murenaite on Unsplash",
                                    createdAt: "2020-02-20T02:43:06.000Z",
                                    updatedAt: "2020-02-20T02:43:13.000Z",
                                },
                            },
                            {
                                id: 18,
                                label: "Burgers",
                                copyId: null,
                                answerOrder: null,
                                score: '{"10":1}',
                                quizQuestionId: 6,
                                thumbnailFileId: 76,
                                createdAt: "2020-01-10T04:30:37.000Z",
                                updatedAt: "2020-02-20T02:55:35.000Z",
                                thumbnailFile: {
                                    id: 76,
                                    size: 46241,
                                    path: require("../images/Files/Q3Answer2_Burgers_IlyaMashkov.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"ecc2d42408ec2d769be9792bb89751a0"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Ilya Mashkov on Unsplash",
                                    createdAt: "2020-02-20T02:43:24.000Z",
                                    updatedAt: "2020-02-20T02:43:28.000Z",
                                },
                            },
                            {
                                id: 19,
                                label: "Salad",
                                copyId: null,
                                answerOrder: null,
                                score: '{"7":1}',
                                quizQuestionId: 6,
                                thumbnailFileId: 77,
                                createdAt: "2020-01-10T04:30:41.000Z",
                                updatedAt: "2020-02-20T02:43:52.000Z",
                                thumbnailFile: {
                                    id: 77,
                                    size: 71172,
                                    path: require("../images/Files/Q3Answer3_Salad_JezTimms.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"de7ea7ed69aef44505590783faa7920f"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Jakub Kapusnak on Unsplash",
                                    createdAt: "2020-02-20T02:43:45.000Z",
                                    updatedAt: "2020-02-20T02:43:50.000Z",
                                },
                            },
                            {
                                id: 20,
                                label: "Chocolate",
                                copyId: null,
                                answerOrder: null,
                                score: '{"6":1}',
                                quizQuestionId: 6,
                                thumbnailFileId: 78,
                                createdAt: "2020-01-10T04:30:45.000Z",
                                updatedAt: "2020-02-20T02:44:07.000Z",
                                thumbnailFile: {
                                    id: 78,
                                    size: 60349,
                                    path: require("../images/Files/Q3Answer4_Chocolate_CharisseKenion.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"337543e036e31dcec206df896a6fbbcc"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Charisse Kenion on Unsplash",
                                    createdAt: "2020-02-20T02:44:01.000Z",
                                    updatedAt: "2020-02-20T02:44:05.000Z",
                                },
                            },
                            {
                                id: 21,
                                label: "Eggs",
                                copyId: null,
                                answerOrder: null,
                                score: '{"8":1}',
                                quizQuestionId: 6,
                                thumbnailFileId: 79,
                                createdAt: "2020-01-10T04:30:48.000Z",
                                updatedAt: "2020-02-20T02:44:22.000Z",
                                thumbnailFile: {
                                    id: 79,
                                    size: 33993,
                                    path: require("../images/Files/Q3Answer5_Eggs_JakubKapusnak.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"689c04819bda6bc217dd965a4c331b0b"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Jakub Kapusnak on Unsplash",
                                    createdAt: "2020-02-20T02:44:16.000Z",
                                    updatedAt: "2020-02-20T02:44:20.000Z",
                                },
                            },
                            {
                                id: 22,
                                label: "Sandwiches",
                                copyId: null,
                                answerOrder: null,
                                score: '{"9":1}',
                                quizQuestionId: 6,
                                thumbnailFileId: 80,
                                createdAt: "2020-01-10T04:30:52.000Z",
                                updatedAt: "2020-02-20T02:44:36.000Z",
                                thumbnailFile: {
                                    id: 80,
                                    size: 56249,
                                    path: require("../images/Files/Q3Answer6_Sandwhich_ReidZura.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"d5dcd7945c29e5eddace87c4f712c656"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Micheile Henderson on Unsplash",
                                    createdAt: "2020-02-20T02:44:31.000Z",
                                    updatedAt: "2020-02-20T02:44:34.000Z",
                                },
                            },
                        ],
                    },
                    {
                        id: 7,
                        label: "How would your friends describe you?",
                        copyId: null,
                        thumbnailFileId: null,
                        createdAt: "2020-01-10T04:24:35.000Z",
                        updatedAt: "2020-01-10T04:24:35.000Z",
                        quizId: 3,
                        thumbnailFile: null,
                        quizAnswers: [
                            {
                                id: 23,
                                label: "Loud",
                                copyId: null,
                                answerOrder: null,
                                score: '{"6":1}',
                                quizQuestionId: 7,
                                thumbnailFileId: 81,
                                createdAt: "2020-01-10T04:31:55.000Z",
                                updatedAt: "2020-02-20T02:45:42.000Z",
                                thumbnailFile: {
                                    id: 81,
                                    size: 30893,
                                    path: require("../images/Files/Q4Answer1_Loud_JasonRosewell.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"56a186ddc7000c62354946370de0b11a"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Jason Rosewell on Unsplash",
                                    createdAt: "2020-02-20T02:45:32.000Z",
                                    updatedAt: "2020-02-20T02:45:40.000Z",
                                },
                            },
                            {
                                id: 24,
                                label: "Loving",
                                copyId: null,
                                answerOrder: null,
                                score: '{"11":1}',
                                quizQuestionId: 7,
                                thumbnailFileId: 82,
                                createdAt: "2020-01-10T04:31:59.000Z",
                                updatedAt: "2020-02-20T02:45:54.000Z",
                                thumbnailFile: {
                                    id: 82,
                                    size: 52415,
                                    path: require("../images/Files/Q4Answer2_Loving_Neonbrand.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"ddada790dfcfe716579ab6cddca69c10"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by NeONBRAND on Unsplash",
                                    createdAt: "2020-02-20T02:45:50.000Z",
                                    updatedAt: "2020-02-20T02:45:52.000Z",
                                },
                            },
                            {
                                id: 25,
                                label: "Considerate",
                                copyId: null,
                                answerOrder: null,
                                score: '{"7":1}',
                                quizQuestionId: 7,
                                thumbnailFileId: 83,
                                createdAt: "2020-01-10T04:32:03.000Z",
                                updatedAt: "2020-02-20T02:46:09.000Z",
                                thumbnailFile: {
                                    id: 83,
                                    size: 29401,
                                    path: require("../images/Files/Q4Answer3_Considerate_GiangVu.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"3c338bd5ed196145fa8cced08596e314"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Giang Vu on Unsplash",
                                    createdAt: "2020-02-20T02:46:02.000Z",
                                    updatedAt: "2020-02-20T02:46:06.000Z",
                                },
                            },
                            {
                                id: 26,
                                label: "Wise",
                                copyId: null,
                                answerOrder: null,
                                score: '{"8":1}',
                                quizQuestionId: 7,
                                thumbnailFileId: 84,
                                createdAt: "2020-01-10T04:32:07.000Z",
                                updatedAt: "2020-02-20T02:46:23.000Z",
                                thumbnailFile: {
                                    id: 84,
                                    size: 33381,
                                    path: require("../images/Files/Q4Answer4_Wise_NathanGuzman.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"545dd47093e1e07360731088ef972f7a"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Nathan Guzman on Unsplash",
                                    createdAt: "2020-02-20T02:46:16.000Z",
                                    updatedAt: "2020-02-20T02:46:20.000Z",
                                },
                            },
                            {
                                id: 27,
                                label: "Funny",
                                copyId: null,
                                answerOrder: null,
                                score: '{"10":1}',
                                quizQuestionId: 7,
                                thumbnailFileId: 85,
                                createdAt: "2020-01-10T04:32:10.000Z",
                                updatedAt: "2020-02-20T02:46:39.000Z",
                                thumbnailFile: {
                                    id: 85,
                                    size: 76060,
                                    path: require("../images/Files/Q4Answer5_Funny_PriscillaDuPreez.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"ab8cd77662ae8976bc2dc0f24cc53b42"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Priscilla Du Preez on Unsplash",
                                    createdAt: "2020-02-20T02:46:32.000Z",
                                    updatedAt: "2020-02-20T02:46:38.000Z",
                                },
                            },
                            {
                                id: 28,
                                label: "Cute",
                                copyId: null,
                                answerOrder: null,
                                score: '{"9":1}',
                                quizQuestionId: 7,
                                thumbnailFileId: 86,
                                createdAt: "2020-01-10T04:32:14.000Z",
                                updatedAt: "2020-02-20T02:46:58.000Z",
                                thumbnailFile: {
                                    id: 86,
                                    size: 45986,
                                    path: require("../images/Files/Q4Answer6_Cute_JairoAlzate.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"5590cb1b77aba997fb7278e1479a412a"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Jairo Alzate on Unsplash",
                                    createdAt: "2020-02-20T02:46:51.000Z",
                                    updatedAt: "2020-02-20T02:46:56.000Z",
                                },
                            },
                        ],
                    },
                    {
                        id: 8,
                        label: "Pick a colour:",
                        copyId: null,
                        thumbnailFileId: null,
                        createdAt: "2020-01-10T04:24:41.000Z",
                        updatedAt: "2020-01-10T04:24:41.000Z",
                        quizId: 3,
                        thumbnailFile: null,
                        quizAnswers: [
                            {
                                id: 29,
                                label: "Green",
                                copyId: null,
                                answerOrder: null,
                                score: '{"8":1}',
                                quizQuestionId: 8,
                                thumbnailFileId: 87,
                                createdAt: "2020-01-10T04:33:42.000Z",
                                updatedAt: "2020-02-20T02:47:24.000Z",
                                thumbnailFile: {
                                    id: 87,
                                    size: 50244,
                                    path: require("../images/Files/Q5Answer1_Green_JasonDent.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"c0046f8cf7c0dd1a0f93f4463b56623c"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Jason Dent on Unsplash",
                                    createdAt: "2020-02-20T02:47:18.000Z",
                                    updatedAt: "2020-02-20T02:47:22.000Z",
                                },
                            },
                            {
                                id: 30,
                                label: "Orange",
                                copyId: null,
                                answerOrder: null,
                                score: '{"6":1}',
                                quizQuestionId: 8,
                                thumbnailFileId: 88,
                                createdAt: "2020-01-10T04:33:50.000Z",
                                updatedAt: "2020-02-20T02:47:38.000Z",
                                thumbnailFile: {
                                    id: 88,
                                    size: 19522,
                                    path: require("../images/Files/Q5Answer2_Orange_LucasBenjamin.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"23541aec35b23e2f5231acf7dffc8bae"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Lucas Benjamin on Unsplash",
                                    createdAt: "2020-02-20T02:47:32.000Z",
                                    updatedAt: "2020-02-20T02:47:36.000Z",
                                },
                            },
                            {
                                id: 31,
                                label: "Pink",
                                copyId: null,
                                answerOrder: null,
                                score: '{"7":1}',
                                quizQuestionId: 8,
                                thumbnailFileId: 89,
                                createdAt: "2020-01-10T04:33:54.000Z",
                                updatedAt: "2020-02-20T02:55:52.000Z",
                                thumbnailFile: {
                                    id: 89,
                                    size: 19098,
                                    path: require("../images/Files/Q5Answer3_Pink_w.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"8b4e2413f1b9ce4bb70626d94b2965bd"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by W on Unsplash",
                                    createdAt: "2020-02-20T02:47:46.000Z",
                                    updatedAt: "2020-02-20T02:47:50.000Z",
                                },
                            },
                            {
                                id: 32,
                                label: "Blue",
                                copyId: null,
                                answerOrder: null,
                                score: '{"9":1}',
                                quizQuestionId: 8,
                                thumbnailFileId: 90,
                                createdAt: "2020-01-10T04:34:01.000Z",
                                updatedAt: "2020-02-20T02:48:09.000Z",
                                thumbnailFile: {
                                    id: 90,
                                    size: 19845,
                                    path: require("../images/Files/Q5Answer4_blue_ToaHeftiba.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"e7c5c885f68a7488d70f7d2b5a591281"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Toa Heftiba on Unsplash",
                                    createdAt: "2020-02-20T02:48:01.000Z",
                                    updatedAt: "2020-02-20T02:48:06.000Z",
                                },
                            },
                            {
                                id: 33,
                                label: "Yellow",
                                copyId: null,
                                answerOrder: null,
                                score: '{"11":1}',
                                quizQuestionId: 8,
                                thumbnailFileId: 91,
                                createdAt: "2020-01-10T04:34:05.000Z",
                                updatedAt: "2020-02-20T02:48:24.000Z",
                                thumbnailFile: {
                                    id: 91,
                                    size: 68445,
                                    path: require("../images/Files/Q5Answer5_Yellow_JoseLarrazolo.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"7406320859fcf61f0932ebcd63a14a72"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by JOSE LARRAZOLO on Unsplash",
                                    createdAt: "2020-02-20T02:48:16.000Z",
                                    updatedAt: "2020-02-20T02:48:22.000Z",
                                },
                            },
                            {
                                id: 34,
                                label: "Red",
                                copyId: null,
                                answerOrder: null,
                                score: '{"10":1}',
                                quizQuestionId: 8,
                                thumbnailFileId: 92,
                                createdAt: "2020-01-10T04:34:11.000Z",
                                updatedAt: "2020-02-20T02:48:46.000Z",
                                thumbnailFile: {
                                    id: 92,
                                    size: 40706,
                                    path: require("../images/Files/Q5Answer6_Red_JasonDent.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"7eebfe6b7e7de3d1b74ccfc7aeb3e177"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Jason Dent on Unsplash",
                                    createdAt: "2020-02-20T02:48:35.000Z",
                                    updatedAt: "2020-02-20T02:48:43.000Z",
                                },
                            },
                        ],
                    },
                    {
                        id: 9,
                        label: "What annoys you?",
                        copyId: null,
                        thumbnailFileId: null,
                        createdAt: "2020-01-10T04:24:47.000Z",
                        updatedAt: "2020-01-10T04:24:47.000Z",
                        quizId: 3,
                        thumbnailFile: null,
                        quizAnswers: [
                            {
                                id: 35,
                                label: "People not following the rules",
                                copyId: null,
                                answerOrder: null,
                                score: '{"8":1}',
                                quizQuestionId: 9,
                                thumbnailFileId: 93,
                                createdAt: "2020-01-10T04:35:29.000Z",
                                updatedAt: "2020-02-20T02:49:13.000Z",
                                thumbnailFile: {
                                    id: 93,
                                    size: 50191,
                                    path: require("../images/Files/Q6Answer1_Peoplenotfollowingrules_RyanFranco.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"8d32cd57a0bc9b03f3bd806d2cee0945"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Ryan Franco on Unsplash",
                                    createdAt: "2020-02-20T02:49:06.000Z",
                                    updatedAt: "2020-02-20T02:49:11.000Z",
                                },
                            },
                            {
                                id: 36,
                                label: "Being ignored",
                                copyId: null,
                                answerOrder: null,
                                score: '{"6":1}',
                                quizQuestionId: 9,
                                thumbnailFileId: 94,
                                createdAt: "2020-01-10T04:35:36.000Z",
                                updatedAt: "2020-02-20T02:49:38.000Z",
                                thumbnailFile: {
                                    id: 94,
                                    size: 54806,
                                    path: require("../images/Files/Q6Answer2_Beingignored_ClemOnojeghuo.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"9edbd31d5dc0d5d61070fcdff5ac199b"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Clem Onojeghuo on Unsplash",
                                    createdAt: "2020-02-20T02:49:27.000Z",
                                    updatedAt: "2020-02-20T02:49:32.000Z",
                                },
                            },
                            {
                                id: 37,
                                label: "Lack of manners",
                                copyId: null,
                                answerOrder: null,
                                score: '{"11":1}',
                                quizQuestionId: 9,
                                thumbnailFileId: 95,
                                createdAt: "2020-01-10T04:35:42.000Z",
                                updatedAt: "2020-02-20T02:50:01.000Z",
                                thumbnailFile: {
                                    id: 95,
                                    size: 35803,
                                    path: require("../images/Files/Q6Answer3_BeingIgnored_allisonShaw.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"4bf26e385c50183bc4c7f1e0509c95bd"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Allison Shaw on Unsplash",
                                    createdAt: "2020-02-20T02:49:54.000Z",
                                    updatedAt: "2020-02-20T02:49:58.000Z",
                                },
                            },
                            {
                                id: 38,
                                label: "Tourists",
                                copyId: null,
                                answerOrder: null,
                                score: '{"10":1}',
                                quizQuestionId: 9,
                                thumbnailFileId: 96,
                                createdAt: "2020-01-10T04:35:45.000Z",
                                updatedAt: "2020-02-20T02:50:14.000Z",
                                thumbnailFile: {
                                    id: 96,
                                    size: 45539,
                                    path: require("../images/Files/Q6Answer4_Tourists_vidarNordli.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"c6d6ce61b163c524b689ce93c3d05d18"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Vidar Nordli-Mathisen on Unsplash",
                                    createdAt: "2020-02-20T02:50:08.000Z",
                                    updatedAt: "2020-02-20T02:50:12.000Z",
                                },
                            },
                            {
                                id: 39,
                                label: "Your sibling",
                                copyId: null,
                                answerOrder: null,
                                score: '{"7":1}',
                                quizQuestionId: 9,
                                thumbnailFileId: 97,
                                createdAt: "2020-01-10T04:35:50.000Z",
                                updatedAt: "2020-02-20T02:50:29.000Z",
                                thumbnailFile: {
                                    id: 97,
                                    size: 75993,
                                    path: require("../images/Files/Q6Answer5_YourSibling_annie_spratt.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"42d0c5d787c4f400426eb2ba3dbd3305"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Annie Spratt on Unsplash",
                                    createdAt: "2020-02-20T02:50:21.000Z",
                                    updatedAt: "2020-02-20T02:50:27.000Z",
                                },
                            },
                            {
                                id: 40,
                                label: "When people step on your toes",
                                copyId: null,
                                answerOrder: null,
                                score: '{"9":1}',
                                quizQuestionId: 9,
                                thumbnailFileId: 98,
                                createdAt: "2020-01-10T04:35:57.000Z",
                                updatedAt: "2020-02-20T02:50:48.000Z",
                                thumbnailFile: {
                                    id: 98,
                                    size: 29495,
                                    path: require("../images/Files/Q6Answer6_Peoplestepontoes_brina_blum.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"d234c9b9e87ef64e56c4d3a9dd0a4169"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Brina Blum on Unsplash",
                                    createdAt: "2020-02-20T02:50:42.000Z",
                                    updatedAt: "2020-02-20T02:50:45.000Z",
                                },
                            },
                        ],
                    },
                    {
                        id: 10,
                        label: "Choose a place:",
                        copyId: null,
                        thumbnailFileId: null,
                        createdAt: "2020-01-10T04:24:56.000Z",
                        updatedAt: "2020-01-10T04:24:56.000Z",
                        quizId: 3,
                        thumbnailFile: null,
                        quizAnswers: [
                            {
                                id: 41,
                                label: "Forest",
                                copyId: null,
                                answerOrder: null,
                                score: '{"9":1}',
                                quizQuestionId: 10,
                                thumbnailFileId: 99,
                                createdAt: "2020-01-10T04:37:16.000Z",
                                updatedAt: "2020-02-20T02:51:09.000Z",
                                thumbnailFile: {
                                    id: 99,
                                    size: 44140,
                                    path: require("../images/Files/Q7Answer1_Forest_SebastianUnrau.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"82051e5b3d31abbd79a8556c8bcde9ec"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Sebastian Unrau on Unsplash",
                                    createdAt: "2020-02-20T02:51:04.000Z",
                                    updatedAt: "2020-02-20T02:51:07.000Z",
                                },
                            },
                            {
                                id: 42,
                                label: "Snow",
                                copyId: null,
                                answerOrder: null,
                                score: '{"6":1}',
                                quizQuestionId: 10,
                                thumbnailFileId: 100,
                                createdAt: "2020-01-10T04:37:20.000Z",
                                updatedAt: "2020-02-20T02:51:21.000Z",
                                thumbnailFile: {
                                    id: 100,
                                    size: 34010,
                                    path: require("../images/Files/Q7Answer2_Snow_FabianMardi.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"8a9f3685ed412cbcc9c25301cf585617"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Fabian Mardi on Unsplash",
                                    createdAt: "2020-02-20T02:51:16.000Z",
                                    updatedAt: "2020-02-20T02:51:19.000Z",
                                },
                            },
                            {
                                id: 43,
                                label: "Beach",
                                copyId: null,
                                answerOrder: null,
                                score: '{"11":1}',
                                quizQuestionId: 10,
                                thumbnailFileId: 101,
                                createdAt: "2020-01-10T04:37:24.000Z",
                                updatedAt: "2020-02-20T02:51:36.000Z",
                                thumbnailFile: {
                                    id: 101,
                                    size: 32859,
                                    path: require("../images/Files/Q7Answer3_Beach_Sean.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"4b718e7d61b13656c1a1f40a644925ae"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Sean O. on Unsplash",
                                    createdAt: "2020-02-20T02:51:30.000Z",
                                    updatedAt: "2020-02-20T02:51:34.000Z",
                                },
                            },
                            {
                                id: 44,
                                label: "Spa",
                                copyId: null,
                                answerOrder: null,
                                score: '{"10":1}',
                                quizQuestionId: 10,
                                thumbnailFileId: 102,
                                createdAt: "2020-01-10T04:37:28.000Z",
                                updatedAt: "2020-02-20T02:51:51.000Z",
                                thumbnailFile: {
                                    id: 102,
                                    size: 51002,
                                    path: require("../images/Files/Q7Answer4_Spa_mark_christian_killick_calver.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"e5e2109f4bc93093c5745371d3e9a344"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Mark-Christian Killick-Calver on Unsplash",
                                    createdAt: "2020-02-20T02:51:44.000Z",
                                    updatedAt: "2020-02-20T02:51:49.000Z",
                                },
                            },
                            {
                                id: 45,
                                label: "Pool",
                                copyId: null,
                                answerOrder: null,
                                score: '{"8":1}',
                                quizQuestionId: 10,
                                thumbnailFileId: 103,
                                createdAt: "2020-01-10T04:37:32.000Z",
                                updatedAt: "2020-02-20T02:52:04.000Z",
                                thumbnailFile: {
                                    id: 103,
                                    size: 46298,
                                    path: require("../images/Files/Q7Answer5_Pool_raphael_biscaldi.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"1b0b597bc3325a4c1f9315198c4038c8"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Raphaël Biscaldi on Unsplash",
                                    createdAt: "2020-02-20T02:51:59.000Z",
                                    updatedAt: "2020-02-20T02:52:02.000Z",
                                },
                            },
                            {
                                id: 46,
                                label: "Rollercoaster",
                                copyId: null,
                                answerOrder: null,
                                score: '{"7":1}',
                                quizQuestionId: 10,
                                thumbnailFileId: 104,
                                createdAt: "2020-01-10T04:37:37.000Z",
                                updatedAt: "2020-02-20T02:52:19.000Z",
                                thumbnailFile: {
                                    id: 104,
                                    size: 44660,
                                    path: require("../images/Files/Q7Answer6_Rollercoaster_BradleyPisney.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"8f897b9fb2658c0bca029e020d4a9e52"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by bradley pisney on Unsplash",
                                    createdAt: "2020-02-20T02:52:11.000Z",
                                    updatedAt: "2020-02-20T02:52:17.000Z",
                                },
                            },
                        ],
                    },
                    {
                        id: 11,
                        label: "Pick a song to dance to:",
                        copyId: null,
                        thumbnailFileId: null,
                        createdAt: "2020-01-10T04:25:05.000Z",
                        updatedAt: "2020-01-10T04:25:05.000Z",
                        quizId: 3,
                        thumbnailFile: null,
                        quizAnswers: [
                            {
                                id: 47,
                                label: "I don't dance",
                                copyId: null,
                                answerOrder: null,
                                score: '{"10":1}',
                                quizQuestionId: 11,
                                thumbnailFileId: 105,
                                createdAt: "2020-01-10T04:39:24.000Z",
                                updatedAt: "2020-02-20T02:53:05.000Z",
                                thumbnailFile: {
                                    id: 105,
                                    size: 51075,
                                    path: require("../images/Files/Q8Answer1_dontdance_ayo_ogunseinde.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"0ce949e9d0305c16444cd673dbc7c638"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Ayo Ogunseinde on Unsplash",
                                    createdAt: "2020-02-20T02:52:56.000Z",
                                    updatedAt: "2020-02-20T02:53:03.000Z",
                                },
                            },
                            {
                                id: 48,
                                label: "Call Me Maybe - Carly Rae Jepsen",
                                copyId: null,
                                answerOrder: null,
                                score: '{"11":1}',
                                quizQuestionId: 11,
                                thumbnailFileId: 106,
                                createdAt: "2020-01-10T04:39:40.000Z",
                                updatedAt: "2020-02-20T02:53:20.000Z",
                                thumbnailFile: {
                                    id: 106,
                                    size: 46236,
                                    path: require("../images/Files/Q8Answer2_Callmemaybe_eliska_motisova.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"4ed7f3a79fc7d0734bd3b98fac8cecdf"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Eliška Motisová on Unsplash",
                                    createdAt: "2020-02-20T02:53:13.000Z",
                                    updatedAt: "2020-02-20T02:53:17.000Z",
                                },
                            },
                            {
                                id: 49,
                                label: "Uptown Funk - Mark Ronson and Bruno Mars",
                                copyId: null,
                                answerOrder: null,
                                score: '{"7":1}',
                                quizQuestionId: 11,
                                thumbnailFileId: 107,
                                createdAt: "2020-01-10T04:39:55.000Z",
                                updatedAt: "2020-02-20T02:53:40.000Z",
                                thumbnailFile: {
                                    id: 107,
                                    size: 77717,
                                    path: require("../images/Files/Q8Answer3_Uptownfunk_thor_alvis.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"1ed469d3dbd29f8e3d56ef20471c4f95"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Thor Alvis on Unsplash",
                                    createdAt: "2020-02-20T02:53:30.000Z",
                                    updatedAt: "2020-02-20T02:53:38.000Z",
                                },
                            },
                            {
                                id: 50,
                                label: "Shape of You - Ed Sheehan",
                                copyId: null,
                                answerOrder: null,
                                score: '{"9":1}',
                                quizQuestionId: 11,
                                thumbnailFileId: 108,
                                createdAt: "2020-01-10T04:40:16.000Z",
                                updatedAt: "2020-02-20T02:53:57.000Z",
                                thumbnailFile: {
                                    id: 108,
                                    size: 20039,
                                    path: require("../images/Files/Q8Answer4_shapeofyou_designecologist.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"e95363e2094fde3146ff087fb6ee97b3"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by DESIGNECOLOGIST on Unsplash",
                                    createdAt: "2020-02-20T02:53:50.000Z",
                                    updatedAt: "2020-02-20T02:53:54.000Z",
                                },
                            },
                            {
                                id: 51,
                                label: "Bad Guy - Billie Eilish",
                                copyId: null,
                                answerOrder: null,
                                score: '{"6":1}',
                                quizQuestionId: 11,
                                thumbnailFileId: 109,
                                createdAt: "2020-01-10T04:40:32.000Z",
                                updatedAt: "2020-02-20T02:54:14.000Z",
                                thumbnailFile: {
                                    id: 109,
                                    size: 28032,
                                    path: require("../images/Files/Q8Answer5_BadGuy_RossSokolovski.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"f1a7425aec08afc4f61f9803b031a43a"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Ross Sokolovski on Unsplash",
                                    createdAt: "2020-02-20T02:54:06.000Z",
                                    updatedAt: "2020-02-20T02:54:09.000Z",
                                },
                            },
                            {
                                id: 52,
                                label: "Old Town Road - Lil Nas X",
                                copyId: null,
                                answerOrder: null,
                                score: '{"8":1}',
                                quizQuestionId: 11,
                                thumbnailFileId: 110,
                                createdAt: "2020-01-10T04:40:46.000Z",
                                updatedAt: "2020-02-20T02:54:29.000Z",
                                thumbnailFile: {
                                    id: 110,
                                    size: 47714,
                                    path: require("../images/Files/Q8Answer6_Oldtownroad_specphotops.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"5ad71d613ae8cc1639d6841cbd8733f4"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by specphotops on Unsplash",
                                    createdAt: "2020-02-20T02:54:23.000Z",
                                    updatedAt: "2020-02-20T02:54:26.000Z",
                                },
                            },
                        ],
                    },
                ],
                quizResults: [
                    {
                        id: 6,
                        label: "Kerry",
                        detail: "You are a constant adventurer! You love exploring and learning, you are unstoppable and sometimes act before thinking it through but always manage to make things right!",
                        copyId: null,
                        scoreThreshold: 0,
                        thumbnailFileId: 111,
                        createdAt: "2020-01-10T04:23:26.000Z",
                        updatedAt: "2020-02-20T02:57:21.000Z",
                        quizId: 3,
                        thumbnailFile: {
                            id: 111,
                            size: 37506,
                            path: require("../images/Files/kerry_character_wishmastree_01.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"4613dddaf9f2de98a230c0574238e1d4"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T02:57:19.000Z",
                            updatedAt: "2020-02-20T02:57:19.000Z",
                        },
                    },
                    {
                        id: 7,
                        label: "Petra",
                        detail: "You are courageous and would do anything for your family and friends even if it means going outside your comfort zone! You are clever and cautious but know how to have fun once you relax.",
                        copyId: null,
                        scoreThreshold: 0,
                        thumbnailFileId: 112,
                        createdAt: "2020-01-10T04:23:32.000Z",
                        updatedAt: "2020-02-20T02:57:52.000Z",
                        quizId: 3,
                        thumbnailFile: {
                            id: 112,
                            size: 36716,
                            path: require("../images/Files/petra_character_wishmastree_01.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"07cf06702b7dc44970210e466e29d723"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T02:57:42.000Z",
                            updatedAt: "2020-02-20T02:57:42.000Z",
                        },
                    },
                    {
                        id: 8,
                        label: "Yarra",
                        detail: "You are the wise one! You are an expert on everything and guide everyone you know. Your past experiences have made you appreciate the quiet life but still you still have fire in your belly.",
                        copyId: null,
                        scoreThreshold: 0,
                        thumbnailFileId: 113,
                        createdAt: "2020-01-10T04:23:41.000Z",
                        updatedAt: "2020-02-20T02:58:06.000Z",
                        quizId: 3,
                        thumbnailFile: {
                            id: 113,
                            size: 35555,
                            path: require("../images/Files/yarra_character_wishmastree_01.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"b94eb3dc5661d08d76a9bd5074c5db18"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T02:58:03.000Z",
                            updatedAt: "2020-02-20T02:58:03.000Z",
                        },
                    },
                    {
                        id: 9,
                        label: "Bernard",
                        detail: "You are cute and unpredictable. Full of energy you feed off the energy of others and rarely spend time alone. Cheeky and loveable you are a hit at every party!",
                        copyId: null,
                        scoreThreshold: 0,
                        thumbnailFileId: 114,
                        createdAt: "2020-01-10T04:23:46.000Z",
                        updatedAt: "2020-02-20T02:58:21.000Z",
                        quizId: 3,
                        thumbnailFile: {
                            id: 114,
                            size: 29578,
                            path: require("../images/Files/bernard_character_wishmastree_01.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"2c5aab71688fac9379dec9bf8d47e6f3"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T02:58:18.000Z",
                            updatedAt: "2020-02-20T02:58:18.000Z",
                        },
                    },
                    {
                        id: 10,
                        label: "Augustus",
                        detail: "You are a comedian, whether you mean to be or not. You know you are the top of the food chain and rule every social situation. You keep a hard exterior but are a marshmallow inside.",
                        copyId: null,
                        scoreThreshold: 0,
                        thumbnailFileId: 115,
                        createdAt: "2020-01-10T04:23:51.000Z",
                        updatedAt: "2020-02-20T02:58:35.000Z",
                        quizId: 3,
                        thumbnailFile: {
                            id: 115,
                            size: 29671,
                            path: require("../images/Files/augustus_character_wishmastree_01.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"4cf5c10a77ceaad59a926dbc06fa643b"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T02:58:33.000Z",
                            updatedAt: "2020-02-20T02:58:33.000Z",
                        },
                    },
                    {
                        id: 11,
                        label: "Daddy Possum",
                        detail: "You are a big kid and oblivious to the world but are genuine and love greatly. Couldn’t hurt a fly but might miss if it is trapped somewhere. Some see you as naive but your happiness is infectious.",
                        copyId: null,
                        scoreThreshold: 0,
                        thumbnailFileId: 116,
                        createdAt: "2020-01-10T04:23:57.000Z",
                        updatedAt: "2020-02-20T02:58:50.000Z",
                        quizId: 3,
                        thumbnailFile: {
                            id: 116,
                            size: 33597,
                            path: require("../images/Files/daddypossum_characters_wishmastree_01.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"e4bbb8799fda79b00fcd3d00aaf31be2"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T02:58:49.000Z",
                            updatedAt: "2020-02-20T02:58:49.000Z",
                        },
                    },
                ],
                thumbnailFile: {
                    id: 280,
                    size: 37998,
                    path: require("../images/Files/quiz_thumbnail_3.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"9b97abea7367c817365b0f4e844d6e4b"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-26T06:39:25.000Z",
                    updatedAt: "2020-02-26T06:39:25.000Z",
                },
            },
        },
        {
            id: 10,
            type: "imageGallery",
            content_id: 2,
            label: "Gallery - Sanctuary Peak",
            title_copy_id: 10,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-01-30T04:34:40.000Z",
            forceLarge: false,
            createdAt: "2020-01-31T04:34:40.000Z",
            updatedAt: "2020-02-26T04:53:52.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 223,
                    contentId: 10,
                    tagId: 26,
                    createdAt: "2020-02-26T04:53:22.000Z",
                    updatedAt: "2020-02-26T04:53:22.000Z",
                    tag: {
                        id: 26,
                        tag_id: "gallery",
                        label: "Gallery",
                        createdAt: "2020-02-26T04:50:05.000Z",
                        updatedAt: "2020-02-26T04:50:05.000Z",
                    },
                },
                {
                    id: 224,
                    contentId: 10,
                    tagId: 14,
                    createdAt: "2020-02-26T04:53:43.000Z",
                    updatedAt: "2020-02-26T04:53:43.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 2,
                label: "Gallery - Sanctuary Peak",
                createdAt: "2020-01-31T04:34:35.000Z",
                updatedAt: "2020-02-26T04:53:14.000Z",
                imageGalleryImages: [
                    {
                        id: 19,
                        imageGalleryId: 2,
                        imageId: 19,
                        createdAt: "2020-02-26T04:48:20.000Z",
                        updatedAt: "2020-02-26T04:48:20.000Z",
                        image: {
                            id: 19,
                            url: null,
                            fileId: 210,
                            thumbnailFileId: 211,
                            label: "gallery_peak_1",
                            createdAt: "2020-02-26T04:33:15.000Z",
                            updatedAt: "2020-02-26T04:37:46.000Z",
                            thumbnailFile: {
                                id: 211,
                                size: 47678,
                                path: require("../images/Files/gallery_peak_1_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"d124bfc869ffe5f7525ffd34fb9635dc"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:37:45.000Z",
                                updatedAt: "2020-02-26T04:37:45.000Z",
                            },
                            file: {
                                id: 210,
                                size: 242826,
                                path: require("../images/Files/gallery_peak_1.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"c8843a34e51fc574db05792efce47fa9"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:37:43.000Z",
                                updatedAt: "2020-02-26T04:37:43.000Z",
                            },
                        },
                    },
                    {
                        id: 20,
                        imageGalleryId: 2,
                        imageId: 20,
                        createdAt: "2020-02-26T04:48:23.000Z",
                        updatedAt: "2020-02-26T04:48:23.000Z",
                        image: {
                            id: 20,
                            url: null,
                            fileId: 212,
                            thumbnailFileId: 213,
                            label: "gallery_peak_2",
                            createdAt: "2020-02-26T04:33:18.000Z",
                            updatedAt: "2020-02-26T04:37:57.000Z",
                            thumbnailFile: {
                                id: 213,
                                size: 48623,
                                path: require("../images/Files/gallery_peak_2_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"a84ddc53860ce47f8ea38f94dc650eeb"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:37:56.000Z",
                                updatedAt: "2020-02-26T04:37:56.000Z",
                            },
                            file: {
                                id: 212,
                                size: 307654,
                                path: require("../images/Files/gallery_peak_2.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"2a4cea69b88036cb5cf19705c242a4c5"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:37:55.000Z",
                                updatedAt: "2020-02-26T04:37:55.000Z",
                            },
                        },
                    },
                    {
                        id: 21,
                        imageGalleryId: 2,
                        imageId: 21,
                        createdAt: "2020-02-26T04:48:27.000Z",
                        updatedAt: "2020-02-26T04:48:27.000Z",
                        image: {
                            id: 21,
                            url: null,
                            fileId: 215,
                            thumbnailFileId: 214,
                            label: "gallery_peak_3",
                            createdAt: "2020-02-26T04:33:21.000Z",
                            updatedAt: "2020-02-26T04:38:09.000Z",
                            thumbnailFile: {
                                id: 214,
                                size: 45747,
                                path: require("../images/Files/gallery_peak_3_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"528596689da29ae4cbb60575e06df220"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:38:07.000Z",
                                updatedAt: "2020-02-26T04:38:07.000Z",
                            },
                            file: {
                                id: 215,
                                size: 218571,
                                path: require("../images/Files/gallery_peak_3.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"cebe8509691646d81ce069dcfe124ccb"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:38:08.000Z",
                                updatedAt: "2020-02-26T04:38:08.000Z",
                            },
                        },
                    },
                    {
                        id: 22,
                        imageGalleryId: 2,
                        imageId: 22,
                        createdAt: "2020-02-26T04:48:30.000Z",
                        updatedAt: "2020-02-26T04:48:30.000Z",
                        image: {
                            id: 22,
                            url: null,
                            fileId: 217,
                            thumbnailFileId: 216,
                            label: "gallery_peak_4",
                            createdAt: "2020-02-26T04:33:23.000Z",
                            updatedAt: "2020-02-26T04:38:23.000Z",
                            thumbnailFile: {
                                id: 216,
                                size: 29269,
                                path: require("../images/Files/gallery_peak_4_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"2fd8a5930f7c59d5f4e78b3698324650"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:38:21.000Z",
                                updatedAt: "2020-02-26T04:38:21.000Z",
                            },
                            file: {
                                id: 217,
                                size: 172282,
                                path: require("../images/Files/gallery_peak_4.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"3360fd828eed1ca14d7b75ceccca1394"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:38:22.000Z",
                                updatedAt: "2020-02-26T04:38:22.000Z",
                            },
                        },
                    },
                    {
                        id: 23,
                        imageGalleryId: 2,
                        imageId: 23,
                        createdAt: "2020-02-26T04:48:34.000Z",
                        updatedAt: "2020-02-26T04:48:34.000Z",
                        image: {
                            id: 23,
                            url: null,
                            fileId: 219,
                            thumbnailFileId: 218,
                            label: "gallery_peak_5",
                            createdAt: "2020-02-26T04:33:25.000Z",
                            updatedAt: "2020-02-26T04:38:39.000Z",
                            thumbnailFile: {
                                id: 218,
                                size: 27021,
                                path: require("../images/Files/gallery_peak_5_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"7b5b319a8739683a0bd52d481f6df445"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:38:36.000Z",
                                updatedAt: "2020-02-26T04:38:36.000Z",
                            },
                            file: {
                                id: 219,
                                size: 127132,
                                path: require("../images/Files/gallery_peak_5.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"db2fbc1ccb10b92991fbcf7a7999c184"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:38:39.000Z",
                                updatedAt: "2020-02-26T04:38:39.000Z",
                            },
                        },
                    },
                    {
                        id: 24,
                        imageGalleryId: 2,
                        imageId: 24,
                        createdAt: "2020-02-26T04:48:37.000Z",
                        updatedAt: "2020-02-26T04:48:37.000Z",
                        image: {
                            id: 24,
                            url: null,
                            fileId: 221,
                            thumbnailFileId: 220,
                            label: "gallery_peak_6",
                            createdAt: "2020-02-26T04:33:28.000Z",
                            updatedAt: "2020-02-26T04:38:50.000Z",
                            thumbnailFile: {
                                id: 220,
                                size: 31209,
                                path: require("../images/Files/gallery_peak_6_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"f3688009ad6dfcb14473a7da0aa187e7"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:38:46.000Z",
                                updatedAt: "2020-02-26T04:38:46.000Z",
                            },
                            file: {
                                id: 221,
                                size: 158007,
                                path: require("../images/Files/gallery_peak_6.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"66d36eda7bee78cf47c2820be430119d"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:38:49.000Z",
                                updatedAt: "2020-02-26T04:38:49.000Z",
                            },
                        },
                    },
                ],
            },
        },
        {
            id: 11,
            type: "video",
            content_id: 1,
            label: "DIY Misty Muds Bath Bombs!",
            title_copy_id: 11,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-04T00:45:58.000Z",
            forceLarge: false,
            createdAt: "2020-02-04T00:45:58.000Z",
            updatedAt: "2020-02-25T22:10:58.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 3,
                    contentId: 11,
                    tagId: 8,
                    createdAt: "2020-02-04T01:26:47.000Z",
                    updatedAt: "2020-02-04T01:26:47.000Z",
                    tag: {
                        id: 8,
                        tag_id: "diy",
                        label: "DIY",
                        createdAt: "2020-02-04T01:14:13.000Z",
                        updatedAt: "2020-02-04T01:14:13.000Z",
                    },
                },
                {
                    id: 4,
                    contentId: 11,
                    tagId: 2,
                    createdAt: "2020-02-04T01:26:49.000Z",
                    updatedAt: "2020-02-04T01:26:49.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 5,
                    contentId: 11,
                    tagId: 18,
                    createdAt: "2020-02-04T01:27:24.000Z",
                    updatedAt: "2020-02-04T01:27:24.000Z",
                    tag: {
                        id: 18,
                        tag_id: "recipe",
                        label: "Recipe",
                        createdAt: "2020-02-04T01:26:33.000Z",
                        updatedAt: "2020-02-04T01:26:33.000Z",
                    },
                },
                {
                    id: 21,
                    contentId: 11,
                    tagId: 14,
                    createdAt: "2020-02-05T22:49:58.000Z",
                    updatedAt: "2020-02-05T22:49:58.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 1,
                url: "https://www.youtube.com/embed/k-L1OSQ6OrA",
                thumbnailFileId: 281,
                label: "DIY Misty Muds Bath Bombs! | Sanctuary City Creations",
                createdAt: "2020-02-04T00:44:59.000Z",
                updatedAt: "2020-02-27T00:40:12.000Z",
                thumbnailFile: {
                    id: 281,
                    size: 7178761,
                    path: require("../images/Files/DIY_bathbombs.png"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"ec21a8ca258795c7a505f719748938a3-2"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-27T00:40:08.000Z",
                    updatedAt: "2020-02-27T00:40:08.000Z",
                },
            },
        },
        {
            id: 12,
            type: "downloadable",
            content_id: 1,
            label: "Colouring In - Augustus",
            title_copy_id: 12,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-03T01:12:19.000Z",
            forceLarge: false,
            createdAt: "2020-02-04T01:12:19.000Z",
            updatedAt: "2020-02-06T01:37:16.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 204,
                    contentId: 12,
                    tagId: 21,
                    createdAt: "2020-02-24T04:40:27.000Z",
                    updatedAt: "2020-02-24T04:40:27.000Z",
                    tag: {
                        id: 21,
                        tag_id: "colouringin",
                        label: "Colouring In",
                        createdAt: "2020-02-06T01:42:33.000Z",
                        updatedAt: "2020-02-06T01:42:33.000Z",
                    },
                },
                {
                    id: 205,
                    contentId: 12,
                    tagId: 6,
                    createdAt: "2020-02-24T04:40:29.000Z",
                    updatedAt: "2020-02-24T04:40:29.000Z",
                    tag: {
                        id: 6,
                        tag_id: "downloadable",
                        label: "Downloadable",
                        createdAt: "2019-12-18T03:23:49.000Z",
                        updatedAt: "2019-12-18T03:23:49.000Z",
                    },
                },
                {
                    id: 206,
                    contentId: 12,
                    tagId: 17,
                    createdAt: "2020-02-24T04:40:31.000Z",
                    updatedAt: "2020-02-24T04:40:31.000Z",
                    tag: {
                        id: 17,
                        tag_id: "augustus",
                        label: "Augustus",
                        createdAt: "2020-02-04T01:26:13.000Z",
                        updatedAt: "2020-02-04T01:26:13.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 1,
                url: "",
                label: "Colouring In - Augustus",
                thumbnailFileId: 191,
                fileId: 31,
                createdAt: "2020-02-03T04:15:18.000Z",
                updatedAt: "2020-02-26T04:17:55.000Z",
                file: {
                    id: 31,
                    size: 481850,
                    path: require("../images/Files/colouring_in_augustus.pdf"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"1feb8f83e31504ea67d110bde440b9ae"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-06T01:37:01.000Z",
                    updatedAt: "2020-02-06T01:37:01.000Z",
                },
                thumbnailFile: {
                    id: 191,
                    size: 61673,
                    path: require("../images/Files/colouring_in_augustus_thumb_3.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"0dd238132665ebf5052cbe9a2e6c16ec"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-26T04:17:49.000Z",
                    updatedAt: "2020-02-26T04:17:49.000Z",
                },
            },
        },
        {
            id: 13,
            type: "interactiveMap",
            content_id: 1,
            label: "Sanctuary City",
            title_copy_id: 13,
            titleTextCopyId: null,
            priority: 500,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-04T02:09:19.000Z",
            forceLarge: true,
            createdAt: "2020-02-04T02:09:19.000Z",
            updatedAt: "2020-02-25T22:05:00.000Z",
            titleTextCopy: null,
            contentTags: [],
            linkedContent: {
                id: 1,
                label: "Sanctuary City",
                createdAt: "2020-02-04T02:07:40.000Z",
                updatedAt: "2020-02-25T22:04:52.000Z",
            },
        },
        {
            id: 14,
            type: "locProfile",
            content_id: 1,
            label: "Treehouse District",
            title_copy_id: 14,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-03T02:20:47.000Z",
            forceLarge: false,
            createdAt: "2020-02-04T02:20:47.000Z",
            updatedAt: "2020-02-27T02:03:22.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 22,
                    contentId: 14,
                    tagId: 14,
                    createdAt: "2020-02-05T22:50:18.000Z",
                    updatedAt: "2020-02-05T22:50:18.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 1,
                positionX: 1200,
                positionY: 600,
                label: "The Treehouse District",
                thumbnailFileId: 25,
                iconFileId: 189,
                description:
                    "The Treehouse District is home to all tree-dwelling animals including marsupials and birds. This is where Kerry, her sister Petra and Dad live. This district is a paradise for any animal that loves to live life high up in the clouds and features a suspended treetop pool and a zipline running through for ease of transport! The Treetops District also has a sophisticated system of interconnected pathways linking houses from bark to branch. \n\nSanctuary Fact! The design of the Treetops District is inspired by the walkways of the Daintree Rainforest in North Queensland, Australia.",
                imageFileId: 111,
                createdAt: "2020-02-04T01:32:40.000Z",
                updatedAt: "2020-02-27T01:50:04.000Z",
                thumbnailFile: {
                    id: 25,
                    size: 21254,
                    path: require("../images/Files/TWT_Sc0050_Shot2_thumb.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"cb8cdde0ee146b817c78644516103357"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-01-31T04:32:01.000Z",
                    updatedAt: "2020-01-31T04:32:01.000Z",
                },
                iconFile: {
                    id: 189,
                    size: 5233,
                    path: require("../images/Files/mapIcon.png"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"59b8c483a5f5817ec71510a88725d641"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-26T00:52:25.000Z",
                    updatedAt: "2020-02-26T00:52:25.000Z",
                },
                file: {
                    id: 111,
                    size: 37506,
                    path: require("../images/Files/kerry_character_wishmastree_01.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"4613dddaf9f2de98a230c0574238e1d4"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-20T02:57:19.000Z",
                    updatedAt: "2020-02-20T02:57:19.000Z",
                },
                locationProfileContent: [
                    {
                        id: 3,
                        contentId: 15,
                        locationProfileId: 1,
                        createdAt: "2020-02-05T23:59:46.000Z",
                        updatedAt: "2020-02-05T23:59:46.000Z",
                        content: {
                            id: 15,
                            type: "quiz",
                            content_id: 6,
                            label: "Can you ace this quiz?",
                            title_copy_id: 15,
                            titleTextCopyId: null,
                            priority: 0,
                            expands: false,
                            isPublic: 1,
                            publishedAt: "2020-02-03T07:20:51.000Z",
                            forceLarge: false,
                            createdAt: "2020-02-04T07:20:51.000Z",
                            updatedAt: "2020-02-25T23:46:19.000Z",
                        },
                    },
                    {
                        id: 4,
                        contentId: 1,
                        locationProfileId: 1,
                        createdAt: "2020-02-05T23:59:54.000Z",
                        updatedAt: "2020-02-05T23:59:54.000Z",
                        content: {
                            id: 1,
                            type: "imageGallery",
                            content_id: 1,
                            label: "Gallery - Kerry",
                            title_copy_id: 1,
                            titleTextCopyId: null,
                            priority: 0,
                            expands: false,
                            isPublic: 1,
                            publishedAt: "2019-12-03T14:00:00.000Z",
                            forceLarge: false,
                            createdAt: "2019-12-18T05:53:59.000Z",
                            updatedAt: "2020-02-26T04:54:16.000Z",
                        },
                    },
                    {
                        id: 5,
                        contentId: 22,
                        locationProfileId: 1,
                        createdAt: "2020-02-05T23:59:59.000Z",
                        updatedAt: "2020-02-05T23:59:59.000Z",
                        content: {
                            id: 22,
                            type: "video",
                            content_id: 2,
                            label: "DIY Bio Bird Feeder!",
                            title_copy_id: 22,
                            titleTextCopyId: null,
                            priority: 0,
                            expands: false,
                            isPublic: 1,
                            publishedAt: "2020-02-04T21:56:55.000Z",
                            forceLarge: false,
                            createdAt: "2020-02-05T21:56:55.000Z",
                            updatedAt: "2020-02-25T22:10:32.000Z",
                        },
                    },
                ],
            },
        },
        {
            id: 15,
            type: "quiz",
            content_id: 6,
            label: "Can you ace this quiz?",
            title_copy_id: 15,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-03T07:20:51.000Z",
            forceLarge: false,
            createdAt: "2020-02-04T07:20:51.000Z",
            updatedAt: "2020-02-25T23:46:19.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 23,
                    contentId: 15,
                    tagId: 14,
                    createdAt: "2020-02-05T22:50:29.000Z",
                    updatedAt: "2020-02-05T22:50:29.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 24,
                    contentId: 15,
                    tagId: 9,
                    createdAt: "2020-02-05T22:50:31.000Z",
                    updatedAt: "2020-02-05T22:50:31.000Z",
                    tag: {
                        id: 9,
                        tag_id: "kerry",
                        label: "Kerry",
                        createdAt: "2020-02-04T01:25:00.000Z",
                        updatedAt: "2020-02-04T01:25:00.000Z",
                    },
                },
                {
                    id: 25,
                    contentId: 15,
                    tagId: 10,
                    createdAt: "2020-02-05T22:50:34.000Z",
                    updatedAt: "2020-02-05T22:50:34.000Z",
                    tag: {
                        id: 10,
                        tag_id: "petra",
                        label: "Petra",
                        createdAt: "2020-02-04T01:25:05.000Z",
                        updatedAt: "2020-02-04T01:25:05.000Z",
                    },
                },
                {
                    id: 26,
                    contentId: 15,
                    tagId: 11,
                    createdAt: "2020-02-05T22:50:35.000Z",
                    updatedAt: "2020-02-05T22:50:35.000Z",
                    tag: {
                        id: 11,
                        tag_id: "yarra",
                        label: "Yarra",
                        createdAt: "2020-02-04T01:25:09.000Z",
                        updatedAt: "2020-02-04T01:25:09.000Z",
                    },
                },
                {
                    id: 27,
                    contentId: 15,
                    tagId: 15,
                    createdAt: "2020-02-05T22:50:40.000Z",
                    updatedAt: "2020-02-05T22:50:40.000Z",
                    tag: {
                        id: 15,
                        tag_id: "stick",
                        label: "Stick",
                        createdAt: "2020-02-04T01:26:00.000Z",
                        updatedAt: "2020-02-04T01:26:00.000Z",
                    },
                },
                {
                    id: 28,
                    contentId: 15,
                    tagId: 12,
                    createdAt: "2020-02-05T22:50:41.000Z",
                    updatedAt: "2020-02-05T22:50:41.000Z",
                    tag: {
                        id: 12,
                        tag_id: "bernard",
                        label: "Bernard",
                        createdAt: "2020-02-04T01:25:16.000Z",
                        updatedAt: "2020-02-04T01:25:16.000Z",
                    },
                },
                {
                    id: 42,
                    contentId: 15,
                    tagId: 13,
                    createdAt: "2020-02-05T22:52:58.000Z",
                    updatedAt: "2020-02-05T22:52:58.000Z",
                    tag: {
                        id: 13,
                        tag_id: "quiz",
                        label: "Quiz",
                        createdAt: "2020-02-04T01:25:21.000Z",
                        updatedAt: "2020-02-04T01:25:21.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 6,
                label: "Can you ace this quiz?",
                copyId: null,
                thumbnailFileId: 279,
                quizType: 2,
                createdAt: "2020-02-04T07:13:08.000Z",
                updatedAt: "2020-02-26T06:39:15.000Z",
                copy: null,
                quizQuestions: [
                    {
                        id: 15,
                        label: "How many petals does a Wishmas flower have?",
                        copyId: null,
                        thumbnailFileId: 117,
                        createdAt: "2020-02-04T07:13:08.000Z",
                        updatedAt: "2020-02-20T04:17:31.000Z",
                        quizId: 6,
                        thumbnailFile: {
                            id: 117,
                            size: 49889,
                            path: require("../images/Files/Q1Answer_wishmasflower_wishmastree.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"f70c1bc91be3ae53bb7a112a9c001ffd"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T04:17:30.000Z",
                            updatedAt: "2020-02-20T04:17:30.000Z",
                        },
                        quizAnswers: [
                            {
                                id: 57,
                                label: "4",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 15,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:15:08.000Z",
                                updatedAt: "2020-02-04T07:15:08.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 58,
                                label: "5",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 15,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:15:15.000Z",
                                updatedAt: "2020-02-04T07:15:15.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 59,
                                label: "6",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 15,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:15:20.000Z",
                                updatedAt: "2020-02-04T07:15:20.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 60,
                                label: "7",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 15,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:15:27.000Z",
                                updatedAt: "2020-02-04T07:15:27.000Z",
                                thumbnailFile: null,
                            },
                        ],
                    },
                    {
                        id: 16,
                        label: "How many foundling children are there?",
                        copyId: null,
                        thumbnailFileId: 118,
                        createdAt: "2020-02-04T07:13:08.000Z",
                        updatedAt: "2020-02-20T04:17:49.000Z",
                        quizId: 6,
                        thumbnailFile: {
                            id: 118,
                            size: 45363,
                            path: require("../images/Files/Q2Answer_foundingchildren_wishmastree.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"e3feaa2e62d2fb4b8e9333831a9fdd4d"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T04:17:47.000Z",
                            updatedAt: "2020-02-20T04:17:47.000Z",
                        },
                        quizAnswers: [
                            {
                                id: 61,
                                label: "3",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 16,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:15:42.000Z",
                                updatedAt: "2020-02-04T07:15:42.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 62,
                                label: "4",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 16,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:15:46.000Z",
                                updatedAt: "2020-02-04T07:15:46.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 63,
                                label: "5",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 16,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:15:51.000Z",
                                updatedAt: "2020-02-04T07:15:51.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 64,
                                label: "10",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 16,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:15:53.000Z",
                                updatedAt: "2020-02-04T07:15:53.000Z",
                                thumbnailFile: null,
                            },
                        ],
                    },
                    {
                        id: 17,
                        label: "What animal is not one of the founding children?",
                        copyId: null,
                        thumbnailFileId: 119,
                        createdAt: "2020-02-04T07:13:08.000Z",
                        updatedAt: "2020-02-20T04:18:01.000Z",
                        quizId: 6,
                        thumbnailFile: {
                            id: 119,
                            size: 51870,
                            path: require("../images/Files/Q3Answer_foundingchildren2_wishmastree.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"0e2bcddae3c73aa71941cbad7cb015ad"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T04:18:00.000Z",
                            updatedAt: "2020-02-20T04:18:00.000Z",
                        },
                        quizAnswers: [
                            {
                                id: 65,
                                label: "Crocodile",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 17,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:16:07.000Z",
                                updatedAt: "2020-02-04T07:16:07.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 66,
                                label: "Possum",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 17,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:16:13.000Z",
                                updatedAt: "2020-02-04T07:16:13.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 67,
                                label: "Snake",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 17,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:16:17.000Z",
                                updatedAt: "2020-02-04T07:16:17.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 68,
                                label: "Lorikeet",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 17,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:16:23.000Z",
                                updatedAt: "2020-02-04T07:16:23.000Z",
                                thumbnailFile: null,
                            },
                        ],
                    },
                    {
                        id: 18,
                        label: "Who is the Gatekeeper to the Dropbear Kingdom?",
                        copyId: null,
                        thumbnailFileId: 120,
                        createdAt: "2020-02-04T07:13:08.000Z",
                        updatedAt: "2020-02-20T04:18:13.000Z",
                        quizId: 6,
                        thumbnailFile: {
                            id: 120,
                            size: 40268,
                            path: require("../images/Files/Q4Answer_reveal4_wishmastree.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"b4c8dcfe88c4974e5c7501aa380ddd4c"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T04:18:11.000Z",
                            updatedAt: "2020-02-20T04:18:11.000Z",
                        },
                        quizAnswers: [
                            {
                                id: 69,
                                label: "Augustus",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 18,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:16:33.000Z",
                                updatedAt: "2020-02-04T07:16:33.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 70,
                                label: "Yarra",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 18,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:16:39.000Z",
                                updatedAt: "2020-02-04T07:16:39.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 71,
                                label: "A bear",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 18,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:16:45.000Z",
                                updatedAt: "2020-02-04T07:16:45.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 72,
                                label: "Bernard",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 18,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:16:48.000Z",
                                updatedAt: "2020-02-04T07:16:48.000Z",
                                thumbnailFile: null,
                            },
                        ],
                    },
                    {
                        id: 19,
                        label: "What does Kerry compare a Cane Toad's brains to?",
                        copyId: null,
                        thumbnailFileId: 121,
                        createdAt: "2020-02-04T07:13:08.000Z",
                        updatedAt: "2020-02-20T04:18:30.000Z",
                        quizId: 6,
                        thumbnailFile: {
                            id: 121,
                            size: 49840,
                            path: require("../images/Files/Q5Answer_Augustus_peanut_wishmastree.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"1c21c3bb6b774c8d51e27dc7ccb0043b"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T04:18:29.000Z",
                            updatedAt: "2020-02-20T04:18:29.000Z",
                        },
                        quizAnswers: [
                            {
                                id: 73,
                                label: "Grain of rice",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 19,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:16:59.000Z",
                                updatedAt: "2020-02-04T07:16:59.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 74,
                                label: "A peanut",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 19,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:17:03.000Z",
                                updatedAt: "2020-02-04T07:17:03.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 75,
                                label: "An avocado",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 19,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:17:11.000Z",
                                updatedAt: "2020-02-04T07:17:11.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 76,
                                label: "A walnut",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 19,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:17:26.000Z",
                                updatedAt: "2020-02-04T07:17:26.000Z",
                                thumbnailFile: null,
                            },
                        ],
                    },
                    {
                        id: 20,
                        label: "What is the one rule of Sanctuary City?",
                        copyId: null,
                        thumbnailFileId: 122,
                        createdAt: "2020-02-04T07:13:08.000Z",
                        updatedAt: "2020-02-20T04:19:32.000Z",
                        quizId: 6,
                        thumbnailFile: {
                            id: 122,
                            size: 50354,
                            path: require("../images/Files/Q6Answer_Donoteat_wishmastree.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"7ca9ecf1ecb6a894b3fe0e98336ed78d"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T04:18:41.000Z",
                            updatedAt: "2020-02-20T04:18:41.000Z",
                        },
                        quizAnswers: [
                            {
                                id: 77,
                                label: "Never leave the city",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 20,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:17:42.000Z",
                                updatedAt: "2020-02-04T07:17:42.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 78,
                                label: "Do not eat one another",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 20,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:17:48.000Z",
                                updatedAt: "2020-02-04T07:17:48.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 79,
                                label: "Do not trust a drop bear",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 20,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:18:10.000Z",
                                updatedAt: "2020-02-04T07:18:10.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 80,
                                label: "You cannot come back from the wild",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 20,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:18:17.000Z",
                                updatedAt: "2020-02-04T07:18:17.000Z",
                                thumbnailFile: null,
                            },
                        ],
                    },
                    {
                        id: 21,
                        label: "How old is Petra?",
                        copyId: null,
                        thumbnailFileId: 123,
                        createdAt: "2020-02-04T07:13:08.000Z",
                        updatedAt: "2020-02-20T04:19:58.000Z",
                        quizId: 6,
                        thumbnailFile: {
                            id: 123,
                            size: 54152,
                            path: require("../images/Files/Q10Answer_imfourteen_wishmastree.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"259b4c90833639fdfb949fbe4b75f7b2"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T04:19:56.000Z",
                            updatedAt: "2020-02-20T04:19:56.000Z",
                        },
                        quizAnswers: [
                            {
                                id: 81,
                                label: "10",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 21,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:18:52.000Z",
                                updatedAt: "2020-02-04T07:18:52.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 82,
                                label: "14",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 21,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:18:55.000Z",
                                updatedAt: "2020-02-04T07:18:55.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 83,
                                label: "16",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 21,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:19:00.000Z",
                                updatedAt: "2020-02-04T07:19:00.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 84,
                                label: "12",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 21,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:19:04.000Z",
                                updatedAt: "2020-02-04T07:19:04.000Z",
                                thumbnailFile: null,
                            },
                        ],
                    },
                    {
                        id: 22,
                        label: "What is NOT one of the colours of Stick's beads?",
                        copyId: null,
                        thumbnailFileId: 124,
                        createdAt: "2020-02-04T07:13:08.000Z",
                        updatedAt: "2020-02-20T06:38:01.000Z",
                        quizId: 6,
                        thumbnailFile: {
                            id: 124,
                            size: 52240,
                            path: require("../images/Files/Q7Answer_Stick_wishmastree.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"e034ad4ac59d6f507d8e1cae4e2e5d1d"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T04:20:26.000Z",
                            updatedAt: "2020-02-20T04:20:26.000Z",
                        },
                        quizAnswers: [
                            {
                                id: 85,
                                label: "Blue",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 22,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:19:13.000Z",
                                updatedAt: "2020-02-04T07:19:13.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 86,
                                label: "Red",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 22,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:19:17.000Z",
                                updatedAt: "2020-02-04T07:19:17.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 87,
                                label: "Yellow",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 22,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:19:20.000Z",
                                updatedAt: "2020-02-04T07:19:20.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 88,
                                label: "Green",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 22,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:19:24.000Z",
                                updatedAt: "2020-02-04T07:19:24.000Z",
                                thumbnailFile: null,
                            },
                        ],
                    },
                    {
                        id: 23,
                        label: "What item does Gilbert the Gerbil carry?",
                        copyId: null,
                        thumbnailFileId: 125,
                        createdAt: "2020-02-04T07:13:08.000Z",
                        updatedAt: "2020-02-20T06:38:12.000Z",
                        quizId: 6,
                        thumbnailFile: {
                            id: 125,
                            size: 44206,
                            path: require("../images/Files/Q8Answer_Gilbert_wishmastree.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"d1a713d67881a35bed8fa626ddf92fe1"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T04:20:36.000Z",
                            updatedAt: "2020-02-20T04:20:36.000Z",
                        },
                        quizAnswers: [
                            {
                                id: 89,
                                label: "A map",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 23,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:19:34.000Z",
                                updatedAt: "2020-02-04T07:19:34.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 90,
                                label: "A camera",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 23,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:19:39.000Z",
                                updatedAt: "2020-02-04T07:19:39.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 91,
                                label: "Binoculars",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 23,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:19:47.000Z",
                                updatedAt: "2020-02-04T07:19:47.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 92,
                                label: "A radio",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 23,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:20:03.000Z",
                                updatedAt: "2020-02-04T07:20:03.000Z",
                                thumbnailFile: null,
                            },
                        ],
                    },
                    {
                        id: 24,
                        label: "What is the name of the convenience store?",
                        copyId: null,
                        thumbnailFileId: 126,
                        createdAt: "2020-02-04T07:13:08.000Z",
                        updatedAt: "2020-02-20T04:20:46.000Z",
                        quizId: 6,
                        thumbnailFile: {
                            id: 126,
                            size: 57307,
                            path: require("../images/Files/Q9Answer_Owlways_wishmastree.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"caa6d565ccbab9d98238e04828339e93"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T04:20:44.000Z",
                            updatedAt: "2020-02-20T04:20:44.000Z",
                        },
                        quizAnswers: [
                            {
                                id: 93,
                                label: "Anytime Owls",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 24,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:20:15.000Z",
                                updatedAt: "2020-02-04T07:20:15.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 94,
                                label: "Always Owlpen",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 24,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:20:32.000Z",
                                updatedAt: "2020-02-04T07:20:32.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 95,
                                label: "Owlways Open",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 24,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:20:38.000Z",
                                updatedAt: "2020-02-04T07:20:38.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 96,
                                label: "Sweetie's",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 24,
                                thumbnailFileId: null,
                                createdAt: "2020-02-04T07:20:44.000Z",
                                updatedAt: "2020-02-04T07:20:44.000Z",
                                thumbnailFile: null,
                            },
                        ],
                    },
                ],
                quizResults: [
                    {
                        id: 14,
                        label: "1-3",
                        detail: "Oof! Stop what you are doing and watch The Wishmas Tree! The world of Sanctuary City is waiting. If you have seen the film then YIKES! You need a rewatch stat! ",
                        copyId: null,
                        scoreThreshold: 0,
                        thumbnailFileId: 127,
                        createdAt: "2020-02-04T07:13:09.000Z",
                        updatedAt: "2020-02-20T22:40:22.000Z",
                        quizId: 6,
                        thumbnailFile: {
                            id: 127,
                            size: 79643,
                            path: require("../images/Files/quiz_bad.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"c870f41e6e06f20569b60061e9cceb54"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T22:40:20.000Z",
                            updatedAt: "2020-02-20T22:40:20.000Z",
                        },
                    },
                    {
                        id: 15,
                        label: "4-7",
                        detail: "Not bad! You have seen the film but it looks as though you could get a refresher! You remember some of the things but a true fan knows all the small details!",
                        copyId: null,
                        scoreThreshold: 4,
                        thumbnailFileId: 128,
                        createdAt: "2020-02-04T07:13:09.000Z",
                        updatedAt: "2020-02-20T22:40:42.000Z",
                        quizId: 6,
                        thumbnailFile: {
                            id: 128,
                            size: 75506,
                            path: require("../images/Files/quiz_mid.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"5191dd5f21bc4df89e99437422876bf4"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T22:40:41.000Z",
                            updatedAt: "2020-02-20T22:40:41.000Z",
                        },
                    },
                    {
                        id: 16,
                        label: "8-10",
                        detail: "Wow, you are a Wishmas Tree wiz! You have seen it front to back and back to front. You remember all the details and can consider yourself a true fan of the film! Well done!",
                        copyId: null,
                        scoreThreshold: 8,
                        thumbnailFileId: 129,
                        createdAt: "2020-02-04T07:13:09.000Z",
                        updatedAt: "2020-02-20T22:41:00.000Z",
                        quizId: 6,
                        thumbnailFile: {
                            id: 129,
                            size: 77801,
                            path: require("../images/Files/quiz_good.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"ae0f9e4a0d548ba4353bcb514632166f"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T22:40:59.000Z",
                            updatedAt: "2020-02-20T22:40:59.000Z",
                        },
                    },
                ],
                thumbnailFile: {
                    id: 279,
                    size: 38254,
                    path: require("../images/Files/quiz_thumbnail_6.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"5d2d630c1ae11608c4a84b34276a1d65"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-26T06:39:14.000Z",
                    updatedAt: "2020-02-26T06:39:14.000Z",
                },
            },
        },
        {
            id: 16,
            type: "quiz",
            content_id: 7,
            label: "Match the quotes to the characters!",
            title_copy_id: 16,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-03T23:38:46.000Z",
            forceLarge: false,
            createdAt: "2020-02-04T23:38:46.000Z",
            updatedAt: "2020-02-25T23:46:29.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 29,
                    contentId: 16,
                    tagId: 9,
                    createdAt: "2020-02-05T22:50:58.000Z",
                    updatedAt: "2020-02-05T22:50:58.000Z",
                    tag: {
                        id: 9,
                        tag_id: "kerry",
                        label: "Kerry",
                        createdAt: "2020-02-04T01:25:00.000Z",
                        updatedAt: "2020-02-04T01:25:00.000Z",
                    },
                },
                {
                    id: 30,
                    contentId: 16,
                    tagId: 15,
                    createdAt: "2020-02-05T22:51:00.000Z",
                    updatedAt: "2020-02-05T22:51:00.000Z",
                    tag: {
                        id: 15,
                        tag_id: "stick",
                        label: "Stick",
                        createdAt: "2020-02-04T01:26:00.000Z",
                        updatedAt: "2020-02-04T01:26:00.000Z",
                    },
                },
                {
                    id: 31,
                    contentId: 16,
                    tagId: 10,
                    createdAt: "2020-02-05T22:51:01.000Z",
                    updatedAt: "2020-02-05T22:51:01.000Z",
                    tag: {
                        id: 10,
                        tag_id: "petra",
                        label: "Petra",
                        createdAt: "2020-02-04T01:25:05.000Z",
                        updatedAt: "2020-02-04T01:25:05.000Z",
                    },
                },
                {
                    id: 32,
                    contentId: 16,
                    tagId: 11,
                    createdAt: "2020-02-05T22:51:04.000Z",
                    updatedAt: "2020-02-05T22:51:04.000Z",
                    tag: {
                        id: 11,
                        tag_id: "yarra",
                        label: "Yarra",
                        createdAt: "2020-02-04T01:25:09.000Z",
                        updatedAt: "2020-02-04T01:25:09.000Z",
                    },
                },
                {
                    id: 33,
                    contentId: 16,
                    tagId: 17,
                    createdAt: "2020-02-05T22:51:06.000Z",
                    updatedAt: "2020-02-05T22:51:06.000Z",
                    tag: {
                        id: 17,
                        tag_id: "augustus",
                        label: "Augustus",
                        createdAt: "2020-02-04T01:26:13.000Z",
                        updatedAt: "2020-02-04T01:26:13.000Z",
                    },
                },
                {
                    id: 34,
                    contentId: 16,
                    tagId: 14,
                    createdAt: "2020-02-05T22:51:09.000Z",
                    updatedAt: "2020-02-05T22:51:09.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 7,
                label: "Match these characters and quotes!",
                copyId: null,
                thumbnailFileId: 278,
                quizType: 1,
                createdAt: "2020-02-04T23:27:03.000Z",
                updatedAt: "2020-02-26T06:39:04.000Z",
                copy: null,
                quizQuestions: [
                    {
                        id: 25,
                        label: "Should I or should I not eat the yellow snow?",
                        copyId: null,
                        thumbnailFileId: null,
                        createdAt: "2020-02-04T23:27:03.000Z",
                        updatedAt: "2020-02-04T23:27:03.000Z",
                        quizId: 7,
                        thumbnailFile: null,
                        quizAnswers: [
                            {
                                id: 97,
                                label: "Petra",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 25,
                                thumbnailFileId: 130,
                                createdAt: "2020-02-04T23:33:24.000Z",
                                updatedAt: "2020-02-20T22:44:27.000Z",
                                thumbnailFile: {
                                    id: 130,
                                    size: 45712,
                                    path: require("../images/Files/petra_1_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"d9cddfc113c3034b332431754701e870"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:44:26.000Z",
                                    updatedAt: "2020-02-20T22:44:26.000Z",
                                },
                            },
                            {
                                id: 98,
                                label: "Yarra",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 25,
                                thumbnailFileId: 131,
                                createdAt: "2020-02-04T23:33:30.000Z",
                                updatedAt: "2020-02-20T22:44:38.000Z",
                                thumbnailFile: {
                                    id: 131,
                                    size: 44189,
                                    path: require("../images/Files/yarra_1_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"397695bf696d5d292d0805f36fc8578f"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:44:36.000Z",
                                    updatedAt: "2020-02-20T22:44:36.000Z",
                                },
                            },
                            {
                                id: 99,
                                label: "Stick",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 25,
                                thumbnailFileId: 132,
                                createdAt: "2020-02-04T23:33:33.000Z",
                                updatedAt: "2020-02-20T22:45:08.000Z",
                                thumbnailFile: {
                                    id: 132,
                                    size: 46761,
                                    path: require("../images/Files/yarra_2_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"a202db671ebf39febe967840a9c53ebd"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:45:05.000Z",
                                    updatedAt: "2020-02-20T22:45:05.000Z",
                                },
                            },
                            {
                                id: 100,
                                label: "Kerry",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 25,
                                thumbnailFileId: 133,
                                createdAt: "2020-02-04T23:33:37.000Z",
                                updatedAt: "2020-02-20T22:45:18.000Z",
                                thumbnailFile: {
                                    id: 133,
                                    size: 45971,
                                    path: require("../images/Files/kerry_1_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"1a62864604d3a5cd57d043bf74ce0298"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:45:17.000Z",
                                    updatedAt: "2020-02-20T22:45:17.000Z",
                                },
                            },
                        ],
                    },
                    {
                        id: 26,
                        label: "It’s our very first TV!",
                        copyId: null,
                        thumbnailFileId: null,
                        createdAt: "2020-02-04T23:27:03.000Z",
                        updatedAt: "2020-02-04T23:27:03.000Z",
                        quizId: 7,
                        thumbnailFile: null,
                        quizAnswers: [
                            {
                                id: 101,
                                label: "Daddy Possum",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 26,
                                thumbnailFileId: 134,
                                createdAt: "2020-02-04T23:33:48.000Z",
                                updatedAt: "2020-02-20T22:45:36.000Z",
                                thumbnailFile: {
                                    id: 134,
                                    size: 40329,
                                    path: require("../images/Files/daddypossum_1_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"52bbf41ce5dcf6399007ba685a24d6bd"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:45:35.000Z",
                                    updatedAt: "2020-02-20T22:45:35.000Z",
                                },
                            },
                            {
                                id: 102,
                                label: "Kerry",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 26,
                                thumbnailFileId: 133,
                                createdAt: "2020-02-04T23:33:53.000Z",
                                updatedAt: "2020-02-20T22:45:49.000Z",
                                thumbnailFile: {
                                    id: 133,
                                    size: 45971,
                                    path: require("../images/Files/kerry_1_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"1a62864604d3a5cd57d043bf74ce0298"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:45:17.000Z",
                                    updatedAt: "2020-02-20T22:45:17.000Z",
                                },
                            },
                            {
                                id: 103,
                                label: "Petra",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 26,
                                thumbnailFileId: 130,
                                createdAt: "2020-02-04T23:33:57.000Z",
                                updatedAt: "2020-02-20T22:45:55.000Z",
                                thumbnailFile: {
                                    id: 130,
                                    size: 45712,
                                    path: require("../images/Files/petra_1_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"d9cddfc113c3034b332431754701e870"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:44:26.000Z",
                                    updatedAt: "2020-02-20T22:44:26.000Z",
                                },
                            },
                            {
                                id: 104,
                                label: "Augustus",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 26,
                                thumbnailFileId: 135,
                                createdAt: "2020-02-04T23:34:04.000Z",
                                updatedAt: "2020-02-20T22:46:09.000Z",
                                thumbnailFile: {
                                    id: 135,
                                    size: 37715,
                                    path: require("../images/Files/augustus_3_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"6b4ff0bc1132c9fdcf398ad3de716167"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:46:07.000Z",
                                    updatedAt: "2020-02-20T22:46:07.000Z",
                                },
                            },
                        ],
                    },
                    {
                        id: 27,
                        label: "Kerry the Ringtail Possum, you must fix what you have done!",
                        copyId: null,
                        thumbnailFileId: null,
                        createdAt: "2020-02-04T23:27:03.000Z",
                        updatedAt: "2020-02-04T23:27:03.000Z",
                        quizId: 7,
                        thumbnailFile: null,
                        quizAnswers: [
                            {
                                id: 105,
                                label: "Petra",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 27,
                                thumbnailFileId: 130,
                                createdAt: "2020-02-04T23:34:17.000Z",
                                updatedAt: "2020-02-20T22:46:22.000Z",
                                thumbnailFile: {
                                    id: 130,
                                    size: 45712,
                                    path: require("../images/Files/petra_1_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"d9cddfc113c3034b332431754701e870"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:44:26.000Z",
                                    updatedAt: "2020-02-20T22:44:26.000Z",
                                },
                            },
                            {
                                id: 106,
                                label: "Yarra",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 27,
                                thumbnailFileId: 131,
                                createdAt: "2020-02-04T23:34:21.000Z",
                                updatedAt: "2020-02-20T22:46:32.000Z",
                                thumbnailFile: {
                                    id: 131,
                                    size: 44189,
                                    path: require("../images/Files/yarra_1_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"397695bf696d5d292d0805f36fc8578f"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:44:36.000Z",
                                    updatedAt: "2020-02-20T22:44:36.000Z",
                                },
                            },
                            {
                                id: 107,
                                label: "Augustus",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 27,
                                thumbnailFileId: 135,
                                createdAt: "2020-02-04T23:34:28.000Z",
                                updatedAt: "2020-02-20T22:46:39.000Z",
                                thumbnailFile: {
                                    id: 135,
                                    size: 37715,
                                    path: require("../images/Files/augustus_3_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"6b4ff0bc1132c9fdcf398ad3de716167"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:46:07.000Z",
                                    updatedAt: "2020-02-20T22:46:07.000Z",
                                },
                            },
                            {
                                id: 108,
                                label: "Daddy Possum",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 27,
                                thumbnailFileId: 134,
                                createdAt: "2020-02-04T23:34:34.000Z",
                                updatedAt: "2020-02-20T22:46:44.000Z",
                                thumbnailFile: {
                                    id: 134,
                                    size: 40329,
                                    path: require("../images/Files/daddypossum_1_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"52bbf41ce5dcf6399007ba685a24d6bd"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:45:35.000Z",
                                    updatedAt: "2020-02-20T22:45:35.000Z",
                                },
                            },
                        ],
                    },
                    {
                        id: 28,
                        label: "You may proceed, but you shall never return!",
                        copyId: null,
                        thumbnailFileId: null,
                        createdAt: "2020-02-04T23:27:03.000Z",
                        updatedAt: "2020-02-04T23:27:03.000Z",
                        quizId: 7,
                        thumbnailFile: null,
                        quizAnswers: [
                            {
                                id: 109,
                                label: "Kerry",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 28,
                                thumbnailFileId: 133,
                                createdAt: "2020-02-04T23:34:47.000Z",
                                updatedAt: "2020-02-20T22:47:01.000Z",
                                thumbnailFile: {
                                    id: 133,
                                    size: 45971,
                                    path: require("../images/Files/kerry_1_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"1a62864604d3a5cd57d043bf74ce0298"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:45:17.000Z",
                                    updatedAt: "2020-02-20T22:45:17.000Z",
                                },
                            },
                            {
                                id: 110,
                                label: "Yarra",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 28,
                                thumbnailFileId: 131,
                                createdAt: "2020-02-04T23:34:55.000Z",
                                updatedAt: "2020-02-20T22:47:09.000Z",
                                thumbnailFile: {
                                    id: 131,
                                    size: 44189,
                                    path: require("../images/Files/yarra_1_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"397695bf696d5d292d0805f36fc8578f"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:44:36.000Z",
                                    updatedAt: "2020-02-20T22:44:36.000Z",
                                },
                            },
                            {
                                id: 111,
                                label: "Augustus",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 28,
                                thumbnailFileId: 135,
                                createdAt: "2020-02-04T23:35:00.000Z",
                                updatedAt: "2020-02-20T22:47:15.000Z",
                                thumbnailFile: {
                                    id: 135,
                                    size: 37715,
                                    path: require("../images/Files/augustus_3_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"6b4ff0bc1132c9fdcf398ad3de716167"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:46:07.000Z",
                                    updatedAt: "2020-02-20T22:46:07.000Z",
                                },
                            },
                            {
                                id: 112,
                                label: "Founding child Kookaburra",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 28,
                                thumbnailFileId: 136,
                                createdAt: "2020-02-04T23:35:40.000Z",
                                updatedAt: "2020-02-20T22:47:32.000Z",
                                thumbnailFile: {
                                    id: 136,
                                    size: 35281,
                                    path: require("../images/Files/foundingchild_kookaburra_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"d3e627ae6e931c907fe53a3d711878fe"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:47:30.000Z",
                                    updatedAt: "2020-02-20T22:47:30.000Z",
                                },
                            },
                        ],
                    },
                    {
                        id: 29,
                        label: "I wish I had a penguin friend who I could wear tuxedos with.",
                        copyId: null,
                        thumbnailFileId: null,
                        createdAt: "2020-02-04T23:27:03.000Z",
                        updatedAt: "2020-02-04T23:27:03.000Z",
                        quizId: 7,
                        thumbnailFile: null,
                        quizAnswers: [
                            {
                                id: 113,
                                label: "Petra",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 29,
                                thumbnailFileId: 130,
                                createdAt: "2020-02-04T23:35:51.000Z",
                                updatedAt: "2020-02-20T22:47:44.000Z",
                                thumbnailFile: {
                                    id: 130,
                                    size: 45712,
                                    path: require("../images/Files/petra_1_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"d9cddfc113c3034b332431754701e870"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:44:26.000Z",
                                    updatedAt: "2020-02-20T22:44:26.000Z",
                                },
                            },
                            {
                                id: 114,
                                label: "Stick",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 29,
                                thumbnailFileId: 132,
                                createdAt: "2020-02-04T23:35:56.000Z",
                                updatedAt: "2020-02-20T22:47:52.000Z",
                                thumbnailFile: {
                                    id: 132,
                                    size: 46761,
                                    path: require("../images/Files/yarra_2_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"a202db671ebf39febe967840a9c53ebd"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:45:05.000Z",
                                    updatedAt: "2020-02-20T22:45:05.000Z",
                                },
                            },
                            {
                                id: 115,
                                label: "Sweetie",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 29,
                                thumbnailFileId: 137,
                                createdAt: "2020-02-04T23:36:00.000Z",
                                updatedAt: "2020-02-20T22:48:04.000Z",
                                thumbnailFile: {
                                    id: 137,
                                    size: 48198,
                                    path: require("../images/Files/sweetie_1_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"a8d07fa3a6a9896d0941bf2ebe4e46a9"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:48:03.000Z",
                                    updatedAt: "2020-02-20T22:48:03.000Z",
                                },
                            },
                            {
                                id: 116,
                                label: "Young Platypus",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 29,
                                thumbnailFileId: 138,
                                createdAt: "2020-02-04T23:36:08.000Z",
                                updatedAt: "2020-02-20T22:48:23.000Z",
                                thumbnailFile: {
                                    id: 138,
                                    size: 38528,
                                    path: require("../images/Files/platypus_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"ff327beb12adcc71bb78c44b8d18fed9"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:48:22.000Z",
                                    updatedAt: "2020-02-20T22:48:22.000Z",
                                },
                            },
                        ],
                    },
                    {
                        id: 30,
                        label: "…",
                        copyId: null,
                        thumbnailFileId: null,
                        createdAt: "2020-02-04T23:27:03.000Z",
                        updatedAt: "2020-02-04T23:27:03.000Z",
                        quizId: 7,
                        thumbnailFile: null,
                        quizAnswers: [
                            {
                                id: 117,
                                label: "Kerry",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 30,
                                thumbnailFileId: 133,
                                createdAt: "2020-02-04T23:36:19.000Z",
                                updatedAt: "2020-02-20T22:48:37.000Z",
                                thumbnailFile: {
                                    id: 133,
                                    size: 45971,
                                    path: require("../images/Files/kerry_1_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"1a62864604d3a5cd57d043bf74ce0298"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:45:17.000Z",
                                    updatedAt: "2020-02-20T22:45:17.000Z",
                                },
                            },
                            {
                                id: 118,
                                label: "Stick",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 30,
                                thumbnailFileId: 132,
                                createdAt: "2020-02-04T23:36:22.000Z",
                                updatedAt: "2020-02-20T22:48:44.000Z",
                                thumbnailFile: {
                                    id: 132,
                                    size: 46761,
                                    path: require("../images/Files/yarra_2_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"a202db671ebf39febe967840a9c53ebd"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:45:05.000Z",
                                    updatedAt: "2020-02-20T22:45:05.000Z",
                                },
                            },
                            {
                                id: 119,
                                label: "Augustus",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 30,
                                thumbnailFileId: 135,
                                createdAt: "2020-02-04T23:36:38.000Z",
                                updatedAt: "2020-02-20T22:48:50.000Z",
                                thumbnailFile: {
                                    id: 135,
                                    size: 37715,
                                    path: require("../images/Files/augustus_3_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"6b4ff0bc1132c9fdcf398ad3de716167"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:46:07.000Z",
                                    updatedAt: "2020-02-20T22:46:07.000Z",
                                },
                            },
                            {
                                id: 120,
                                label: "Daddy Possum",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 30,
                                thumbnailFileId: 134,
                                createdAt: "2020-02-04T23:36:43.000Z",
                                updatedAt: "2020-02-20T22:48:56.000Z",
                                thumbnailFile: {
                                    id: 134,
                                    size: 40329,
                                    path: require("../images/Files/daddypossum_1_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"52bbf41ce5dcf6399007ba685a24d6bd"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:45:35.000Z",
                                    updatedAt: "2020-02-20T22:45:35.000Z",
                                },
                            },
                        ],
                    },
                    {
                        id: 31,
                        label: "That was awesome!",
                        copyId: null,
                        thumbnailFileId: null,
                        createdAt: "2020-02-04T23:27:03.000Z",
                        updatedAt: "2020-02-04T23:27:03.000Z",
                        quizId: 7,
                        thumbnailFile: null,
                        quizAnswers: [
                            {
                                id: 121,
                                label: "Sweetie",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 31,
                                thumbnailFileId: 137,
                                createdAt: "2020-02-04T23:36:55.000Z",
                                updatedAt: "2020-02-20T22:49:09.000Z",
                                thumbnailFile: {
                                    id: 137,
                                    size: 48198,
                                    path: require("../images/Files/sweetie_1_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"a8d07fa3a6a9896d0941bf2ebe4e46a9"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:48:03.000Z",
                                    updatedAt: "2020-02-20T22:48:03.000Z",
                                },
                            },
                            {
                                id: 122,
                                label: "Kerry",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 31,
                                thumbnailFileId: 133,
                                createdAt: "2020-02-04T23:36:59.000Z",
                                updatedAt: "2020-02-20T22:49:16.000Z",
                                thumbnailFile: {
                                    id: 133,
                                    size: 45971,
                                    path: require("../images/Files/kerry_1_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"1a62864604d3a5cd57d043bf74ce0298"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:45:17.000Z",
                                    updatedAt: "2020-02-20T22:45:17.000Z",
                                },
                            },
                            {
                                id: 123,
                                label: "Bernard",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 31,
                                thumbnailFileId: 139,
                                createdAt: "2020-02-04T23:37:03.000Z",
                                updatedAt: "2020-02-20T22:49:29.000Z",
                                thumbnailFile: {
                                    id: 139,
                                    size: 41269,
                                    path: require("../images/Files/bernard_1_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"2ebf509c5b5667d967e38c8c45d5af18"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:49:28.000Z",
                                    updatedAt: "2020-02-20T22:49:28.000Z",
                                },
                            },
                            {
                                id: 124,
                                label: "Augustus",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 31,
                                thumbnailFileId: 135,
                                createdAt: "2020-02-04T23:37:23.000Z",
                                updatedAt: "2020-02-20T22:49:37.000Z",
                                thumbnailFile: {
                                    id: 135,
                                    size: 37715,
                                    path: require("../images/Files/augustus_3_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"6b4ff0bc1132c9fdcf398ad3de716167"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:46:07.000Z",
                                    updatedAt: "2020-02-20T22:46:07.000Z",
                                },
                            },
                        ],
                    },
                    {
                        id: 32,
                        label: "If we get eaten by Drop Bears, I'm going to kill you.",
                        copyId: null,
                        thumbnailFileId: null,
                        createdAt: "2020-02-04T23:27:03.000Z",
                        updatedAt: "2020-02-04T23:27:03.000Z",
                        quizId: 7,
                        thumbnailFile: null,
                        quizAnswers: [
                            {
                                id: 125,
                                label: "Yarra",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 32,
                                thumbnailFileId: 131,
                                createdAt: "2020-02-04T23:37:33.000Z",
                                updatedAt: "2020-02-20T22:49:51.000Z",
                                thumbnailFile: {
                                    id: 131,
                                    size: 44189,
                                    path: require("../images/Files/yarra_1_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"397695bf696d5d292d0805f36fc8578f"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:44:36.000Z",
                                    updatedAt: "2020-02-20T22:44:36.000Z",
                                },
                            },
                            {
                                id: 126,
                                label: "Founding child Kookaburra",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 32,
                                thumbnailFileId: 136,
                                createdAt: "2020-02-04T23:37:44.000Z",
                                updatedAt: "2020-02-20T22:50:38.000Z",
                                thumbnailFile: {
                                    id: 136,
                                    size: 35281,
                                    path: require("../images/Files/foundingchild_kookaburra_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"d3e627ae6e931c907fe53a3d711878fe"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:47:30.000Z",
                                    updatedAt: "2020-02-20T22:47:30.000Z",
                                },
                            },
                            {
                                id: 127,
                                label: "Petra",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 32,
                                thumbnailFileId: 130,
                                createdAt: "2020-02-04T23:37:50.000Z",
                                updatedAt: "2020-02-20T22:50:45.000Z",
                                thumbnailFile: {
                                    id: 130,
                                    size: 45712,
                                    path: require("../images/Files/petra_1_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"d9cddfc113c3034b332431754701e870"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:44:26.000Z",
                                    updatedAt: "2020-02-20T22:44:26.000Z",
                                },
                            },
                            {
                                id: 128,
                                label: "Sweetie",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 32,
                                thumbnailFileId: 137,
                                createdAt: "2020-02-04T23:38:00.000Z",
                                updatedAt: "2020-02-20T22:50:52.000Z",
                                thumbnailFile: {
                                    id: 137,
                                    size: 48198,
                                    path: require("../images/Files/sweetie_1_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"a8d07fa3a6a9896d0941bf2ebe4e46a9"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:48:03.000Z",
                                    updatedAt: "2020-02-20T22:48:03.000Z",
                                },
                            },
                        ],
                    },
                    {
                        id: 33,
                        label: "We wished for safety for all animals.",
                        copyId: null,
                        thumbnailFileId: null,
                        createdAt: "2020-02-04T23:27:03.000Z",
                        updatedAt: "2020-02-04T23:27:03.000Z",
                        quizId: 7,
                        thumbnailFile: null,
                        quizAnswers: [
                            {
                                id: 129,
                                label: "Daddy Possum",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 33,
                                thumbnailFileId: 134,
                                createdAt: "2020-02-04T23:38:14.000Z",
                                updatedAt: "2020-02-20T22:52:41.000Z",
                                thumbnailFile: {
                                    id: 134,
                                    size: 40329,
                                    path: require("../images/Files/daddypossum_1_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"52bbf41ce5dcf6399007ba685a24d6bd"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:45:35.000Z",
                                    updatedAt: "2020-02-20T22:45:35.000Z",
                                },
                            },
                            {
                                id: 130,
                                label: "Founding child Kookaburra",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 33,
                                thumbnailFileId: 136,
                                createdAt: "2020-02-04T23:38:23.000Z",
                                updatedAt: "2020-02-20T22:52:50.000Z",
                                thumbnailFile: {
                                    id: 136,
                                    size: 35281,
                                    path: require("../images/Files/foundingchild_kookaburra_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"d3e627ae6e931c907fe53a3d711878fe"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:47:30.000Z",
                                    updatedAt: "2020-02-20T22:47:30.000Z",
                                },
                            },
                            {
                                id: 131,
                                label: "Founding child Possum",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 33,
                                thumbnailFileId: 140,
                                createdAt: "2020-02-04T23:38:34.000Z",
                                updatedAt: "2020-02-20T22:53:16.000Z",
                                thumbnailFile: {
                                    id: 140,
                                    size: 34006,
                                    path: require("../images/Files/foundingchild_possum_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"2ddae9e9ecd7be74883fdec9ebe2f56c"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:53:15.000Z",
                                    updatedAt: "2020-02-20T22:53:15.000Z",
                                },
                            },
                            {
                                id: 132,
                                label: "Yarra",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 33,
                                thumbnailFileId: 131,
                                createdAt: "2020-02-04T23:38:41.000Z",
                                updatedAt: "2020-02-20T22:53:30.000Z",
                                thumbnailFile: {
                                    id: 131,
                                    size: 44189,
                                    path: require("../images/Files/yarra_1_square.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"397695bf696d5d292d0805f36fc8578f"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-20T22:44:36.000Z",
                                    updatedAt: "2020-02-20T22:44:36.000Z",
                                },
                            },
                        ],
                    },
                ],
                quizResults: [
                    {
                        id: 17,
                        label: "1/9",
                        detail: "Next time you watch the film, maybe try to pay attention?",
                        copyId: null,
                        scoreThreshold: 1,
                        thumbnailFileId: 127,
                        createdAt: "2020-02-04T23:27:03.000Z",
                        updatedAt: "2020-02-20T22:42:44.000Z",
                        quizId: 7,
                        thumbnailFile: {
                            id: 127,
                            size: 79643,
                            path: require("../images/Files/quiz_bad.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"c870f41e6e06f20569b60061e9cceb54"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T22:40:20.000Z",
                            updatedAt: "2020-02-20T22:40:20.000Z",
                        },
                    },
                    {
                        id: 18,
                        label: "3/9",
                        detail: "Maybe you’re not great with characters… or lines…",
                        copyId: null,
                        scoreThreshold: 3,
                        thumbnailFileId: 127,
                        createdAt: "2020-02-04T23:27:03.000Z",
                        updatedAt: "2020-02-20T22:43:06.000Z",
                        quizId: 7,
                        thumbnailFile: {
                            id: 127,
                            size: 79643,
                            path: require("../images/Files/quiz_bad.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"c870f41e6e06f20569b60061e9cceb54"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T22:40:20.000Z",
                            updatedAt: "2020-02-20T22:40:20.000Z",
                        },
                    },
                    {
                        id: 19,
                        label: "2/9",
                        detail: "Was it luck or just a case of bad memory? ",
                        copyId: null,
                        scoreThreshold: 2,
                        thumbnailFileId: 127,
                        createdAt: "2020-02-04T23:27:03.000Z",
                        updatedAt: "2020-02-20T22:42:55.000Z",
                        quizId: 7,
                        thumbnailFile: {
                            id: 127,
                            size: 79643,
                            path: require("../images/Files/quiz_bad.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"c870f41e6e06f20569b60061e9cceb54"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T22:40:20.000Z",
                            updatedAt: "2020-02-20T22:40:20.000Z",
                        },
                    },
                    {
                        id: 20,
                        label: "5/9",
                        detail: "Need to get your head in the game… ",
                        copyId: null,
                        scoreThreshold: 5,
                        thumbnailFileId: 128,
                        createdAt: "2020-02-04T23:27:03.000Z",
                        updatedAt: "2020-02-20T22:43:25.000Z",
                        quizId: 7,
                        thumbnailFile: {
                            id: 128,
                            size: 75506,
                            path: require("../images/Files/quiz_mid.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"5191dd5f21bc4df89e99437422876bf4"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T22:40:41.000Z",
                            updatedAt: "2020-02-20T22:40:41.000Z",
                        },
                    },
                    {
                        id: 21,
                        label: "4/9",
                        detail: "Don’t worry there, you can always watch it again!",
                        copyId: null,
                        scoreThreshold: 4,
                        thumbnailFileId: 128,
                        createdAt: "2020-02-04T23:27:03.000Z",
                        updatedAt: "2020-02-20T22:43:15.000Z",
                        quizId: 7,
                        thumbnailFile: {
                            id: 128,
                            size: 75506,
                            path: require("../images/Files/quiz_mid.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"5191dd5f21bc4df89e99437422876bf4"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T22:40:41.000Z",
                            updatedAt: "2020-02-20T22:40:41.000Z",
                        },
                    },
                    {
                        id: 22,
                        label: "6/9",
                        detail: "Not bad, but not great. At least you knew the obvious ones!",
                        copyId: null,
                        scoreThreshold: 6,
                        thumbnailFileId: 128,
                        createdAt: "2020-02-04T23:27:03.000Z",
                        updatedAt: "2020-02-20T22:43:34.000Z",
                        quizId: 7,
                        thumbnailFile: {
                            id: 128,
                            size: 75506,
                            path: require("../images/Files/quiz_mid.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"5191dd5f21bc4df89e99437422876bf4"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T22:40:41.000Z",
                            updatedAt: "2020-02-20T22:40:41.000Z",
                        },
                    },
                    {
                        id: 23,
                        label: "7/9",
                        detail: "Better luck next time! ",
                        copyId: null,
                        scoreThreshold: 7,
                        thumbnailFileId: 129,
                        createdAt: "2020-02-04T23:27:03.000Z",
                        updatedAt: "2020-02-20T22:43:41.000Z",
                        quizId: 7,
                        thumbnailFile: {
                            id: 129,
                            size: 77801,
                            path: require("../images/Files/quiz_good.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"ae0f9e4a0d548ba4353bcb514632166f"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T22:40:59.000Z",
                            updatedAt: "2020-02-20T22:40:59.000Z",
                        },
                    },
                    {
                        id: 24,
                        label: "8/9",
                        detail: "Sooo close! You can still hold your head high and call yourself a fan!",
                        copyId: null,
                        scoreThreshold: 8,
                        thumbnailFileId: 129,
                        createdAt: "2020-02-04T23:27:03.000Z",
                        updatedAt: "2020-02-20T22:43:51.000Z",
                        quizId: 7,
                        thumbnailFile: {
                            id: 129,
                            size: 77801,
                            path: require("../images/Files/quiz_good.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"ae0f9e4a0d548ba4353bcb514632166f"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T22:40:59.000Z",
                            updatedAt: "2020-02-20T22:40:59.000Z",
                        },
                    },
                    {
                        id: 25,
                        label: "9/9",
                        detail: "GOAT! Nothing gets past you! Wishmas Tree genius!",
                        copyId: null,
                        scoreThreshold: 9,
                        thumbnailFileId: 129,
                        createdAt: "2020-02-04T23:27:03.000Z",
                        updatedAt: "2020-02-20T22:43:58.000Z",
                        quizId: 7,
                        thumbnailFile: {
                            id: 129,
                            size: 77801,
                            path: require("../images/Files/quiz_good.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"ae0f9e4a0d548ba4353bcb514632166f"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T22:40:59.000Z",
                            updatedAt: "2020-02-20T22:40:59.000Z",
                        },
                    },
                    {
                        id: 44,
                        label: "0/9",
                        detail: "So you haven’t seen the film huh?",
                        copyId: null,
                        scoreThreshold: 0,
                        thumbnailFileId: 127,
                        createdAt: "2020-02-20T05:39:41.000Z",
                        updatedAt: "2020-02-20T22:42:33.000Z",
                        quizId: 7,
                        thumbnailFile: {
                            id: 127,
                            size: 79643,
                            path: require("../images/Files/quiz_bad.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"c870f41e6e06f20569b60061e9cceb54"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T22:40:20.000Z",
                            updatedAt: "2020-02-20T22:40:20.000Z",
                        },
                    },
                ],
                thumbnailFile: {
                    id: 278,
                    size: 38550,
                    path: require("../images/Files/quiz_thumbnail_7.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"eace94c22933c4eadda3d49b19a90093"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-26T06:39:02.000Z",
                    updatedAt: "2020-02-26T06:39:02.000Z",
                },
            },
        },
        {
            id: 17,
            type: "quiz",
            content_id: 8,
            label: "Would you survive?",
            title_copy_id: 17,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-04T00:28:51.000Z",
            forceLarge: false,
            createdAt: "2020-02-05T00:28:51.000Z",
            updatedAt: "2020-02-25T23:49:25.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 35,
                    contentId: 17,
                    tagId: 14,
                    createdAt: "2020-02-05T22:51:28.000Z",
                    updatedAt: "2020-02-05T22:51:28.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 36,
                    contentId: 17,
                    tagId: 20,
                    createdAt: "2020-02-05T22:52:13.000Z",
                    updatedAt: "2020-02-05T22:52:13.000Z",
                    tag: {
                        id: 20,
                        tag_id: "dropbears",
                        label: "Dropbears",
                        createdAt: "2020-02-05T22:51:57.000Z",
                        updatedAt: "2020-02-05T22:51:57.000Z",
                    },
                },
                {
                    id: 37,
                    contentId: 17,
                    tagId: 19,
                    createdAt: "2020-02-05T22:52:16.000Z",
                    updatedAt: "2020-02-05T22:52:16.000Z",
                    tag: {
                        id: 19,
                        tag_id: "thewild",
                        label: "The Wild",
                        createdAt: "2020-02-05T22:51:47.000Z",
                        updatedAt: "2020-02-05T22:51:47.000Z",
                    },
                },
                {
                    id: 44,
                    contentId: 17,
                    tagId: 13,
                    createdAt: "2020-02-05T22:53:21.000Z",
                    updatedAt: "2020-02-05T22:53:21.000Z",
                    tag: {
                        id: 13,
                        tag_id: "quiz",
                        label: "Quiz",
                        createdAt: "2020-02-04T01:25:21.000Z",
                        updatedAt: "2020-02-04T01:25:21.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 8,
                label: "How long would you survive?",
                copyId: null,
                thumbnailFileId: 277,
                quizType: 3,
                createdAt: "2020-02-05T00:16:36.000Z",
                updatedAt: "2020-02-26T06:38:47.000Z",
                copy: null,
                quizQuestions: [
                    {
                        id: 34,
                        label: "What is the first thing you would do in the wild?",
                        copyId: null,
                        thumbnailFileId: 146,
                        createdAt: "2020-02-05T00:16:36.000Z",
                        updatedAt: "2020-02-20T23:07:25.000Z",
                        quizId: 8,
                        thumbnailFile: {
                            id: 146,
                            size: 52761,
                            path: require("../images/Files/quiz4_question1.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"7352e85528b07fc840009c9eb7d11507"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T23:07:24.000Z",
                            updatedAt: "2020-02-20T23:07:24.000Z",
                        },
                        quizAnswers: [
                            {
                                id: 133,
                                label: "Find food and shelter",
                                copyId: null,
                                answerOrder: null,
                                score: "2",
                                quizQuestionId: 34,
                                thumbnailFileId: null,
                                createdAt: "2020-02-05T00:23:57.000Z",
                                updatedAt: "2020-02-05T00:23:57.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 134,
                                label: "I don't know",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 34,
                                thumbnailFileId: null,
                                createdAt: "2020-02-05T00:24:04.000Z",
                                updatedAt: "2020-02-05T00:24:04.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 135,
                                label: "Scream and panic",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 34,
                                thumbnailFileId: null,
                                createdAt: "2020-02-05T00:24:11.000Z",
                                updatedAt: "2020-02-05T00:24:11.000Z",
                                thumbnailFile: null,
                            },
                        ],
                    },
                    {
                        id: 35,
                        label: "When you go travelling do you use maps or trust your gut?",
                        copyId: null,
                        thumbnailFileId: 147,
                        createdAt: "2020-02-05T00:16:36.000Z",
                        updatedAt: "2020-02-20T23:08:22.000Z",
                        quizId: 8,
                        thumbnailFile: {
                            id: 147,
                            size: 58320,
                            path: require("../images/Files/quiz4_question2.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"b69f52a52b8fca2a3e43a8698f51a305"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T23:07:52.000Z",
                            updatedAt: "2020-02-20T23:07:52.000Z",
                        },
                        quizAnswers: [
                            {
                                id: 136,
                                label: "Maps, always!",
                                copyId: null,
                                answerOrder: null,
                                score: "2",
                                quizQuestionId: 35,
                                thumbnailFileId: null,
                                createdAt: "2020-02-05T00:24:35.000Z",
                                updatedAt: "2020-02-05T00:24:35.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 137,
                                label: "A bit of both",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 35,
                                thumbnailFileId: null,
                                createdAt: "2020-02-05T00:24:42.000Z",
                                updatedAt: "2020-02-05T00:24:42.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 138,
                                label: "I trust my instincts to lead the way",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 35,
                                thumbnailFileId: null,
                                createdAt: "2020-02-05T00:24:50.000Z",
                                updatedAt: "2020-02-05T00:24:50.000Z",
                                thumbnailFile: null,
                            },
                        ],
                    },
                    {
                        id: 36,
                        label: "Are you adventurous or a home body?",
                        copyId: null,
                        thumbnailFileId: 148,
                        createdAt: "2020-02-05T00:16:36.000Z",
                        updatedAt: "2020-02-20T23:08:10.000Z",
                        quizId: 8,
                        thumbnailFile: {
                            id: 148,
                            size: 73209,
                            path: require("../images/Files/quiz4_question3.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"dfc8e039ae3536036813f02298e1aace"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T23:08:09.000Z",
                            updatedAt: "2020-02-20T23:08:09.000Z",
                        },
                        quizAnswers: [
                            {
                                id: 139,
                                label: "Adventure is my middle name",
                                copyId: null,
                                answerOrder: null,
                                score: "2",
                                quizQuestionId: 36,
                                thumbnailFileId: null,
                                createdAt: "2020-02-05T00:25:04.000Z",
                                updatedAt: "2020-02-05T00:25:04.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 140,
                                label: "I dabble",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 36,
                                thumbnailFileId: null,
                                createdAt: "2020-02-05T00:25:09.000Z",
                                updatedAt: "2020-02-05T00:25:09.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 141,
                                label: "I hate the outdoors",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 36,
                                thumbnailFileId: null,
                                createdAt: "2020-02-05T00:25:15.000Z",
                                updatedAt: "2020-02-05T00:25:15.000Z",
                                thumbnailFile: null,
                            },
                        ],
                    },
                    {
                        id: 37,
                        label: "You find yourself surrounded by Dropbears! What do you do?",
                        copyId: null,
                        thumbnailFileId: 149,
                        createdAt: "2020-02-05T00:16:36.000Z",
                        updatedAt: "2020-02-20T23:08:44.000Z",
                        quizId: 8,
                        thumbnailFile: {
                            id: 149,
                            size: 47915,
                            path: require("../images/Files/quiz4_question4.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"cba37fd723a8638ec132ecd4b7110d0b"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T23:08:43.000Z",
                            updatedAt: "2020-02-20T23:08:43.000Z",
                        },
                        quizAnswers: [
                            {
                                id: 142,
                                label: "Fight!",
                                copyId: null,
                                answerOrder: null,
                                score: "2",
                                quizQuestionId: 37,
                                thumbnailFileId: null,
                                createdAt: "2020-02-05T00:25:24.000Z",
                                updatedAt: "2020-02-05T00:25:24.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 143,
                                label: "Hide!",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 37,
                                thumbnailFileId: null,
                                createdAt: "2020-02-05T00:25:29.000Z",
                                updatedAt: "2020-02-05T00:25:29.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 144,
                                label: "Run away!",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 37,
                                thumbnailFileId: null,
                                createdAt: "2020-02-05T00:25:34.000Z",
                                updatedAt: "2020-02-05T00:25:34.000Z",
                                thumbnailFile: null,
                            },
                        ],
                    },
                    {
                        id: 38,
                        label: "Would you rather go camping or stay in a hotel?",
                        copyId: null,
                        thumbnailFileId: 150,
                        createdAt: "2020-02-05T00:16:36.000Z",
                        updatedAt: "2020-02-20T23:12:24.000Z",
                        quizId: 8,
                        thumbnailFile: {
                            id: 150,
                            size: 60210,
                            path: require("../images/Files/quiz4_question5.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"367e00d7423dfaca98d024719b25af37"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T23:09:23.000Z",
                            updatedAt: "2020-02-20T23:09:23.000Z",
                        },
                        quizAnswers: [
                            {
                                id: 145,
                                label: "Camping",
                                copyId: null,
                                answerOrder: null,
                                score: "2",
                                quizQuestionId: 38,
                                thumbnailFileId: null,
                                createdAt: "2020-02-05T00:25:48.000Z",
                                updatedAt: "2020-02-05T00:25:48.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 146,
                                label: "I would stay home",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 38,
                                thumbnailFileId: null,
                                createdAt: "2020-02-05T00:25:53.000Z",
                                updatedAt: "2020-02-05T00:25:53.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 147,
                                label: "Hotel",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 38,
                                thumbnailFileId: null,
                                createdAt: "2020-02-05T00:25:59.000Z",
                                updatedAt: "2020-02-05T00:25:59.000Z",
                                thumbnailFile: null,
                            },
                        ],
                    },
                    {
                        id: 39,
                        label: "You get stuck in the forbidden swamp! What’s next?",
                        copyId: null,
                        thumbnailFileId: 151,
                        createdAt: "2020-02-05T00:16:36.000Z",
                        updatedAt: "2020-02-20T23:09:44.000Z",
                        quizId: 8,
                        thumbnailFile: {
                            id: 151,
                            size: 63093,
                            path: require("../images/Files/quiz4_question6.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"c53c0893beaca314d436a7ff50ca633e"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T23:09:42.000Z",
                            updatedAt: "2020-02-20T23:09:42.000Z",
                        },
                        quizAnswers: [
                            {
                                id: 148,
                                label: "Don't panic and look for a way out",
                                copyId: null,
                                answerOrder: null,
                                score: "2",
                                quizQuestionId: 39,
                                thumbnailFileId: null,
                                createdAt: "2020-02-05T00:26:15.000Z",
                                updatedAt: "2020-02-05T00:26:15.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 149,
                                label: "Nothing, I would sink",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 39,
                                thumbnailFileId: null,
                                createdAt: "2020-02-05T00:26:25.000Z",
                                updatedAt: "2020-02-05T00:26:25.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 150,
                                label: "Scream for help",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 39,
                                thumbnailFileId: null,
                                createdAt: "2020-02-05T00:26:34.000Z",
                                updatedAt: "2020-02-05T00:26:34.000Z",
                                thumbnailFile: null,
                            },
                        ],
                    },
                    {
                        id: 40,
                        label: "Have you ever been camping?",
                        copyId: null,
                        thumbnailFileId: 152,
                        createdAt: "2020-02-05T00:16:36.000Z",
                        updatedAt: "2020-02-20T23:10:17.000Z",
                        quizId: 8,
                        thumbnailFile: {
                            id: 152,
                            size: 52181,
                            path: require("../images/Files/quiz4_question7.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"b416a43a6fbbe7eed127ea0439847388"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T23:10:16.000Z",
                            updatedAt: "2020-02-20T23:10:16.000Z",
                        },
                        quizAnswers: [
                            {
                                id: 151,
                                label: "All the time",
                                copyId: null,
                                answerOrder: null,
                                score: "2",
                                quizQuestionId: 40,
                                thumbnailFileId: null,
                                createdAt: "2020-02-05T00:26:46.000Z",
                                updatedAt: "2020-02-05T00:26:46.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 152,
                                label: "Occasionally",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 40,
                                thumbnailFileId: null,
                                createdAt: "2020-02-05T00:26:51.000Z",
                                updatedAt: "2020-02-05T00:26:51.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 153,
                                label: "Never",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 40,
                                thumbnailFileId: null,
                                createdAt: "2020-02-05T00:26:56.000Z",
                                updatedAt: "2020-02-05T00:26:56.000Z",
                                thumbnailFile: null,
                            },
                        ],
                    },
                    {
                        id: 41,
                        label: "You find some berries - what do you do?",
                        copyId: null,
                        thumbnailFileId: 153,
                        createdAt: "2020-02-05T00:16:36.000Z",
                        updatedAt: "2020-02-20T23:10:33.000Z",
                        quizId: 8,
                        thumbnailFile: {
                            id: 153,
                            size: 66077,
                            path: require("../images/Files/quiz4_question8.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"f9ed4c1671924f13c5f69671f2cc05ad"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T23:10:32.000Z",
                            updatedAt: "2020-02-20T23:10:32.000Z",
                        },
                        quizAnswers: [
                            {
                                id: 157,
                                label: "Depends on the berry",
                                copyId: null,
                                answerOrder: null,
                                score: "2",
                                quizQuestionId: 41,
                                thumbnailFileId: null,
                                createdAt: "2020-02-05T00:27:46.000Z",
                                updatedAt: "2020-02-05T00:27:46.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 158,
                                label: "Leave them alone",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 41,
                                thumbnailFileId: null,
                                createdAt: "2020-02-05T00:27:51.000Z",
                                updatedAt: "2020-02-05T00:27:51.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 159,
                                label: "Yummm!",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 41,
                                thumbnailFileId: null,
                                createdAt: "2020-02-05T00:27:56.000Z",
                                updatedAt: "2020-02-05T00:27:56.000Z",
                                thumbnailFile: null,
                            },
                        ],
                    },
                    {
                        id: 42,
                        label: "Why are you in the wild?",
                        copyId: null,
                        thumbnailFileId: 154,
                        createdAt: "2020-02-05T00:16:36.000Z",
                        updatedAt: "2020-02-20T23:11:02.000Z",
                        quizId: 8,
                        thumbnailFile: {
                            id: 154,
                            size: 59459,
                            path: require("../images/Files/quiz4_question9.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"595d0724b5d37ed0381f2175fb2bacfe"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T23:11:01.000Z",
                            updatedAt: "2020-02-20T23:11:01.000Z",
                        },
                        quizAnswers: [
                            {
                                id: 154,
                                label: "I'm an adventurer!",
                                copyId: null,
                                answerOrder: null,
                                score: "2",
                                quizQuestionId: 42,
                                thumbnailFileId: null,
                                createdAt: "2020-02-05T00:27:17.000Z",
                                updatedAt: "2020-02-05T00:27:17.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 155,
                                label: "I don't know!",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 42,
                                thumbnailFileId: null,
                                createdAt: "2020-02-05T00:27:22.000Z",
                                updatedAt: "2020-02-05T00:27:22.000Z",
                                thumbnailFile: null,
                            },
                            {
                                id: 156,
                                label: "Some horrible mistake!",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 42,
                                thumbnailFileId: null,
                                createdAt: "2020-02-05T00:27:29.000Z",
                                updatedAt: "2020-02-05T00:27:29.000Z",
                                thumbnailFile: null,
                            },
                        ],
                    },
                ],
                quizResults: [
                    {
                        id: 26,
                        label: "Weeks",
                        detail: "Impressive. Not many can withstand the terror of the wild but you showed skill and survival instincts. However the smallest misstep, perhaps in the Misty Muds would land you in hot water and you would not last more than a few weeks.",
                        copyId: null,
                        scoreThreshold: 12,
                        thumbnailFileId: 144,
                        createdAt: "2020-02-05T00:16:37.000Z",
                        updatedAt: "2020-02-20T23:04:30.000Z",
                        quizId: 8,
                        thumbnailFile: {
                            id: 144,
                            size: 59537,
                            path: require("../images/Files/survival_weeks.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"c8d5fcfa79ef8329b74aa9f5aac09aed"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T23:04:29.000Z",
                            updatedAt: "2020-02-20T23:04:29.000Z",
                        },
                    },
                    {
                        id: 27,
                        label: "Hours",
                        detail: "It is not looking good. You made it to the wild which is something… but you quickly fall prey to the terrors of the wild and some manner of beast got you. A wolf, mud spirits or even a giant eagle surely took you out!",
                        copyId: null,
                        scoreThreshold: 4,
                        thumbnailFileId: 142,
                        createdAt: "2020-02-05T00:16:37.000Z",
                        updatedAt: "2020-02-20T23:02:49.000Z",
                        quizId: 8,
                        thumbnailFile: {
                            id: 142,
                            size: 58320,
                            path: require("../images/Files/survival_hours.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"b69f52a52b8fca2a3e43a8698f51a305"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T23:02:48.000Z",
                            updatedAt: "2020-02-20T23:02:48.000Z",
                        },
                    },
                    {
                        id: 28,
                        label: "Days",
                        detail: "You did okay! The Wild is a dangerous place but you are determined and made it further than most. Not only are there predators in the wild but every landscape is treacherous. You probably get lost in the Forbidden Swamp after a few days.",
                        copyId: null,
                        scoreThreshold: 8,
                        thumbnailFileId: 143,
                        createdAt: "2020-02-05T00:16:37.000Z",
                        updatedAt: "2020-02-20T23:03:21.000Z",
                        quizId: 8,
                        thumbnailFile: {
                            id: 143,
                            size: 52181,
                            path: require("../images/Files/survival_days.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"b416a43a6fbbe7eed127ea0439847388"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T23:03:20.000Z",
                            updatedAt: "2020-02-20T23:03:20.000Z",
                        },
                    },
                    {
                        id: 29,
                        label: "Minutes",
                        detail: "Absolutely not! You are a goner! The wild is a horrifying and difficult place to survive and you… wouldn’t. Adventure isn’t your calling and you seem to not be in any hurry to go into the wild, so fingers crossed you stay clear. At least the beasts can use your bones as toothpicks if you do end up there!",
                        copyId: null,
                        scoreThreshold: 0,
                        thumbnailFileId: 141,
                        createdAt: "2020-02-05T00:16:37.000Z",
                        updatedAt: "2020-02-20T23:02:08.000Z",
                        quizId: 8,
                        thumbnailFile: {
                            id: 141,
                            size: 61903,
                            path: require("../images/Files/survival_minutes.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"619f98eaf21f8a140e0d4c2be6c8f7ae"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T23:02:07.000Z",
                            updatedAt: "2020-02-20T23:02:07.000Z",
                        },
                    },
                    {
                        id: 30,
                        label: "Years",
                        detail: "WOW you really are tough and know how to take care of yourself. You were born in the wild to stay in the wild. No city could ever hold you! Dropbears and spirits are your friends and you are as resilient as they get!",
                        copyId: null,
                        scoreThreshold: 16,
                        thumbnailFileId: 145,
                        createdAt: "2020-02-05T00:16:37.000Z",
                        updatedAt: "2020-02-20T23:04:48.000Z",
                        quizId: 8,
                        thumbnailFile: {
                            id: 145,
                            size: 61487,
                            path: require("../images/Files/survival_years.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"c07904c17a349259b4fb5cd0d3c5dce5"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T23:04:47.000Z",
                            updatedAt: "2020-02-20T23:04:47.000Z",
                        },
                    },
                ],
                thumbnailFile: {
                    id: 277,
                    size: 39502,
                    path: require("../images/Files/quiz_thumbnail_8.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"01ef1b3e0bfeaaaba3415cc7decca099"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-26T06:38:46.000Z",
                    updatedAt: "2020-02-26T06:38:46.000Z",
                },
            },
        },
        {
            id: 19,
            type: "quiz",
            content_id: 10,
            label: "Where would you live?",
            title_copy_id: 19,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-04T03:46:56.000Z",
            forceLarge: false,
            createdAt: "2020-02-05T03:46:56.000Z",
            updatedAt: "2020-02-25T23:46:11.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 45,
                    contentId: 19,
                    tagId: 14,
                    createdAt: "2020-02-05T22:53:34.000Z",
                    updatedAt: "2020-02-05T22:53:34.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 46,
                    contentId: 19,
                    tagId: 19,
                    createdAt: "2020-02-05T22:53:37.000Z",
                    updatedAt: "2020-02-05T22:53:37.000Z",
                    tag: {
                        id: 19,
                        tag_id: "thewild",
                        label: "The Wild",
                        createdAt: "2020-02-05T22:51:47.000Z",
                        updatedAt: "2020-02-05T22:51:47.000Z",
                    },
                },
                {
                    id: 47,
                    contentId: 19,
                    tagId: 13,
                    createdAt: "2020-02-05T22:53:41.000Z",
                    updatedAt: "2020-02-05T22:53:41.000Z",
                    tag: {
                        id: 13,
                        tag_id: "quiz",
                        label: "Quiz",
                        createdAt: "2020-02-04T01:25:21.000Z",
                        updatedAt: "2020-02-04T01:25:21.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 10,
                label: "Where would you live?",
                copyId: null,
                thumbnailFileId: 275,
                quizType: 0,
                createdAt: "2020-02-05T03:38:32.000Z",
                updatedAt: "2020-02-26T06:38:17.000Z",
                copy: null,
                quizQuestions: [
                    {
                        id: 49,
                        label: "Pick a colour:",
                        copyId: null,
                        thumbnailFileId: 159,
                        createdAt: "2020-02-05T03:38:32.000Z",
                        updatedAt: "2020-02-21T00:03:20.000Z",
                        quizId: 10,
                        thumbnailFile: {
                            id: 159,
                            size: 19780,
                            path: require("../images/Files/Q1Answer1_Purple_wishmas.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"97d2f0c0a88afbbdd287f2de3ca7d7b9"',
                            region: "ap-southeast-2",
                            credit: "Photo by Luke Chesser on Unsplash",
                            createdAt: "2020-02-21T00:03:11.000Z",
                            updatedAt: "2020-02-21T00:03:17.000Z",
                        },
                        quizAnswers: [
                            {
                                id: 184,
                                label: "Purple",
                                copyId: null,
                                answerOrder: null,
                                score: '{"35":1}',
                                quizQuestionId: 49,
                                thumbnailFileId: 159,
                                createdAt: "2020-02-05T03:39:26.000Z",
                                updatedAt: "2020-02-21T00:13:33.000Z",
                                thumbnailFile: {
                                    id: 159,
                                    size: 19780,
                                    path: require("../images/Files/Q1Answer1_Purple_wishmas.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"97d2f0c0a88afbbdd287f2de3ca7d7b9"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Luke Chesser on Unsplash",
                                    createdAt: "2020-02-21T00:03:11.000Z",
                                    updatedAt: "2020-02-21T00:03:17.000Z",
                                },
                            },
                            {
                                id: 185,
                                label: "Orange",
                                copyId: null,
                                answerOrder: null,
                                score: '{"35":0,"37":1}',
                                quizQuestionId: 49,
                                thumbnailFileId: 160,
                                createdAt: "2020-02-05T03:39:31.000Z",
                                updatedAt: "2020-02-21T00:03:47.000Z",
                                thumbnailFile: {
                                    id: 160,
                                    size: 21868,
                                    path: require("../images/Files/Q1Answer2_Orange_LucasBenjamin.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"2bb01bc4653ee10e43051dcf2d1de74b"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Lucas Benjamin on Unsplash",
                                    createdAt: "2020-02-21T00:03:37.000Z",
                                    updatedAt: "2020-02-21T00:03:44.000Z",
                                },
                            },
                            {
                                id: 186,
                                label: "Green",
                                copyId: null,
                                answerOrder: null,
                                score: '{"35":0,"36":1,"37":0}',
                                quizQuestionId: 49,
                                thumbnailFileId: 161,
                                createdAt: "2020-02-05T03:39:40.000Z",
                                updatedAt: "2020-02-21T00:04:06.000Z",
                                thumbnailFile: {
                                    id: 161,
                                    size: 60634,
                                    path: require("../images/Files/Q1Answer3_Green_JasonDent.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"f3b6f114678e6ef874f358f989fb2959"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Jason Dent on Unsplash",
                                    createdAt: "2020-02-21T00:03:57.000Z",
                                    updatedAt: "2020-02-21T00:04:01.000Z",
                                },
                            },
                            {
                                id: 187,
                                label: "Brown",
                                copyId: null,
                                answerOrder: null,
                                score: '{"35":0,"36":0,"37":0,"38":1}',
                                quizQuestionId: 49,
                                thumbnailFileId: 162,
                                createdAt: "2020-02-05T03:39:47.000Z",
                                updatedAt: "2020-02-21T00:13:42.000Z",
                                thumbnailFile: {
                                    id: 162,
                                    size: 54547,
                                    path: require("../images/Files/Q1Answer4_Brown_wishmas.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"58e4b41015117eea96d291c82d27933d"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Sincerely Media on Unsplash",
                                    createdAt: "2020-02-21T00:04:18.000Z",
                                    updatedAt: "2020-02-21T00:04:28.000Z",
                                },
                            },
                        ],
                    },
                    {
                        id: 50,
                        label: "Pick a getaway:",
                        copyId: null,
                        thumbnailFileId: null,
                        createdAt: "2020-02-05T03:38:32.000Z",
                        updatedAt: "2020-02-05T03:38:32.000Z",
                        quizId: 10,
                        thumbnailFile: null,
                        quizAnswers: [
                            {
                                id: 188,
                                label: "The Maldives",
                                copyId: null,
                                answerOrder: null,
                                score: '{"37":1}',
                                quizQuestionId: 50,
                                thumbnailFileId: 163,
                                createdAt: "2020-02-05T03:40:17.000Z",
                                updatedAt: "2020-02-21T00:04:54.000Z",
                                thumbnailFile: {
                                    id: 163,
                                    size: 33881,
                                    path: require("../images/Files/Q2Answer1_Maldives_wishmas.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"24e5647d0268cfab13cfb0fd163507c9"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Ahmed Raavi on Unsplash",
                                    createdAt: "2020-02-21T00:04:46.000Z",
                                    updatedAt: "2020-02-21T00:04:51.000Z",
                                },
                            },
                            {
                                id: 189,
                                label: "Costa Rica",
                                copyId: null,
                                answerOrder: null,
                                score: '{"36":1,"37":0}',
                                quizQuestionId: 50,
                                thumbnailFileId: 164,
                                createdAt: "2020-02-05T03:40:28.000Z",
                                updatedAt: "2020-02-21T00:05:07.000Z",
                                thumbnailFile: {
                                    id: 164,
                                    size: 79822,
                                    path: require("../images/Files/Q2Answer2_Costarica_wishmas.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"8c3ab4329ac15ac94cff5c447c936be3"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Perry Grone on Unsplash",
                                    createdAt: "2020-02-21T00:05:02.000Z",
                                    updatedAt: "2020-02-21T00:05:05.000Z",
                                },
                            },
                            {
                                id: 190,
                                label: "Santorini",
                                copyId: null,
                                answerOrder: null,
                                score: '{"35":1,"36":0,"37":0}',
                                quizQuestionId: 50,
                                thumbnailFileId: 165,
                                createdAt: "2020-02-05T03:40:38.000Z",
                                updatedAt: "2020-02-21T00:05:23.000Z",
                                thumbnailFile: {
                                    id: 165,
                                    size: 68546,
                                    path: require("../images/Files/Q2Answer3_santorini_wishmas.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"41ae705c1c133177a05bac604cda20b3"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Heidi Kaden on Unsplash",
                                    createdAt: "2020-02-21T00:05:16.000Z",
                                    updatedAt: "2020-02-21T00:05:21.000Z",
                                },
                            },
                            {
                                id: 191,
                                label: "Sahara Desert",
                                copyId: null,
                                answerOrder: null,
                                score: '{"35":0,"36":0,"37":0,"38":1}',
                                quizQuestionId: 50,
                                thumbnailFileId: 166,
                                createdAt: "2020-02-05T03:40:47.000Z",
                                updatedAt: "2020-02-21T00:14:04.000Z",
                                thumbnailFile: {
                                    id: 166,
                                    size: 30260,
                                    path: require("../images/Files/Q2Answer4_saharadessert_wishmas.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"ccbda473680dfa0db87e742480fcca53"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Yeo Khee on Unsplash",
                                    createdAt: "2020-02-21T00:05:32.000Z",
                                    updatedAt: "2020-02-21T00:05:37.000Z",
                                },
                            },
                        ],
                    },
                    {
                        id: 51,
                        label: "Pick a house:",
                        copyId: null,
                        thumbnailFileId: null,
                        createdAt: "2020-02-05T03:38:32.000Z",
                        updatedAt: "2020-02-05T03:38:32.000Z",
                        quizId: 10,
                        thumbnailFile: null,
                        quizAnswers: [
                            {
                                id: 192,
                                label: "Secluded cabin",
                                copyId: null,
                                answerOrder: null,
                                score: '{"38":1}',
                                quizQuestionId: 51,
                                thumbnailFileId: 167,
                                createdAt: "2020-02-05T03:40:59.000Z",
                                updatedAt: "2020-02-21T00:14:25.000Z",
                                thumbnailFile: {
                                    id: 167,
                                    size: 75528,
                                    path: require("../images/Files/q3answer1_cabin_wishmas.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"5ea4c59e74bce732fd759c49d1788fda"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Olivier Guillard on Unsplash",
                                    createdAt: "2020-02-21T00:05:52.000Z",
                                    updatedAt: "2020-02-21T00:05:55.000Z",
                                },
                            },
                            {
                                id: 193,
                                label: "Coastal single-story",
                                copyId: null,
                                answerOrder: null,
                                score: '{"37":1,"38":0}',
                                quizQuestionId: 51,
                                thumbnailFileId: 168,
                                createdAt: "2020-02-05T03:41:10.000Z",
                                updatedAt: "2020-02-21T00:06:10.000Z",
                                thumbnailFile: {
                                    id: 168,
                                    size: 47469,
                                    path: require("../images/Files/q3answer2_beachhouse_wishmas.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"9dce707d3075600555c4232146bd2f20"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Milo Miloezger on Unsplash",
                                    createdAt: "2020-02-21T00:06:06.000Z",
                                    updatedAt: "2020-02-21T00:06:09.000Z",
                                },
                            },
                            {
                                id: 194,
                                label: "Apartment",
                                copyId: null,
                                answerOrder: null,
                                score: '{"35":1,"37":0,"38":0}',
                                quizQuestionId: 51,
                                thumbnailFileId: 169,
                                createdAt: "2020-02-05T03:41:18.000Z",
                                updatedAt: "2020-02-21T00:06:24.000Z",
                                thumbnailFile: {
                                    id: 169,
                                    size: 47712,
                                    path: require("../images/Files/q3answer3_apartment_wishmas.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"e1266a2602277462d4a29282549307e5"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Luke van Zyl on Unsplash",
                                    createdAt: "2020-02-21T00:06:17.000Z",
                                    updatedAt: "2020-02-21T00:06:22.000Z",
                                },
                            },
                            {
                                id: 195,
                                label: "2 story terrace",
                                copyId: null,
                                answerOrder: null,
                                score: '{"35":0,"36":1,"37":0,"38":0}',
                                quizQuestionId: 51,
                                thumbnailFileId: 170,
                                createdAt: "2020-02-05T03:41:27.000Z",
                                updatedAt: "2020-02-21T00:06:39.000Z",
                                thumbnailFile: {
                                    id: 170,
                                    size: 49553,
                                    path: require("../images/Files/q3answer4_terrace_wishmas.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"3a0748517a4a773c2defdf92a6eb1781"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by garrett parker on Unsplash",
                                    createdAt: "2020-02-21T00:06:32.000Z",
                                    updatedAt: "2020-02-21T00:06:37.000Z",
                                },
                            },
                        ],
                    },
                    {
                        id: 52,
                        label: "Pick a place to camp:",
                        copyId: null,
                        thumbnailFileId: null,
                        createdAt: "2020-02-05T03:38:32.000Z",
                        updatedAt: "2020-02-05T03:38:32.000Z",
                        quizId: 10,
                        thumbnailFile: null,
                        quizAnswers: [
                            {
                                id: 196,
                                label: "Bushland",
                                copyId: null,
                                answerOrder: null,
                                score: '{"38":1}',
                                quizQuestionId: 52,
                                thumbnailFileId: 171,
                                createdAt: "2020-02-05T03:44:27.000Z",
                                updatedAt: "2020-02-21T00:07:32.000Z",
                                thumbnailFile: {
                                    id: 171,
                                    size: 62788,
                                    path: require("../images/Files/q4answer1_bushland_wishmas.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"c5386e8d46061e41e0f873cebae634c2"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Mitch Braithwaite on Unsplash",
                                    createdAt: "2020-02-21T00:07:24.000Z",
                                    updatedAt: "2020-02-21T00:07:31.000Z",
                                },
                            },
                            {
                                id: 197,
                                label: "Beach",
                                copyId: null,
                                answerOrder: null,
                                score: '{"37":1}',
                                quizQuestionId: 52,
                                thumbnailFileId: 172,
                                createdAt: "2020-02-05T03:44:34.000Z",
                                updatedAt: "2020-02-21T00:07:46.000Z",
                                thumbnailFile: {
                                    id: 172,
                                    size: 60152,
                                    path: require("../images/Files/q4answer2_beach_wishmas.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"731dcff93abc6d10e8f0fe3966302622"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Pedro Monteiro on Unsplash",
                                    createdAt: "2020-02-21T00:07:41.000Z",
                                    updatedAt: "2020-02-21T00:07:44.000Z",
                                },
                            },
                            {
                                id: 198,
                                label: "Rainforest",
                                copyId: null,
                                answerOrder: null,
                                score: '{"36":1}',
                                quizQuestionId: 52,
                                thumbnailFileId: 173,
                                createdAt: "2020-02-05T03:44:44.000Z",
                                updatedAt: "2020-02-21T00:08:00.000Z",
                                thumbnailFile: {
                                    id: 173,
                                    size: 68133,
                                    path: require("../images/Files/q4answer3_rainforest_wishmas.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"1d78156de7ac3272e978c0a18811bcc2"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Mandy Choi on Unsplash",
                                    createdAt: "2020-02-21T00:07:54.000Z",
                                    updatedAt: "2020-02-21T00:07:58.000Z",
                                },
                            },
                            {
                                id: 199,
                                label: "Cave",
                                copyId: null,
                                answerOrder: null,
                                score: '{"35":1}',
                                quizQuestionId: 52,
                                thumbnailFileId: 174,
                                createdAt: "2020-02-05T03:44:51.000Z",
                                updatedAt: "2020-02-21T00:08:13.000Z",
                                thumbnailFile: {
                                    id: 174,
                                    size: 74385,
                                    path: require("../images/Files/q4answer4_cave_wishmas.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"f6d55cb84dd1439b04a09b10c9a063fc"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Ksenia Kudelkina on Unsplash",
                                    createdAt: "2020-02-21T00:08:08.000Z",
                                    updatedAt: "2020-02-21T00:08:11.000Z",
                                },
                            },
                        ],
                    },
                    {
                        id: 53,
                        label: "Pick a design style:",
                        copyId: null,
                        thumbnailFileId: null,
                        createdAt: "2020-02-05T03:38:32.000Z",
                        updatedAt: "2020-02-05T03:38:32.000Z",
                        quizId: 10,
                        thumbnailFile: null,
                        quizAnswers: [
                            {
                                id: 200,
                                label: "Scandinavian",
                                copyId: null,
                                answerOrder: null,
                                score: '{"35":1}',
                                quizQuestionId: 53,
                                thumbnailFileId: 175,
                                createdAt: "2020-02-05T03:45:08.000Z",
                                updatedAt: "2020-02-21T00:08:46.000Z",
                                thumbnailFile: {
                                    id: 175,
                                    size: 47287,
                                    path: require("../images/Files/q6answer1_Scandinavian_wishmas.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"184631b1fddfc67ea29209bd9323817a"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Jean-Philippe Delberghe on Unsplash",
                                    createdAt: "2020-02-21T00:08:43.000Z",
                                    updatedAt: "2020-02-21T00:08:45.000Z",
                                },
                            },
                            {
                                id: 201,
                                label: "Rustic",
                                copyId: null,
                                answerOrder: null,
                                score: '{"36":1}',
                                quizQuestionId: 53,
                                thumbnailFileId: 176,
                                createdAt: "2020-02-05T03:45:17.000Z",
                                updatedAt: "2020-02-21T00:09:07.000Z",
                                thumbnailFile: {
                                    id: 176,
                                    size: 92373,
                                    path: require("../images/Files/q6answer2_rustic_wishmas.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"73a4cdd2da88382179a3e391df34fbab"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Chris Barbalis on Unsplash",
                                    createdAt: "2020-02-21T00:09:02.000Z",
                                    updatedAt: "2020-02-21T00:09:06.000Z",
                                },
                            },
                            {
                                id: 202,
                                label: "Hamptons",
                                copyId: null,
                                answerOrder: null,
                                score: '{"37":1}',
                                quizQuestionId: 53,
                                thumbnailFileId: 177,
                                createdAt: "2020-02-05T03:45:25.000Z",
                                updatedAt: "2020-02-21T00:09:24.000Z",
                                thumbnailFile: {
                                    id: 177,
                                    size: 65073,
                                    path: require("../images/Files/q6answer3_hamptons_wishmas.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"6f8a05c30b2910125e91813df8ab7d2e"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Mario Azzi on Unsplash",
                                    createdAt: "2020-02-21T00:09:18.000Z",
                                    updatedAt: "2020-02-21T00:09:22.000Z",
                                },
                            },
                            {
                                id: 203,
                                label: "Shabby Chic",
                                copyId: null,
                                answerOrder: null,
                                score: '{"38":1}',
                                quizQuestionId: 53,
                                thumbnailFileId: 178,
                                createdAt: "2020-02-05T03:45:34.000Z",
                                updatedAt: "2020-02-21T00:09:37.000Z",
                                thumbnailFile: {
                                    id: 178,
                                    size: 62215,
                                    path: require("../images/Files/q6answer4_shabbychic_wishmas.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"4cf06c45a1119559de609fbd8056e6f8"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by dylan nolte on Unsplash",
                                    createdAt: "2020-02-21T00:09:32.000Z",
                                    updatedAt: "2020-02-21T00:09:35.000Z",
                                },
                            },
                        ],
                    },
                    {
                        id: 54,
                        label: "Pick a snack:",
                        copyId: null,
                        thumbnailFileId: null,
                        createdAt: "2020-02-05T03:38:32.000Z",
                        updatedAt: "2020-02-05T03:38:32.000Z",
                        quizId: 10,
                        thumbnailFile: null,
                        quizAnswers: [
                            {
                                id: 204,
                                label: "Donut",
                                copyId: null,
                                answerOrder: null,
                                score: '{"35":1}',
                                quizQuestionId: 54,
                                thumbnailFileId: 179,
                                createdAt: "2020-02-05T03:45:44.000Z",
                                updatedAt: "2020-02-21T00:09:58.000Z",
                                thumbnailFile: {
                                    id: 179,
                                    size: 27975,
                                    path: require("../images/Files/q5answer1_donut_wishmas.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"c28cf558c4212e9b2269f69d64e3770d"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Isaac Quesada on Unsplash",
                                    createdAt: "2020-02-21T00:09:51.000Z",
                                    updatedAt: "2020-02-21T00:09:56.000Z",
                                },
                            },
                            {
                                id: 205,
                                label: "Ice Cream",
                                copyId: null,
                                answerOrder: null,
                                score: '{"37":1}',
                                quizQuestionId: 54,
                                thumbnailFileId: 180,
                                createdAt: "2020-02-05T03:45:51.000Z",
                                updatedAt: "2020-02-21T00:10:12.000Z",
                                thumbnailFile: {
                                    id: 180,
                                    size: 52993,
                                    path: require("../images/Files/q5answer2_icecream_wishmas.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"c1c2d542510f25133f6cf74488311ec8"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Nas Mato on Unsplash",
                                    createdAt: "2020-02-21T00:10:06.000Z",
                                    updatedAt: "2020-02-21T00:10:10.000Z",
                                },
                            },
                            {
                                id: 206,
                                label: "Hot dog",
                                copyId: null,
                                answerOrder: null,
                                score: '{"38":1}',
                                quizQuestionId: 54,
                                thumbnailFileId: 181,
                                createdAt: "2020-02-05T03:45:59.000Z",
                                updatedAt: "2020-02-21T00:10:26.000Z",
                                thumbnailFile: {
                                    id: 181,
                                    size: 49457,
                                    path: require("../images/Files/q5answer3_hotdog_wishmas.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"01cb99f4ca4a72ff96d34d97b95be438"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Peter Secan on Unsplash",
                                    createdAt: "2020-02-21T00:10:21.000Z",
                                    updatedAt: "2020-02-21T00:10:24.000Z",
                                },
                            },
                            {
                                id: 207,
                                label: "Trail mix",
                                copyId: null,
                                answerOrder: null,
                                score: '{"36":1}',
                                quizQuestionId: 54,
                                thumbnailFileId: 182,
                                createdAt: "2020-02-05T03:46:08.000Z",
                                updatedAt: "2020-02-21T00:10:41.000Z",
                                thumbnailFile: {
                                    id: 182,
                                    size: 91476,
                                    path: require("../images/Files/q5answer4_trailmix_wishmas.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"ea39290e221ab69929f58137656457aa"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Maksim Shutov on Unsplash",
                                    createdAt: "2020-02-21T00:10:35.000Z",
                                    updatedAt: "2020-02-21T00:10:39.000Z",
                                },
                            },
                        ],
                    },
                    {
                        id: 55,
                        label: "Pick a feature:",
                        copyId: null,
                        thumbnailFileId: null,
                        createdAt: "2020-02-05T03:38:32.000Z",
                        updatedAt: "2020-02-05T03:38:32.000Z",
                        quizId: 10,
                        thumbnailFile: null,
                        quizAnswers: [
                            {
                                id: 208,
                                label: "Pool",
                                copyId: null,
                                answerOrder: null,
                                score: '{"37":1}',
                                quizQuestionId: 55,
                                thumbnailFileId: 183,
                                createdAt: "2020-02-05T03:46:22.000Z",
                                updatedAt: "2020-02-21T00:11:15.000Z",
                                thumbnailFile: {
                                    id: 183,
                                    size: 77087,
                                    path: require("../images/Files/q7answer1_pool_wishmas.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"65ae3c698ce61b89bad9a8b4531c32c7"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Chris Lawton on Unsplash",
                                    createdAt: "2020-02-21T00:11:07.000Z",
                                    updatedAt: "2020-02-21T00:11:13.000Z",
                                },
                            },
                            {
                                id: 209,
                                label: "Fireplace",
                                copyId: null,
                                answerOrder: null,
                                score: '{"38":1}',
                                quizQuestionId: 55,
                                thumbnailFileId: 184,
                                createdAt: "2020-02-05T03:46:30.000Z",
                                updatedAt: "2020-02-21T00:11:27.000Z",
                                thumbnailFile: {
                                    id: 184,
                                    size: 57945,
                                    path: require("../images/Files/q7answer2_fireplace_wishmas.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"5a098e16950c79b002220d7c633c57ef"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Hayden Scott on Unsplash",
                                    createdAt: "2020-02-21T00:11:22.000Z",
                                    updatedAt: "2020-02-21T00:11:26.000Z",
                                },
                            },
                            {
                                id: 210,
                                label: "Home cinema",
                                copyId: null,
                                answerOrder: null,
                                score: '{"35":1}',
                                quizQuestionId: 55,
                                thumbnailFileId: 185,
                                createdAt: "2020-02-05T03:46:38.000Z",
                                updatedAt: "2020-02-21T00:11:40.000Z",
                                thumbnailFile: {
                                    id: 185,
                                    size: 24921,
                                    path: require("../images/Files/q7answer3_homecinema_wishmas.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"c79097ea3c25d6bd8adc3fefbab6b957"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Felix Mooneeram on Unsplash",
                                    createdAt: "2020-02-21T00:11:35.000Z",
                                    updatedAt: "2020-02-21T00:11:38.000Z",
                                },
                            },
                            {
                                id: 211,
                                label: "Garden",
                                copyId: null,
                                answerOrder: null,
                                score: '{"36":1}',
                                quizQuestionId: 55,
                                thumbnailFileId: 186,
                                createdAt: "2020-02-05T03:46:45.000Z",
                                updatedAt: "2020-02-21T00:11:54.000Z",
                                thumbnailFile: {
                                    id: 186,
                                    size: 87121,
                                    path: require("../images/Files/q7answer4_garden_wishmas.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"b394422f61f73d450b0a36642e0dbf8e"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Veronica Reverse on Unsplash",
                                    createdAt: "2020-02-21T00:11:49.000Z",
                                    updatedAt: "2020-02-21T00:11:52.000Z",
                                },
                            },
                        ],
                    },
                ],
                quizResults: [
                    {
                        id: 35,
                        label: "Spidertown",
                        detail: "You are flexible and a little misunderstood. You don’t mind living on the edges of town and can get behind a small space if it is well utilised. You like the darkness and keep your place low key. Minimalism is your mantra!",
                        copyId: null,
                        scoreThreshold: 0,
                        thumbnailFileId: 155,
                        createdAt: "2020-02-05T03:38:32.000Z",
                        updatedAt: "2020-02-20T23:59:54.000Z",
                        quizId: 10,
                        thumbnailFile: {
                            id: 155,
                            size: 39566,
                            path: require("../images/Files/result_spidertown_wishmas.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"d19cb89f5acbaa2b8af986dda6a58fd0"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T23:59:53.000Z",
                            updatedAt: "2020-02-20T23:59:53.000Z",
                        },
                    },
                    {
                        id: 36,
                        label: "Treehouse District",
                        detail: "You love the hustle and bustle of daily life. You are a total nature lover and feel best surrounded by trees and greenery. You are still close by to the heart of the city but you love to getaway to the outdoors when you have a spare minute!",
                        copyId: null,
                        scoreThreshold: 0,
                        thumbnailFileId: 156,
                        createdAt: "2020-02-05T03:38:32.000Z",
                        updatedAt: "2020-02-21T00:00:02.000Z",
                        quizId: 10,
                        thumbnailFile: {
                            id: 156,
                            size: 41913,
                            path: require("../images/Files/result_treetopsdisctrict_wishmas.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"5d93e3aa2889209f83fe90f671c4d490"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-21T00:00:01.000Z",
                            updatedAt: "2020-02-21T00:00:01.000Z",
                        },
                    },
                    {
                        id: 37,
                        label: "Quokka Rocks",
                        detail: "You are easy breezy and need to be by the water. You are happy lounging outside catching some sun or going for a dip. You are social and keep up with the community. Open door policy is key!  ",
                        copyId: null,
                        scoreThreshold: 0,
                        thumbnailFileId: 157,
                        createdAt: "2020-02-05T03:38:32.000Z",
                        updatedAt: "2020-02-21T00:00:12.000Z",
                        quizId: 10,
                        thumbnailFile: {
                            id: 157,
                            size: 52125,
                            path: require("../images/Files/result_quokkarocks_wishmas.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"7db7e83612493d2cef7525d3ee4f2e50"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-21T00:00:11.000Z",
                            updatedAt: "2020-02-21T00:00:11.000Z",
                        },
                    },
                    {
                        id: 38,
                        label: "The Wild",
                        detail: "You like space! You don’t want to have to see your neighbour and aren’t afraid of toughing it. You can throw together a place and make it feel like home. Your home is your domain and you wouldn’t have it any other way. ",
                        copyId: null,
                        scoreThreshold: 0,
                        thumbnailFileId: 158,
                        createdAt: "2020-02-05T03:38:32.000Z",
                        updatedAt: "2020-02-21T00:00:22.000Z",
                        quizId: 10,
                        thumbnailFile: {
                            id: 158,
                            size: 30085,
                            path: require("../images/Files/result_thewild_wishmas.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"36f32fb8ea99b096393ba31adfd83d5e"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-21T00:00:20.000Z",
                            updatedAt: "2020-02-21T00:00:20.000Z",
                        },
                    },
                ],
                thumbnailFile: {
                    id: 275,
                    size: 45915,
                    path: require("../images/Files/quiz_thumbnail_10.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"0646343390ff918bb8e496578c3522dc"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-26T06:38:16.000Z",
                    updatedAt: "2020-02-26T06:38:16.000Z",
                },
            },
        },
        {
            id: 21,
            type: "quiz",
            content_id: 12,
            label: "How good is your memory?",
            title_copy_id: 21,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-04T04:08:04.000Z",
            forceLarge: false,
            createdAt: "2020-02-05T04:08:04.000Z",
            updatedAt: "2020-02-25T23:50:01.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 52,
                    contentId: 21,
                    tagId: 13,
                    createdAt: "2020-02-05T22:54:12.000Z",
                    updatedAt: "2020-02-05T22:54:12.000Z",
                    tag: {
                        id: 13,
                        tag_id: "quiz",
                        label: "Quiz",
                        createdAt: "2020-02-04T01:25:21.000Z",
                        updatedAt: "2020-02-04T01:25:21.000Z",
                    },
                },
                {
                    id: 53,
                    contentId: 21,
                    tagId: 14,
                    createdAt: "2020-02-05T22:54:14.000Z",
                    updatedAt: "2020-02-05T22:54:14.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 54,
                    contentId: 21,
                    tagId: 11,
                    createdAt: "2020-02-05T22:54:18.000Z",
                    updatedAt: "2020-02-05T22:54:18.000Z",
                    tag: {
                        id: 11,
                        tag_id: "yarra",
                        label: "Yarra",
                        createdAt: "2020-02-04T01:25:09.000Z",
                        updatedAt: "2020-02-04T01:25:09.000Z",
                    },
                },
                {
                    id: 55,
                    contentId: 21,
                    tagId: 9,
                    createdAt: "2020-02-05T22:54:21.000Z",
                    updatedAt: "2020-02-05T22:54:21.000Z",
                    tag: {
                        id: 9,
                        tag_id: "kerry",
                        label: "Kerry",
                        createdAt: "2020-02-04T01:25:00.000Z",
                        updatedAt: "2020-02-04T01:25:00.000Z",
                    },
                },
                {
                    id: 56,
                    contentId: 21,
                    tagId: 10,
                    createdAt: "2020-02-05T22:54:25.000Z",
                    updatedAt: "2020-02-05T22:54:25.000Z",
                    tag: {
                        id: 10,
                        tag_id: "petra",
                        label: "Petra",
                        createdAt: "2020-02-04T01:25:05.000Z",
                        updatedAt: "2020-02-04T01:25:05.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 12,
                label: "How good is your memory?",
                copyId: null,
                thumbnailFileId: 273,
                quizType: 2,
                createdAt: "2020-02-05T03:54:30.000Z",
                updatedAt: "2020-02-26T06:37:51.000Z",
                copy: null,
                quizQuestions: [
                    {
                        id: 62,
                        label: "What colour is Yarra's frill?",
                        copyId: null,
                        thumbnailFileId: 113,
                        createdAt: "2020-02-05T03:54:30.000Z",
                        updatedAt: "2020-02-21T00:17:30.000Z",
                        quizId: 12,
                        thumbnailFile: {
                            id: 113,
                            size: 35555,
                            path: require("../images/Files/yarra_character_wishmastree_01.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"b94eb3dc5661d08d76a9bd5074c5db18"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T02:58:03.000Z",
                            updatedAt: "2020-02-20T02:58:03.000Z",
                        },
                        quizAnswers: [
                            {
                                id: 224,
                                label: "Orange",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 62,
                                thumbnailFileId: 46,
                                createdAt: "2020-02-05T03:55:05.000Z",
                                updatedAt: "2020-02-07T04:03:59.000Z",
                                thumbnailFile: {
                                    id: 46,
                                    size: 5684,
                                    path: require("../images/Files/orange.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"1ab9c45d8253b38f1232802e1f5c9d1f"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-07T04:03:37.000Z",
                                    updatedAt: "2020-02-07T04:03:37.000Z",
                                },
                            },
                            {
                                id: 225,
                                label: "Purple",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 62,
                                thumbnailFileId: 47,
                                createdAt: "2020-02-05T03:55:09.000Z",
                                updatedAt: "2020-02-07T04:05:07.000Z",
                                thumbnailFile: {
                                    id: 47,
                                    size: 6418,
                                    path: require("../images/Files/purple.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"129c95e0e1c49cbae42b8275522e4f66"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-07T04:05:01.000Z",
                                    updatedAt: "2020-02-07T04:05:01.000Z",
                                },
                            },
                        ],
                    },
                    {
                        id: 63,
                        label: "Does Yarra have teeth?",
                        copyId: null,
                        thumbnailFileId: 132,
                        createdAt: "2020-02-05T03:54:30.000Z",
                        updatedAt: "2020-02-21T00:18:02.000Z",
                        quizId: 12,
                        thumbnailFile: {
                            id: 132,
                            size: 46761,
                            path: require("../images/Files/yarra_2_square.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"a202db671ebf39febe967840a9c53ebd"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T22:45:05.000Z",
                            updatedAt: "2020-02-20T22:45:05.000Z",
                        },
                        quizAnswers: [
                            {
                                id: 226,
                                label: "Yes",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 63,
                                thumbnailFileId: 48,
                                createdAt: "2020-02-05T03:55:18.000Z",
                                updatedAt: "2020-02-07T04:07:31.000Z",
                                thumbnailFile: {
                                    id: 48,
                                    size: 164487,
                                    path: require("../images/Files/teeth.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"324d4840f8560a250c2d2f34fe244c8c"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Andreas Berlin on Unsplash",
                                    createdAt: "2020-02-07T04:07:20.000Z",
                                    updatedAt: "2020-02-07T04:11:25.000Z",
                                },
                            },
                            {
                                id: 227,
                                label: "No",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 63,
                                thumbnailFileId: 49,
                                createdAt: "2020-02-05T03:55:23.000Z",
                                updatedAt: "2020-02-07T04:10:10.000Z",
                                thumbnailFile: {
                                    id: 49,
                                    size: 79565,
                                    path: require("../images/Files/noteeth.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"3369c87e3bf042a0d72906e9f9f9998f"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Rodrigo Pereira on Unsplash",
                                    createdAt: "2020-02-07T04:09:58.000Z",
                                    updatedAt: "2020-02-07T04:10:08.000Z",
                                },
                            },
                        ],
                    },
                    {
                        id: 64,
                        label: "What colour are Kerry's eyes?",
                        copyId: null,
                        thumbnailFileId: 133,
                        createdAt: "2020-02-05T03:54:30.000Z",
                        updatedAt: "2020-02-21T00:18:12.000Z",
                        quizId: 12,
                        thumbnailFile: {
                            id: 133,
                            size: 45971,
                            path: require("../images/Files/kerry_1_square.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"1a62864604d3a5cd57d043bf74ce0298"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T22:45:17.000Z",
                            updatedAt: "2020-02-20T22:45:17.000Z",
                        },
                        quizAnswers: [
                            {
                                id: 228,
                                label: "Brown",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 64,
                                thumbnailFileId: 50,
                                createdAt: "2020-02-05T03:55:35.000Z",
                                updatedAt: "2020-02-07T04:14:08.000Z",
                                thumbnailFile: {
                                    id: 50,
                                    size: 4199,
                                    path: require("../images/Files/brown.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"9ea24a329bd1ebab2c07965b6eec6d8d"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-07T04:14:02.000Z",
                                    updatedAt: "2020-02-07T04:14:02.000Z",
                                },
                            },
                            {
                                id: 229,
                                label: "Green",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 64,
                                thumbnailFileId: 51,
                                createdAt: "2020-02-05T03:55:39.000Z",
                                updatedAt: "2020-02-07T04:14:25.000Z",
                                thumbnailFile: {
                                    id: 51,
                                    size: 4199,
                                    path: require("../images/Files/green.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"4da7a43446a0a0aa259a5f032d4e8c64"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-07T04:14:20.000Z",
                                    updatedAt: "2020-02-07T04:14:20.000Z",
                                },
                            },
                        ],
                    },
                    {
                        id: 65,
                        label: "What colour are Stick's crystals?",
                        copyId: null,
                        thumbnailFileId: 132,
                        createdAt: "2020-02-05T03:54:30.000Z",
                        updatedAt: "2020-02-21T00:18:22.000Z",
                        quizId: 12,
                        thumbnailFile: {
                            id: 132,
                            size: 46761,
                            path: require("../images/Files/yarra_2_square.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"a202db671ebf39febe967840a9c53ebd"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T22:45:05.000Z",
                            updatedAt: "2020-02-20T22:45:05.000Z",
                        },
                        quizAnswers: [
                            {
                                id: 230,
                                label: "Blue",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 65,
                                thumbnailFileId: 52,
                                createdAt: "2020-02-05T03:56:03.000Z",
                                updatedAt: "2020-02-07T04:16:53.000Z",
                                thumbnailFile: {
                                    id: 52,
                                    size: 4200,
                                    path: require("../images/Files/lightBlue.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"8a399a3f92cf62bcaa62468ecae1d34b"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-07T04:15:59.000Z",
                                    updatedAt: "2020-02-07T04:15:59.000Z",
                                },
                            },
                            {
                                id: 231,
                                label: "Yellow",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 65,
                                thumbnailFileId: 53,
                                createdAt: "2020-02-05T03:56:09.000Z",
                                updatedAt: "2020-02-07T04:16:18.000Z",
                                thumbnailFile: {
                                    id: 53,
                                    size: 4201,
                                    path: require("../images/Files/yellow.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"5cbf7f5695ad786d3968479741dfb8a0"',
                                    region: "ap-southeast-2",
                                    credit: null,
                                    createdAt: "2020-02-07T04:16:15.000Z",
                                    updatedAt: "2020-02-07T04:16:15.000Z",
                                },
                            },
                        ],
                    },
                    {
                        id: 66,
                        label: "How many petals does the Wishmas flower have?",
                        copyId: null,
                        thumbnailFileId: 117,
                        createdAt: "2020-02-05T03:54:30.000Z",
                        updatedAt: "2020-02-21T00:18:56.000Z",
                        quizId: 12,
                        thumbnailFile: {
                            id: 117,
                            size: 49889,
                            path: require("../images/Files/Q1Answer_wishmasflower_wishmastree.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"f70c1bc91be3ae53bb7a112a9c001ffd"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T04:17:30.000Z",
                            updatedAt: "2020-02-20T04:17:30.000Z",
                        },
                        quizAnswers: [
                            {
                                id: 232,
                                label: "Five",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 66,
                                thumbnailFileId: 54,
                                createdAt: "2020-02-05T04:04:00.000Z",
                                updatedAt: "2020-02-07T04:18:57.000Z",
                                thumbnailFile: {
                                    id: 54,
                                    size: 65440,
                                    path: require("../images/Files/fivePetals.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"c2c0324a3b2b04d623fdf399a17735ab"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Nick Fewings on Unsplash",
                                    createdAt: "2020-02-07T04:18:53.000Z",
                                    updatedAt: "2020-02-07T04:18:55.000Z",
                                },
                            },
                            {
                                id: 233,
                                label: "Four",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 66,
                                thumbnailFileId: 55,
                                createdAt: "2020-02-05T04:04:05.000Z",
                                updatedAt: "2020-02-07T04:20:55.000Z",
                                thumbnailFile: {
                                    id: 55,
                                    size: 78490,
                                    path: require("../images/Files/fourPetals.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"e2f9e82414f39c2d7ec02e33986c5bda"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by DDP on Unsplash",
                                    createdAt: "2020-02-07T04:20:46.000Z",
                                    updatedAt: "2020-02-07T04:20:49.000Z",
                                },
                            },
                        ],
                    },
                    {
                        id: 67,
                        label: "What is the right TV set?",
                        copyId: null,
                        thumbnailFileId: 187,
                        createdAt: "2020-02-05T03:54:30.000Z",
                        updatedAt: "2020-02-21T00:19:35.000Z",
                        quizId: 12,
                        thumbnailFile: {
                            id: 187,
                            size: 61903,
                            path: require("../images/Files/quiz_tv_set.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"619f98eaf21f8a140e0d4c2be6c8f7ae"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-21T00:19:34.000Z",
                            updatedAt: "2020-02-21T00:19:34.000Z",
                        },
                        quizAnswers: [
                            {
                                id: 234,
                                label: "Antlers",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 67,
                                thumbnailFileId: 57,
                                createdAt: "2020-02-05T04:05:23.000Z",
                                updatedAt: "2020-02-07T04:36:31.000Z",
                                thumbnailFile: {
                                    id: 57,
                                    size: 62715,
                                    path: require("../images/Files/antlers.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"d42231eed63e927cc3f1448a9c5c230b"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Livin4wheel on Unsplash",
                                    createdAt: "2020-02-07T04:26:41.000Z",
                                    updatedAt: "2020-02-07T04:26:45.000Z",
                                },
                            },
                            {
                                id: 235,
                                label: "Bunny Ears",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 67,
                                thumbnailFileId: 56,
                                createdAt: "2020-02-05T04:05:29.000Z",
                                updatedAt: "2020-02-07T04:23:29.000Z",
                                thumbnailFile: {
                                    id: 56,
                                    size: 113729,
                                    path: require("../images/Files/bunnyEars.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"92ba0726309c697c985e27c9a544bced"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Emiliano Vittoriosi on Unsplash",
                                    createdAt: "2020-02-07T04:23:25.000Z",
                                    updatedAt: "2020-02-07T04:23:28.000Z",
                                },
                            },
                        ],
                    },
                    {
                        id: 68,
                        label: "Which is Kerry's favourite toy?",
                        copyId: null,
                        thumbnailFileId: 188,
                        createdAt: "2020-02-05T03:54:30.000Z",
                        updatedAt: "2020-02-21T00:32:33.000Z",
                        quizId: 12,
                        thumbnailFile: {
                            id: 188,
                            size: 115636,
                            path: require("../images/Files/kerry_stuffed_toy.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"9fc8448b094b76bd8aa895e6133c5fe2"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-21T00:32:32.000Z",
                            updatedAt: "2020-02-21T00:32:32.000Z",
                        },
                        quizAnswers: [
                            {
                                id: 236,
                                label: "Polar Bear",
                                copyId: null,
                                answerOrder: null,
                                score: "1",
                                quizQuestionId: 68,
                                thumbnailFileId: 58,
                                createdAt: "2020-02-05T04:06:06.000Z",
                                updatedAt: "2020-02-07T04:37:17.000Z",
                                thumbnailFile: {
                                    id: 58,
                                    size: 92185,
                                    path: require("../images/Files/polarBear.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"da260ccff14ebba0017c8d28d3e66a60"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Dan Bolton on Unsplash",
                                    createdAt: "2020-02-07T04:37:11.000Z",
                                    updatedAt: "2020-02-07T04:37:14.000Z",
                                },
                            },
                            {
                                id: 237,
                                label: "Grizzly Bear",
                                copyId: null,
                                answerOrder: null,
                                score: "0",
                                quizQuestionId: 68,
                                thumbnailFileId: 59,
                                createdAt: "2020-02-05T04:06:12.000Z",
                                updatedAt: "2020-02-07T04:38:48.000Z",
                                thumbnailFile: {
                                    id: 59,
                                    size: 215012,
                                    path: require("../images/Files/grizzlyBear.jpg"),
                                    bucketId: "tfsc-hub-file-bucket",
                                    eTag: '"f5380a129a877934ca6182495096fd2b"',
                                    region: "ap-southeast-2",
                                    credit: "Photo by Richard Lee on Unsplash",
                                    createdAt: "2020-02-07T04:38:40.000Z",
                                    updatedAt: "2020-02-07T04:38:45.000Z",
                                },
                            },
                        ],
                    },
                ],
                quizResults: [
                    {
                        id: 41,
                        label: "6-7",
                        detail: "Wow, you are a Wishmas Tree wiz! You have seen it front to back and back to front. You remember all the details and can consider yourself a true fan of the film! Well done! ",
                        copyId: null,
                        scoreThreshold: 6,
                        thumbnailFileId: 129,
                        createdAt: "2020-02-05T03:54:30.000Z",
                        updatedAt: "2020-02-21T00:17:15.000Z",
                        quizId: 12,
                        thumbnailFile: {
                            id: 129,
                            size: 77801,
                            path: require("../images/Files/quiz_good.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"ae0f9e4a0d548ba4353bcb514632166f"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T22:40:59.000Z",
                            updatedAt: "2020-02-20T22:40:59.000Z",
                        },
                    },
                    {
                        id: 42,
                        label: "1-3",
                        detail: "Oof! Stop what you are doing and watch The Wishmas Tree! The world of Sanctuary City is waiting. If you have seen the film then YIKES! You need a rewatch stat! ",
                        copyId: null,
                        scoreThreshold: 0,
                        thumbnailFileId: 127,
                        createdAt: "2020-02-05T03:54:30.000Z",
                        updatedAt: "2020-02-21T00:16:56.000Z",
                        quizId: 12,
                        thumbnailFile: {
                            id: 127,
                            size: 79643,
                            path: require("../images/Files/quiz_bad.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"c870f41e6e06f20569b60061e9cceb54"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T22:40:20.000Z",
                            updatedAt: "2020-02-20T22:40:20.000Z",
                        },
                    },
                    {
                        id: 43,
                        label: "4-5",
                        detail: "Not bad! You have seen the film but it looks as though you could get a refresher! You remember some of the things but a true fan knows all the small details!",
                        copyId: null,
                        scoreThreshold: 4,
                        thumbnailFileId: 128,
                        createdAt: "2020-02-05T03:54:30.000Z",
                        updatedAt: "2020-02-21T00:17:07.000Z",
                        quizId: 12,
                        thumbnailFile: {
                            id: 128,
                            size: 75506,
                            path: require("../images/Files/quiz_mid.jpg"),
                            bucketId: "tfsc-hub-file-bucket",
                            eTag: '"5191dd5f21bc4df89e99437422876bf4"',
                            region: "ap-southeast-2",
                            credit: null,
                            createdAt: "2020-02-20T22:40:41.000Z",
                            updatedAt: "2020-02-20T22:40:41.000Z",
                        },
                    },
                ],
                thumbnailFile: {
                    id: 273,
                    size: 39896,
                    path: require("../images/Files/quiz_thumbnail_12.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"4496df022cf3f0f35d3e82b205267a24"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-26T06:37:48.000Z",
                    updatedAt: "2020-02-26T06:37:48.000Z",
                },
            },
        },
        {
            id: 22,
            type: "video",
            content_id: 2,
            label: "DIY Bio Bird Feeder!",
            title_copy_id: 22,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-04T21:56:55.000Z",
            forceLarge: false,
            createdAt: "2020-02-05T21:56:55.000Z",
            updatedAt: "2020-02-25T22:10:32.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 77,
                    contentId: 22,
                    tagId: 14,
                    createdAt: "2020-02-07T01:55:06.000Z",
                    updatedAt: "2020-02-07T01:55:06.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 81,
                    contentId: 22,
                    tagId: 2,
                    createdAt: "2020-02-24T03:10:36.000Z",
                    updatedAt: "2020-02-24T03:10:36.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 228,
                    contentId: 22,
                    tagId: 8,
                    createdAt: "2020-02-27T00:41:05.000Z",
                    updatedAt: "2020-02-27T00:41:05.000Z",
                    tag: {
                        id: 8,
                        tag_id: "diy",
                        label: "DIY",
                        createdAt: "2020-02-04T01:14:13.000Z",
                        updatedAt: "2020-02-04T01:14:13.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 2,
                url: "https://www.youtube.com/embed/a3MrdfgpaeY",
                thumbnailFileId: 282,
                label: "DIY Bio Bird Feeder!",
                createdAt: "2020-02-04T04:45:43.000Z",
                updatedAt: "2020-02-27T00:43:39.000Z",
                thumbnailFile: {
                    id: 282,
                    size: 51787,
                    path: require("../images/Files/DIY_birdfeeder.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"57181293eb68277bf78b873d823b42b9"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-27T00:43:34.000Z",
                    updatedAt: "2020-02-27T00:43:34.000Z",
                },
            },
        },
        {
            id: 23,
            type: "downloadable",
            content_id: 4,
            label: "Colouring In - Present",
            title_copy_id: 23,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-05T01:38:30.000Z",
            forceLarge: false,
            createdAt: "2020-02-06T01:38:30.000Z",
            updatedAt: "2020-02-17T04:40:05.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 57,
                    contentId: 23,
                    tagId: 21,
                    createdAt: "2020-02-06T01:42:48.000Z",
                    updatedAt: "2020-02-06T01:42:48.000Z",
                    tag: {
                        id: 21,
                        tag_id: "colouringin",
                        label: "Colouring In",
                        createdAt: "2020-02-06T01:42:33.000Z",
                        updatedAt: "2020-02-06T01:42:33.000Z",
                    },
                },
                {
                    id: 58,
                    contentId: 23,
                    tagId: 6,
                    createdAt: "2020-02-06T01:42:51.000Z",
                    updatedAt: "2020-02-06T01:42:51.000Z",
                    tag: {
                        id: 6,
                        tag_id: "downloadable",
                        label: "Downloadable",
                        createdAt: "2019-12-18T03:23:49.000Z",
                        updatedAt: "2019-12-18T03:23:49.000Z",
                    },
                },
                {
                    id: 59,
                    contentId: 23,
                    tagId: 9,
                    createdAt: "2020-02-06T01:42:53.000Z",
                    updatedAt: "2020-02-06T01:42:53.000Z",
                    tag: {
                        id: 9,
                        tag_id: "kerry",
                        label: "Kerry",
                        createdAt: "2020-02-04T01:25:00.000Z",
                        updatedAt: "2020-02-04T01:25:00.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 4,
                url: "",
                label: "Colouring In - Present",
                thumbnailFileId: 192,
                fileId: 32,
                createdAt: "2020-02-03T06:19:48.000Z",
                updatedAt: "2020-02-26T04:18:27.000Z",
                file: {
                    id: 32,
                    size: 561607,
                    path: require("../images/Files/colouring_in_bed.pdf"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"083b0ed12baa2a780987ac8eb96efc6e"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-06T01:38:10.000Z",
                    updatedAt: "2020-02-06T01:38:10.000Z",
                },
                thumbnailFile: {
                    id: 192,
                    size: 47488,
                    path: require("../images/Files/colouring_in_present_thumb.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"24006785967ec94dba90a9a447916fe0"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-26T04:18:25.000Z",
                    updatedAt: "2020-02-26T04:18:25.000Z",
                },
            },
        },
        {
            id: 24,
            type: "downloadable",
            content_id: 5,
            label: "Colouring In - Bernard",
            title_copy_id: 24,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-05T01:40:04.000Z",
            forceLarge: false,
            createdAt: "2020-02-06T01:40:04.000Z",
            updatedAt: "2020-02-06T01:43:38.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 60,
                    contentId: 24,
                    tagId: 12,
                    createdAt: "2020-02-06T01:43:07.000Z",
                    updatedAt: "2020-02-06T01:43:07.000Z",
                    tag: {
                        id: 12,
                        tag_id: "bernard",
                        label: "Bernard",
                        createdAt: "2020-02-04T01:25:16.000Z",
                        updatedAt: "2020-02-04T01:25:16.000Z",
                    },
                },
                {
                    id: 61,
                    contentId: 24,
                    tagId: 21,
                    createdAt: "2020-02-06T01:43:09.000Z",
                    updatedAt: "2020-02-06T01:43:09.000Z",
                    tag: {
                        id: 21,
                        tag_id: "colouringin",
                        label: "Colouring In",
                        createdAt: "2020-02-06T01:42:33.000Z",
                        updatedAt: "2020-02-06T01:42:33.000Z",
                    },
                },
                {
                    id: 62,
                    contentId: 24,
                    tagId: 6,
                    createdAt: "2020-02-06T01:43:11.000Z",
                    updatedAt: "2020-02-06T01:43:11.000Z",
                    tag: {
                        id: 6,
                        tag_id: "downloadable",
                        label: "Downloadable",
                        createdAt: "2019-12-18T03:23:49.000Z",
                        updatedAt: "2019-12-18T03:23:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 5,
                url: "",
                label: "Colouring In - Bernard",
                thumbnailFileId: 193,
                fileId: 35,
                createdAt: "2020-02-06T01:39:04.000Z",
                updatedAt: "2020-02-26T04:18:41.000Z",
                file: {
                    id: 35,
                    size: 390497,
                    path: require("../images/Files/colouring_in_bernard.pdf"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"b44b09f12113b5eb9bd410264ba43398"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-06T01:39:49.000Z",
                    updatedAt: "2020-02-06T01:39:49.000Z",
                },
                thumbnailFile: {
                    id: 193,
                    size: 56641,
                    path: require("../images/Files/colouring_in_bernard_thumb_2.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"adfb28e9b3f300a7f57855427e6708df"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-26T04:18:39.000Z",
                    updatedAt: "2020-02-26T04:18:39.000Z",
                },
            },
        },
        {
            id: 25,
            type: "downloadable",
            content_id: 6,
            label: "Colouring In - Heros",
            title_copy_id: 25,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-05T01:40:15.000Z",
            forceLarge: false,
            createdAt: "2020-02-06T01:40:15.000Z",
            updatedAt: "2020-02-28T00:39:24.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 63,
                    contentId: 25,
                    tagId: 21,
                    createdAt: "2020-02-06T01:43:51.000Z",
                    updatedAt: "2020-02-06T01:43:51.000Z",
                    tag: {
                        id: 21,
                        tag_id: "colouringin",
                        label: "Colouring In",
                        createdAt: "2020-02-06T01:42:33.000Z",
                        updatedAt: "2020-02-06T01:42:33.000Z",
                    },
                },
                {
                    id: 64,
                    contentId: 25,
                    tagId: 6,
                    createdAt: "2020-02-06T01:43:53.000Z",
                    updatedAt: "2020-02-06T01:43:53.000Z",
                    tag: {
                        id: 6,
                        tag_id: "downloadable",
                        label: "Downloadable",
                        createdAt: "2019-12-18T03:23:49.000Z",
                        updatedAt: "2019-12-18T03:23:49.000Z",
                    },
                },
                {
                    id: 65,
                    contentId: 25,
                    tagId: 11,
                    createdAt: "2020-02-06T01:43:56.000Z",
                    updatedAt: "2020-02-06T01:43:56.000Z",
                    tag: {
                        id: 11,
                        tag_id: "yarra",
                        label: "Yarra",
                        createdAt: "2020-02-04T01:25:09.000Z",
                        updatedAt: "2020-02-04T01:25:09.000Z",
                    },
                },
                {
                    id: 66,
                    contentId: 25,
                    tagId: 10,
                    createdAt: "2020-02-06T01:43:58.000Z",
                    updatedAt: "2020-02-06T01:43:58.000Z",
                    tag: {
                        id: 10,
                        tag_id: "petra",
                        label: "Petra",
                        createdAt: "2020-02-04T01:25:05.000Z",
                        updatedAt: "2020-02-04T01:25:05.000Z",
                    },
                },
                {
                    id: 67,
                    contentId: 25,
                    tagId: 9,
                    createdAt: "2020-02-06T01:44:00.000Z",
                    updatedAt: "2020-02-06T01:44:00.000Z",
                    tag: {
                        id: 9,
                        tag_id: "kerry",
                        label: "Kerry",
                        createdAt: "2020-02-04T01:25:00.000Z",
                        updatedAt: "2020-02-04T01:25:00.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 6,
                url: "",
                label: "Colouring In - Crew",
                thumbnailFileId: 194,
                fileId: 37,
                createdAt: "2020-02-06T01:39:09.000Z",
                updatedAt: "2020-02-26T04:18:58.000Z",
                file: {
                    id: 37,
                    size: 627496,
                    path: require("../images/Files/colouring_in_crew.pdf"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"c0ea495d58d98788be25989c4517ab03"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-06T01:40:30.000Z",
                    updatedAt: "2020-02-06T01:40:30.000Z",
                },
                thumbnailFile: {
                    id: 194,
                    size: 67187,
                    path: require("../images/Files/colouring_in_crew_thumb_2.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"4ad9c7016b442e119d9246d030173a7f"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-26T04:18:56.000Z",
                    updatedAt: "2020-02-26T04:18:56.000Z",
                },
            },
        },
        {
            id: 26,
            type: "downloadable",
            content_id: 7,
            label: "Colouring In - Kerry",
            title_copy_id: 26,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-05T01:40:42.000Z",
            forceLarge: false,
            createdAt: "2020-02-06T01:40:42.000Z",
            updatedAt: "2020-02-06T01:44:31.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 68,
                    contentId: 26,
                    tagId: 21,
                    createdAt: "2020-02-06T01:44:21.000Z",
                    updatedAt: "2020-02-06T01:44:21.000Z",
                    tag: {
                        id: 21,
                        tag_id: "colouringin",
                        label: "Colouring In",
                        createdAt: "2020-02-06T01:42:33.000Z",
                        updatedAt: "2020-02-06T01:42:33.000Z",
                    },
                },
                {
                    id: 69,
                    contentId: 26,
                    tagId: 6,
                    createdAt: "2020-02-06T01:44:24.000Z",
                    updatedAt: "2020-02-06T01:44:24.000Z",
                    tag: {
                        id: 6,
                        tag_id: "downloadable",
                        label: "Downloadable",
                        createdAt: "2019-12-18T03:23:49.000Z",
                        updatedAt: "2019-12-18T03:23:49.000Z",
                    },
                },
                {
                    id: 70,
                    contentId: 26,
                    tagId: 9,
                    createdAt: "2020-02-06T01:44:26.000Z",
                    updatedAt: "2020-02-06T01:44:26.000Z",
                    tag: {
                        id: 9,
                        tag_id: "kerry",
                        label: "Kerry",
                        createdAt: "2020-02-04T01:25:00.000Z",
                        updatedAt: "2020-02-04T01:25:00.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 7,
                url: "",
                label: "Colouring In - Kerry",
                thumbnailFileId: 195,
                fileId: 39,
                createdAt: "2020-02-06T01:39:15.000Z",
                updatedAt: "2020-02-26T04:19:13.000Z",
                file: {
                    id: 39,
                    size: 430681,
                    path: require("../images/Files/colouring_in_kerry.pdf"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"e221626a5f5d56be8c57b3513ddd5c02"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-06T01:40:59.000Z",
                    updatedAt: "2020-02-06T01:40:59.000Z",
                },
                thumbnailFile: {
                    id: 195,
                    size: 53060,
                    path: require("../images/Files/colouring_in_kerry_thumb_2.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"bc190fcd5340b688dcb391b85ae2f79c"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-26T04:19:12.000Z",
                    updatedAt: "2020-02-26T04:19:12.000Z",
                },
            },
        },
        {
            id: 27,
            type: "downloadable",
            content_id: 8,
            label: "Colouring In - Petra",
            title_copy_id: 27,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-05T01:41:10.000Z",
            forceLarge: false,
            createdAt: "2020-02-06T01:41:10.000Z",
            updatedAt: "2020-02-06T01:44:44.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 71,
                    contentId: 27,
                    tagId: 21,
                    createdAt: "2020-02-06T01:44:50.000Z",
                    updatedAt: "2020-02-06T01:44:50.000Z",
                    tag: {
                        id: 21,
                        tag_id: "colouringin",
                        label: "Colouring In",
                        createdAt: "2020-02-06T01:42:33.000Z",
                        updatedAt: "2020-02-06T01:42:33.000Z",
                    },
                },
                {
                    id: 72,
                    contentId: 27,
                    tagId: 6,
                    createdAt: "2020-02-06T01:44:52.000Z",
                    updatedAt: "2020-02-06T01:44:52.000Z",
                    tag: {
                        id: 6,
                        tag_id: "downloadable",
                        label: "Downloadable",
                        createdAt: "2019-12-18T03:23:49.000Z",
                        updatedAt: "2019-12-18T03:23:49.000Z",
                    },
                },
                {
                    id: 73,
                    contentId: 27,
                    tagId: 10,
                    createdAt: "2020-02-06T01:44:54.000Z",
                    updatedAt: "2020-02-06T01:44:54.000Z",
                    tag: {
                        id: 10,
                        tag_id: "petra",
                        label: "Petra",
                        createdAt: "2020-02-04T01:25:05.000Z",
                        updatedAt: "2020-02-04T01:25:05.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 8,
                url: "",
                label: "Colouring In - Petra",
                thumbnailFileId: 196,
                fileId: 41,
                createdAt: "2020-02-06T01:39:20.000Z",
                updatedAt: "2020-02-26T04:20:20.000Z",
                file: {
                    id: 41,
                    size: 398282,
                    path: require("../images/Files/colouring_in_petra.pdf"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"d15c45b302c65c28c48af7349c498850"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-06T01:41:22.000Z",
                    updatedAt: "2020-02-06T01:41:22.000Z",
                },
                thumbnailFile: {
                    id: 196,
                    size: 45581,
                    path: require("../images/Files/colouring_in_petra_thumb_2.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"49b4758091d062c59883ea00206630bc"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-26T04:20:17.000Z",
                    updatedAt: "2020-02-26T04:20:17.000Z",
                },
            },
        },
        {
            id: 28,
            type: "downloadable",
            content_id: 9,
            label: "Colouring In - Yarra",
            title_copy_id: 28,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-05T01:41:33.000Z",
            forceLarge: false,
            createdAt: "2020-02-06T01:41:33.000Z",
            updatedAt: "2020-02-06T01:45:08.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 74,
                    contentId: 28,
                    tagId: 21,
                    createdAt: "2020-02-06T01:45:12.000Z",
                    updatedAt: "2020-02-06T01:45:12.000Z",
                    tag: {
                        id: 21,
                        tag_id: "colouringin",
                        label: "Colouring In",
                        createdAt: "2020-02-06T01:42:33.000Z",
                        updatedAt: "2020-02-06T01:42:33.000Z",
                    },
                },
                {
                    id: 75,
                    contentId: 28,
                    tagId: 11,
                    createdAt: "2020-02-06T01:45:14.000Z",
                    updatedAt: "2020-02-06T01:45:14.000Z",
                    tag: {
                        id: 11,
                        tag_id: "yarra",
                        label: "Yarra",
                        createdAt: "2020-02-04T01:25:09.000Z",
                        updatedAt: "2020-02-04T01:25:09.000Z",
                    },
                },
                {
                    id: 76,
                    contentId: 28,
                    tagId: 6,
                    createdAt: "2020-02-06T01:45:18.000Z",
                    updatedAt: "2020-02-06T01:45:18.000Z",
                    tag: {
                        id: 6,
                        tag_id: "downloadable",
                        label: "Downloadable",
                        createdAt: "2019-12-18T03:23:49.000Z",
                        updatedAt: "2019-12-18T03:23:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 9,
                url: "",
                label: "Colouring In - Yarra",
                thumbnailFileId: 197,
                fileId: 43,
                createdAt: "2020-02-06T01:39:26.000Z",
                updatedAt: "2020-02-26T04:20:42.000Z",
                file: {
                    id: 43,
                    size: 742835,
                    path: require("../images/Files/colouring_in_yarra.pdf"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"0669a65f44535cc5c9db4e930eb0cf35"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-06T01:41:52.000Z",
                    updatedAt: "2020-02-06T01:41:52.000Z",
                },
                thumbnailFile: {
                    id: 197,
                    size: 78257,
                    path: require("../images/Files/colouring_in_yarra_thumb_2.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"146a486609a0d43dcc7b5d555ae9bef1"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-26T04:20:40.000Z",
                    updatedAt: "2020-02-26T04:20:40.000Z",
                },
            },
        },
        {
            id: 29,
            type: "locProfile",
            content_id: 2,
            label: "Sanctuary Peak",
            title_copy_id: 29,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-05T22:52:29.000Z",
            forceLarge: false,
            createdAt: "2020-02-06T22:52:29.000Z",
            updatedAt: "2020-02-27T02:04:00.000Z",
            titleTextCopy: null,
            contentTags: [],
            linkedContent: {
                id: 2,
                positionX: 1600,
                positionY: 800,
                label: "Sanctuary Peak",
                thumbnailFileId: 216,
                iconFileId: 189,
                description:
                    "Sanctuary Peak is made of the last remnant of a meteor that struck Earth, forming a crater that now houses Sanctuary City. Within it lies The Wishmas Tree, a meeting place for all residents to come together and cast their wish for a better world once every year. It is here that Kerry makes a fateful wish to explore The Wild, starting a series of events she must race against the clock to undo!  \n\nThe peak itself is made up of crystals with the history of Sanctuary City engraved on its walls by generations of animals who have called this oasis home. Given its location at the heart of the city, the peak is also the place where the Sanctuary City council meets to discuss important matters affecting citizens both inside and outside the walls. \n\nSanctuary Fact: The aqueduct that flows into the city cascades into Sanctuary Peak. ",
                imageFileId: 217,
                createdAt: "2020-02-06T22:52:16.000Z",
                updatedAt: "2020-02-27T02:14:26.000Z",
                thumbnailFile: {
                    id: 216,
                    size: 29269,
                    path: require("../images/Files/gallery_peak_4_thumb.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"2fd8a5930f7c59d5f4e78b3698324650"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-26T04:38:21.000Z",
                    updatedAt: "2020-02-26T04:38:21.000Z",
                },
                iconFile: {
                    id: 189,
                    size: 5233,
                    path: require("../images/Files/mapIcon.png"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"59b8c483a5f5817ec71510a88725d641"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-26T00:52:25.000Z",
                    updatedAt: "2020-02-26T00:52:25.000Z",
                },
                file: {
                    id: 217,
                    size: 172282,
                    path: require("../images/Files/gallery_peak_4.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"3360fd828eed1ca14d7b75ceccca1394"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-26T04:38:22.000Z",
                    updatedAt: "2020-02-26T04:38:22.000Z",
                },
                locationProfileContent: [
                    {
                        id: 6,
                        contentId: 28,
                        locationProfileId: 2,
                        createdAt: "2020-02-06T22:52:40.000Z",
                        updatedAt: "2020-02-06T22:52:40.000Z",
                        content: {
                            id: 28,
                            type: "downloadable",
                            content_id: 9,
                            label: "Colouring In - Yarra",
                            title_copy_id: 28,
                            titleTextCopyId: null,
                            priority: 0,
                            expands: false,
                            isPublic: 1,
                            publishedAt: "2020-02-05T01:41:33.000Z",
                            forceLarge: false,
                            createdAt: "2020-02-06T01:41:33.000Z",
                            updatedAt: "2020-02-06T01:45:08.000Z",
                        },
                    },
                    {
                        id: 7,
                        contentId: 21,
                        locationProfileId: 2,
                        createdAt: "2020-02-06T22:52:49.000Z",
                        updatedAt: "2020-02-06T22:52:49.000Z",
                        content: {
                            id: 21,
                            type: "quiz",
                            content_id: 12,
                            label: "How good is your memory?",
                            title_copy_id: 21,
                            titleTextCopyId: null,
                            priority: 0,
                            expands: false,
                            isPublic: 1,
                            publishedAt: "2020-02-04T04:08:04.000Z",
                            forceLarge: false,
                            createdAt: "2020-02-05T04:08:04.000Z",
                            updatedAt: "2020-02-25T23:50:01.000Z",
                        },
                    },
                    {
                        id: 8,
                        contentId: 10,
                        locationProfileId: 2,
                        createdAt: "2020-02-06T22:52:57.000Z",
                        updatedAt: "2020-02-06T22:52:57.000Z",
                        content: {
                            id: 10,
                            type: "imageGallery",
                            content_id: 2,
                            label: "Gallery - Sanctuary Peak",
                            title_copy_id: 10,
                            titleTextCopyId: null,
                            priority: 0,
                            expands: false,
                            isPublic: 1,
                            publishedAt: "2020-01-30T04:34:40.000Z",
                            forceLarge: false,
                            createdAt: "2020-01-31T04:34:40.000Z",
                            updatedAt: "2020-02-26T04:53:52.000Z",
                        },
                    },
                ],
            },
        },
        {
            id: 31,
            type: "video",
            content_id: 3,
            label: "DIY Crocodile Cookies!",
            title_copy_id: 33,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-03-14T03:11:44.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T03:11:44.000Z",
            updatedAt: "2020-03-20T01:52:31.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 82,
                    contentId: 31,
                    tagId: 8,
                    createdAt: "2020-02-24T04:18:43.000Z",
                    updatedAt: "2020-02-24T04:18:43.000Z",
                    tag: {
                        id: 8,
                        tag_id: "diy",
                        label: "DIY",
                        createdAt: "2020-02-04T01:14:13.000Z",
                        updatedAt: "2020-02-04T01:14:13.000Z",
                    },
                },
                {
                    id: 83,
                    contentId: 31,
                    tagId: 2,
                    createdAt: "2020-02-24T04:18:45.000Z",
                    updatedAt: "2020-02-24T04:18:45.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 84,
                    contentId: 31,
                    tagId: 14,
                    createdAt: "2020-02-24T04:18:46.000Z",
                    updatedAt: "2020-02-24T04:18:46.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 85,
                    contentId: 31,
                    tagId: 18,
                    createdAt: "2020-02-24T04:19:18.000Z",
                    updatedAt: "2020-02-24T04:19:18.000Z",
                    tag: {
                        id: 18,
                        tag_id: "recipe",
                        label: "Recipe",
                        createdAt: "2020-02-04T01:26:33.000Z",
                        updatedAt: "2020-02-04T01:26:33.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 3,
                url: "https://www.youtube.com/embed/c6Ke7TTgi9A",
                thumbnailFileId: null,
                label: "DIY Crocodile Cookies! | Sanctuary City Creations",
                createdAt: "2020-02-04T04:47:50.000Z",
                updatedAt: "2020-02-24T03:11:42.000Z",
                thumbnailFile: null,
            },
        },
        {
            id: 32,
            type: "video",
            content_id: 4,
            label: "DIY Dropbear Mobile",
            title_copy_id: 34,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-03-21T03:12:02.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T03:12:02.000Z",
            updatedAt: "2020-03-20T01:52:56.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 86,
                    contentId: 32,
                    tagId: 2,
                    createdAt: "2020-02-24T04:19:36.000Z",
                    updatedAt: "2020-02-24T04:19:36.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 87,
                    contentId: 32,
                    tagId: 8,
                    createdAt: "2020-02-24T04:19:38.000Z",
                    updatedAt: "2020-02-24T04:19:38.000Z",
                    tag: {
                        id: 8,
                        tag_id: "diy",
                        label: "DIY",
                        createdAt: "2020-02-04T01:14:13.000Z",
                        updatedAt: "2020-02-04T01:14:13.000Z",
                    },
                },
                {
                    id: 88,
                    contentId: 32,
                    tagId: 14,
                    createdAt: "2020-02-24T04:19:41.000Z",
                    updatedAt: "2020-02-24T04:19:41.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 89,
                    contentId: 32,
                    tagId: 20,
                    createdAt: "2020-02-24T04:19:43.000Z",
                    updatedAt: "2020-02-24T04:19:43.000Z",
                    tag: {
                        id: 20,
                        tag_id: "dropbears",
                        label: "Dropbears",
                        createdAt: "2020-02-05T22:51:57.000Z",
                        updatedAt: "2020-02-05T22:51:57.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 4,
                url: "https://www.youtube.com/embed/U6EN2LW7YMI",
                thumbnailFileId: null,
                label: "DIY Dropbear Mobile! | Sanctuary City Creations",
                createdAt: "2020-02-04T04:49:42.000Z",
                updatedAt: "2020-02-24T03:12:01.000Z",
                thumbnailFile: null,
            },
        },
        {
            id: 33,
            type: "video",
            content_id: 5,
            label: "DIY Dropbear Snot",
            title_copy_id: 35,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-03-27T14:00:00.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T03:12:23.000Z",
            updatedAt: "2020-03-20T01:53:22.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 90,
                    contentId: 33,
                    tagId: 8,
                    createdAt: "2020-02-24T04:20:05.000Z",
                    updatedAt: "2020-02-24T04:20:05.000Z",
                    tag: {
                        id: 8,
                        tag_id: "diy",
                        label: "DIY",
                        createdAt: "2020-02-04T01:14:13.000Z",
                        updatedAt: "2020-02-04T01:14:13.000Z",
                    },
                },
                {
                    id: 91,
                    contentId: 33,
                    tagId: 14,
                    createdAt: "2020-02-24T04:20:07.000Z",
                    updatedAt: "2020-02-24T04:20:07.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 92,
                    contentId: 33,
                    tagId: 2,
                    createdAt: "2020-02-24T04:20:10.000Z",
                    updatedAt: "2020-02-24T04:20:10.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 93,
                    contentId: 33,
                    tagId: 20,
                    createdAt: "2020-02-24T04:20:12.000Z",
                    updatedAt: "2020-02-24T04:20:12.000Z",
                    tag: {
                        id: 20,
                        tag_id: "dropbears",
                        label: "Dropbears",
                        createdAt: "2020-02-05T22:51:57.000Z",
                        updatedAt: "2020-02-05T22:51:57.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 5,
                url: "https://www.youtube.com/embed/3Zj8DB0dRy8",
                thumbnailFileId: null,
                label: "DIY Dropbear Snot! | Sanctuary City Creations",
                createdAt: "2020-02-04T04:50:30.000Z",
                updatedAt: "2020-02-24T03:12:22.000Z",
                thumbnailFile: null,
            },
        },
        {
            id: 34,
            type: "video",
            content_id: 6,
            label: "DIY Natural Gummy Dropbears",
            title_copy_id: 36,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-04-03T14:00:00.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T03:12:38.000Z",
            updatedAt: "2020-03-20T01:53:45.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 94,
                    contentId: 34,
                    tagId: 8,
                    createdAt: "2020-02-24T04:20:27.000Z",
                    updatedAt: "2020-02-24T04:20:27.000Z",
                    tag: {
                        id: 8,
                        tag_id: "diy",
                        label: "DIY",
                        createdAt: "2020-02-04T01:14:13.000Z",
                        updatedAt: "2020-02-04T01:14:13.000Z",
                    },
                },
                {
                    id: 95,
                    contentId: 34,
                    tagId: 20,
                    createdAt: "2020-02-24T04:20:28.000Z",
                    updatedAt: "2020-02-24T04:20:28.000Z",
                    tag: {
                        id: 20,
                        tag_id: "dropbears",
                        label: "Dropbears",
                        createdAt: "2020-02-05T22:51:57.000Z",
                        updatedAt: "2020-02-05T22:51:57.000Z",
                    },
                },
                {
                    id: 96,
                    contentId: 34,
                    tagId: 2,
                    createdAt: "2020-02-24T04:20:31.000Z",
                    updatedAt: "2020-02-24T04:20:31.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 97,
                    contentId: 34,
                    tagId: 14,
                    createdAt: "2020-02-24T04:20:35.000Z",
                    updatedAt: "2020-02-24T04:20:35.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 6,
                url: "https://www.youtube.com/embed/jE1RjD3dbfU",
                thumbnailFileId: null,
                label: "DIY Natural Gummy Dropbears | Sanctuary City Creations",
                createdAt: "2020-02-04T04:51:44.000Z",
                updatedAt: "2020-02-24T03:12:37.000Z",
                thumbnailFile: null,
            },
        },
        {
            id: 35,
            type: "video",
            content_id: 7,
            label: "DIY Koala Cookies",
            title_copy_id: 37,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-04-10T14:00:00.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T03:13:07.000Z",
            updatedAt: "2020-03-20T01:53:59.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 98,
                    contentId: 35,
                    tagId: 8,
                    createdAt: "2020-02-24T04:20:51.000Z",
                    updatedAt: "2020-02-24T04:20:51.000Z",
                    tag: {
                        id: 8,
                        tag_id: "diy",
                        label: "DIY",
                        createdAt: "2020-02-04T01:14:13.000Z",
                        updatedAt: "2020-02-04T01:14:13.000Z",
                    },
                },
                {
                    id: 99,
                    contentId: 35,
                    tagId: 2,
                    createdAt: "2020-02-24T04:20:53.000Z",
                    updatedAt: "2020-02-24T04:20:53.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 100,
                    contentId: 35,
                    tagId: 14,
                    createdAt: "2020-02-24T04:20:55.000Z",
                    updatedAt: "2020-02-24T04:20:55.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 7,
                url: "https://www.youtube.com/embed/YjjLr48UhHc",
                thumbnailFileId: null,
                label: "DIY Koala Cookies | Sanctuary City Creations",
                createdAt: "2020-02-04T04:52:29.000Z",
                updatedAt: "2020-02-24T03:13:04.000Z",
                thumbnailFile: null,
            },
        },
        {
            id: 36,
            type: "video",
            content_id: 8,
            label: "DIY Mudcake in a Mug",
            title_copy_id: 38,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-04-17T14:00:00.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T03:13:25.000Z",
            updatedAt: "2020-03-20T01:54:19.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 101,
                    contentId: 36,
                    tagId: 8,
                    createdAt: "2020-02-24T04:21:08.000Z",
                    updatedAt: "2020-02-24T04:21:08.000Z",
                    tag: {
                        id: 8,
                        tag_id: "diy",
                        label: "DIY",
                        createdAt: "2020-02-04T01:14:13.000Z",
                        updatedAt: "2020-02-04T01:14:13.000Z",
                    },
                },
                {
                    id: 102,
                    contentId: 36,
                    tagId: 2,
                    createdAt: "2020-02-24T04:21:10.000Z",
                    updatedAt: "2020-02-24T04:21:10.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 103,
                    contentId: 36,
                    tagId: 14,
                    createdAt: "2020-02-24T04:21:12.000Z",
                    updatedAt: "2020-02-24T04:21:12.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 104,
                    contentId: 36,
                    tagId: 18,
                    createdAt: "2020-02-24T04:21:14.000Z",
                    updatedAt: "2020-02-24T04:21:14.000Z",
                    tag: {
                        id: 18,
                        tag_id: "recipe",
                        label: "Recipe",
                        createdAt: "2020-02-04T01:26:33.000Z",
                        updatedAt: "2020-02-04T01:26:33.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 8,
                url: "https://www.youtube.com/embed/VtLV9HSv2Yc",
                thumbnailFileId: null,
                label: "DIY Mudcake in a Mug! | Sanctuary City Creations",
                createdAt: "2020-02-04T04:55:46.000Z",
                updatedAt: "2020-02-24T03:13:23.000Z",
                thumbnailFile: null,
            },
        },
        {
            id: 38,
            type: "video",
            content_id: 9,
            label: "DIY Tiny Turtles",
            title_copy_id: 40,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-04-24T14:00:00.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T03:13:42.000Z",
            updatedAt: "2020-03-20T01:54:33.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 105,
                    contentId: 38,
                    tagId: 8,
                    createdAt: "2020-02-24T04:21:30.000Z",
                    updatedAt: "2020-02-24T04:21:30.000Z",
                    tag: {
                        id: 8,
                        tag_id: "diy",
                        label: "DIY",
                        createdAt: "2020-02-04T01:14:13.000Z",
                        updatedAt: "2020-02-04T01:14:13.000Z",
                    },
                },
                {
                    id: 106,
                    contentId: 38,
                    tagId: 2,
                    createdAt: "2020-02-24T04:21:31.000Z",
                    updatedAt: "2020-02-24T04:21:31.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 107,
                    contentId: 38,
                    tagId: 14,
                    createdAt: "2020-02-24T04:21:34.000Z",
                    updatedAt: "2020-02-24T04:21:34.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 9,
                url: "https://www.youtube.com/embed/YZpX8XlB5Xw",
                thumbnailFileId: null,
                label: "DIY Tiny Turtles! | Sanctuary City Creations",
                createdAt: "2020-02-04T04:55:56.000Z",
                updatedAt: "2020-02-24T03:13:40.000Z",
                thumbnailFile: null,
            },
        },
        {
            id: 39,
            type: "video",
            content_id: 10,
            label: "DIY Wishmas Flower Papercraft",
            title_copy_id: 41,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-23T03:14:29.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T03:14:29.000Z",
            updatedAt: "2020-02-27T03:16:01.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 129,
                    contentId: 39,
                    tagId: 8,
                    createdAt: "2020-02-24T04:31:52.000Z",
                    updatedAt: "2020-02-24T04:31:52.000Z",
                    tag: {
                        id: 8,
                        tag_id: "diy",
                        label: "DIY",
                        createdAt: "2020-02-04T01:14:13.000Z",
                        updatedAt: "2020-02-04T01:14:13.000Z",
                    },
                },
                {
                    id: 130,
                    contentId: 39,
                    tagId: 14,
                    createdAt: "2020-02-24T04:31:54.000Z",
                    updatedAt: "2020-02-24T04:31:54.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 131,
                    contentId: 39,
                    tagId: 2,
                    createdAt: "2020-02-24T04:31:55.000Z",
                    updatedAt: "2020-02-24T04:31:55.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 10,
                url: "https://www.youtube.com/embed/ubJgE6PIZmM",
                thumbnailFileId: 297,
                label: "DIY Wishmas Flower Papercraft! | Sanctuary City Creations",
                createdAt: "2020-02-04T04:56:08.000Z",
                updatedAt: "2020-02-27T03:32:01.000Z",
                thumbnailFile: {
                    id: 297,
                    size: 121035,
                    path: require("../images/Files/DIY_Wishmasflower.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"fec3e29c43c1ef2e5d77809d622af318"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-27T03:32:00.000Z",
                    updatedAt: "2020-02-27T03:32:00.000Z",
                },
            },
        },
        {
            id: 40,
            type: "video",
            content_id: 11,
            label: "Drawing Yarra",
            title_copy_id: 42,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-23T03:14:58.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T03:14:58.000Z",
            updatedAt: "2020-02-27T03:17:23.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 201,
                    contentId: 40,
                    tagId: 22,
                    createdAt: "2020-02-24T04:39:23.000Z",
                    updatedAt: "2020-02-24T04:39:23.000Z",
                    tag: {
                        id: 22,
                        tag_id: "pauldraws",
                        label: "Paul Draws",
                        createdAt: "2020-02-24T04:17:42.000Z",
                        updatedAt: "2020-02-24T04:17:42.000Z",
                    },
                },
                {
                    id: 202,
                    contentId: 40,
                    tagId: 14,
                    createdAt: "2020-02-24T04:39:24.000Z",
                    updatedAt: "2020-02-24T04:39:24.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 203,
                    contentId: 40,
                    tagId: 2,
                    createdAt: "2020-02-24T04:39:25.000Z",
                    updatedAt: "2020-02-24T04:39:25.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 207,
                    contentId: 40,
                    tagId: 11,
                    createdAt: "2020-02-24T04:40:57.000Z",
                    updatedAt: "2020-02-24T04:40:57.000Z",
                    tag: {
                        id: 11,
                        tag_id: "yarra",
                        label: "Yarra",
                        createdAt: "2020-02-04T01:25:09.000Z",
                        updatedAt: "2020-02-04T01:25:09.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 11,
                url: "https://www.youtube.com/embed/BTVJI86gtME",
                thumbnailFileId: 298,
                label: "Draw Yarra from The Wishmas Tree - Speed Draw with Paul ",
                createdAt: "2020-02-05T06:51:07.000Z",
                updatedAt: "2020-02-27T03:36:40.000Z",
                thumbnailFile: {
                    id: 298,
                    size: 81373,
                    path: require("../images/Files/PaulDraws_Yarra.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"982c60ef69b08194f3bf628dc018b1d2"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-27T03:36:38.000Z",
                    updatedAt: "2020-02-27T03:36:38.000Z",
                },
            },
        },
        {
            id: 41,
            type: "video",
            content_id: 12,
            label: "Drawing Stick",
            title_copy_id: 43,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-04-02T14:00:00.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T03:15:10.000Z",
            updatedAt: "2020-03-20T01:59:44.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 198,
                    contentId: 41,
                    tagId: 2,
                    createdAt: "2020-02-24T04:39:07.000Z",
                    updatedAt: "2020-02-24T04:39:07.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 199,
                    contentId: 41,
                    tagId: 22,
                    createdAt: "2020-02-24T04:39:09.000Z",
                    updatedAt: "2020-02-24T04:39:09.000Z",
                    tag: {
                        id: 22,
                        tag_id: "pauldraws",
                        label: "Paul Draws",
                        createdAt: "2020-02-24T04:17:42.000Z",
                        updatedAt: "2020-02-24T04:17:42.000Z",
                    },
                },
                {
                    id: 200,
                    contentId: 41,
                    tagId: 14,
                    createdAt: "2020-02-24T04:39:11.000Z",
                    updatedAt: "2020-02-24T04:39:11.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 215,
                    contentId: 41,
                    tagId: 15,
                    createdAt: "2020-02-25T22:16:53.000Z",
                    updatedAt: "2020-02-25T22:16:53.000Z",
                    tag: {
                        id: 15,
                        tag_id: "stick",
                        label: "Stick",
                        createdAt: "2020-02-04T01:26:00.000Z",
                        updatedAt: "2020-02-04T01:26:00.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 12,
                url: "https://www.youtube.com/embed/Jjq0tYTCh9c",
                thumbnailFileId: null,
                label: "Draw Stick from The Wishmas Tree - Speed Draw with Paul ",
                createdAt: "2020-02-05T06:51:18.000Z",
                updatedAt: "2020-02-24T03:15:09.000Z",
                thumbnailFile: null,
            },
        },
        {
            id: 42,
            type: "video",
            content_id: 13,
            label: "Drawing Petra",
            title_copy_id: 44,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-03-26T14:00:00.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T03:15:23.000Z",
            updatedAt: "2020-03-20T01:59:30.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 195,
                    contentId: 42,
                    tagId: 22,
                    createdAt: "2020-02-24T04:38:52.000Z",
                    updatedAt: "2020-02-24T04:38:52.000Z",
                    tag: {
                        id: 22,
                        tag_id: "pauldraws",
                        label: "Paul Draws",
                        createdAt: "2020-02-24T04:17:42.000Z",
                        updatedAt: "2020-02-24T04:17:42.000Z",
                    },
                },
                {
                    id: 196,
                    contentId: 42,
                    tagId: 14,
                    createdAt: "2020-02-24T04:38:53.000Z",
                    updatedAt: "2020-02-24T04:38:53.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 197,
                    contentId: 42,
                    tagId: 2,
                    createdAt: "2020-02-24T04:38:54.000Z",
                    updatedAt: "2020-02-24T04:38:54.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 214,
                    contentId: 42,
                    tagId: 10,
                    createdAt: "2020-02-25T22:16:42.000Z",
                    updatedAt: "2020-02-25T22:16:42.000Z",
                    tag: {
                        id: 10,
                        tag_id: "petra",
                        label: "Petra",
                        createdAt: "2020-02-04T01:25:05.000Z",
                        updatedAt: "2020-02-04T01:25:05.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 13,
                url: "https://www.youtube.com/embed/bLeC0kcvkXU",
                thumbnailFileId: null,
                label: "Draw Petra from The Wishmas Tree - Speed Draw with Paul ",
                createdAt: "2020-02-05T06:51:29.000Z",
                updatedAt: "2020-02-24T03:15:22.000Z",
                thumbnailFile: null,
            },
        },
        {
            id: 43,
            type: "video",
            content_id: 14,
            label: "Drawing Kerry",
            title_copy_id: 45,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-23T03:15:35.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T03:15:35.000Z",
            updatedAt: "2020-02-25T22:16:24.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 192,
                    contentId: 43,
                    tagId: 22,
                    createdAt: "2020-02-24T04:38:39.000Z",
                    updatedAt: "2020-02-24T04:38:39.000Z",
                    tag: {
                        id: 22,
                        tag_id: "pauldraws",
                        label: "Paul Draws",
                        createdAt: "2020-02-24T04:17:42.000Z",
                        updatedAt: "2020-02-24T04:17:42.000Z",
                    },
                },
                {
                    id: 193,
                    contentId: 43,
                    tagId: 2,
                    createdAt: "2020-02-24T04:38:40.000Z",
                    updatedAt: "2020-02-24T04:38:40.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 194,
                    contentId: 43,
                    tagId: 14,
                    createdAt: "2020-02-24T04:38:41.000Z",
                    updatedAt: "2020-02-24T04:38:41.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 213,
                    contentId: 43,
                    tagId: 9,
                    createdAt: "2020-02-25T22:16:28.000Z",
                    updatedAt: "2020-02-25T22:16:28.000Z",
                    tag: {
                        id: 9,
                        tag_id: "kerry",
                        label: "Kerry",
                        createdAt: "2020-02-04T01:25:00.000Z",
                        updatedAt: "2020-02-04T01:25:00.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 14,
                url: "https://www.youtube.com/embed/d-OnENiqwjg",
                thumbnailFileId: 292,
                label: "Draw Kerry from The Wishmas Tree - Speed Draw with Paul ",
                createdAt: "2020-02-05T06:51:42.000Z",
                updatedAt: "2020-02-27T01:44:59.000Z",
                thumbnailFile: {
                    id: 292,
                    size: 80989,
                    path: require("../images/Files/PaulDraws_Kerry.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"8147a39b29e79527dd27025381f59f1a"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-27T01:44:58.000Z",
                    updatedAt: "2020-02-27T01:44:58.000Z",
                },
            },
        },
        {
            id: 44,
            type: "video",
            content_id: 15,
            label: "Drawing Bernard",
            title_copy_id: 46,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-23T03:15:53.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T03:15:53.000Z",
            updatedAt: "2020-02-25T22:16:10.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 189,
                    contentId: 44,
                    tagId: 22,
                    createdAt: "2020-02-24T04:38:21.000Z",
                    updatedAt: "2020-02-24T04:38:21.000Z",
                    tag: {
                        id: 22,
                        tag_id: "pauldraws",
                        label: "Paul Draws",
                        createdAt: "2020-02-24T04:17:42.000Z",
                        updatedAt: "2020-02-24T04:17:42.000Z",
                    },
                },
                {
                    id: 190,
                    contentId: 44,
                    tagId: 2,
                    createdAt: "2020-02-24T04:38:22.000Z",
                    updatedAt: "2020-02-24T04:38:22.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 191,
                    contentId: 44,
                    tagId: 14,
                    createdAt: "2020-02-24T04:38:24.000Z",
                    updatedAt: "2020-02-24T04:38:24.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 211,
                    contentId: 44,
                    tagId: 12,
                    createdAt: "2020-02-25T22:16:13.000Z",
                    updatedAt: "2020-02-25T22:16:13.000Z",
                    tag: {
                        id: 12,
                        tag_id: "bernard",
                        label: "Bernard",
                        createdAt: "2020-02-04T01:25:16.000Z",
                        updatedAt: "2020-02-04T01:25:16.000Z",
                    },
                },
                {
                    id: 212,
                    contentId: 44,
                    tagId: 20,
                    createdAt: "2020-02-25T22:16:15.000Z",
                    updatedAt: "2020-02-25T22:16:15.000Z",
                    tag: {
                        id: 20,
                        tag_id: "dropbears",
                        label: "Dropbears",
                        createdAt: "2020-02-05T22:51:57.000Z",
                        updatedAt: "2020-02-05T22:51:57.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 15,
                url: "https://www.youtube.com/embed/460ldsmoNSM",
                thumbnailFileId: 291,
                label: "Draw Bernard from The Wishmas Tree - Speed Draw with Paul ",
                createdAt: "2020-02-05T06:51:55.000Z",
                updatedAt: "2020-02-27T01:44:31.000Z",
                thumbnailFile: {
                    id: 291,
                    size: 78523,
                    path: require("../images/Files/PaulDraws_Bernard.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"e76074a839314730ba666ba3310937fa"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-27T01:44:29.000Z",
                    updatedAt: "2020-02-27T01:44:29.000Z",
                },
            },
        },
        {
            id: 45,
            type: "video",
            content_id: 16,
            label: "Two Facts and a Fib - Barking Owl",
            title_copy_id: 47,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-23T04:09:35.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T04:09:35.000Z",
            updatedAt: "2020-02-25T22:15:47.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 186,
                    contentId: 45,
                    tagId: 14,
                    createdAt: "2020-02-24T04:38:03.000Z",
                    updatedAt: "2020-02-24T04:38:03.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 187,
                    contentId: 45,
                    tagId: 2,
                    createdAt: "2020-02-24T04:38:04.000Z",
                    updatedAt: "2020-02-24T04:38:04.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 188,
                    contentId: 45,
                    tagId: 25,
                    createdAt: "2020-02-24T04:38:05.000Z",
                    updatedAt: "2020-02-24T04:38:05.000Z",
                    tag: {
                        id: 25,
                        tag_id: "tff",
                        label: "Two Facts and a Fib",
                        createdAt: "2020-02-24T04:23:39.000Z",
                        updatedAt: "2020-02-24T04:23:39.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 16,
                url: "https://www.youtube.com/embed/164JziFnBv4",
                thumbnailFileId: 289,
                label: "Two Facts and a Fib! | Barking Owl | The Wishmas Tree ",
                createdAt: "2020-02-24T03:16:10.000Z",
                updatedAt: "2020-02-27T01:39:02.000Z",
                thumbnailFile: {
                    id: 289,
                    size: 113196,
                    path: require("../images/Files/TFF_Barkingowl.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"7606f9a1a8763e25c6c2ab97f1dbc973"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-27T01:39:01.000Z",
                    updatedAt: "2020-02-27T01:39:01.000Z",
                },
            },
        },
        {
            id: 46,
            type: "video",
            content_id: 17,
            label: "Two Facts and a Fib - Barn Owl",
            title_copy_id: 48,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-03-12T04:16:46.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T04:16:46.000Z",
            updatedAt: "2020-03-20T01:49:37.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 183,
                    contentId: 46,
                    tagId: 25,
                    createdAt: "2020-02-24T04:37:48.000Z",
                    updatedAt: "2020-02-24T04:37:48.000Z",
                    tag: {
                        id: 25,
                        tag_id: "tff",
                        label: "Two Facts and a Fib",
                        createdAt: "2020-02-24T04:23:39.000Z",
                        updatedAt: "2020-02-24T04:23:39.000Z",
                    },
                },
                {
                    id: 184,
                    contentId: 46,
                    tagId: 2,
                    createdAt: "2020-02-24T04:37:50.000Z",
                    updatedAt: "2020-02-24T04:37:50.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 185,
                    contentId: 46,
                    tagId: 14,
                    createdAt: "2020-02-24T04:37:51.000Z",
                    updatedAt: "2020-02-24T04:37:51.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 17,
                url: "https://www.youtube.com/embed/5NtULu56VIE",
                thumbnailFileId: 288,
                label: "Two Facts and a Fib! | Barn Owl | The Wishmas Tree ",
                createdAt: "2020-02-24T03:16:20.000Z",
                updatedAt: "2020-02-27T01:38:32.000Z",
                thumbnailFile: {
                    id: 288,
                    size: 80304,
                    path: require("../images/Files/TFF_Barnowl.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"7babbb9e38a5019bb04e6e709f45d2be"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-27T01:38:31.000Z",
                    updatedAt: "2020-02-27T01:38:31.000Z",
                },
            },
        },
        {
            id: 47,
            type: "video",
            content_id: 18,
            label: "Two Facts and a Fib - Cassowary",
            title_copy_id: 49,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-23T04:16:47.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T04:16:47.000Z",
            updatedAt: "2020-02-25T22:15:23.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 180,
                    contentId: 47,
                    tagId: 14,
                    createdAt: "2020-02-24T04:37:29.000Z",
                    updatedAt: "2020-02-24T04:37:29.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 181,
                    contentId: 47,
                    tagId: 2,
                    createdAt: "2020-02-24T04:37:31.000Z",
                    updatedAt: "2020-02-24T04:37:31.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 182,
                    contentId: 47,
                    tagId: 25,
                    createdAt: "2020-02-24T04:37:32.000Z",
                    updatedAt: "2020-02-24T04:37:32.000Z",
                    tag: {
                        id: 25,
                        tag_id: "tff",
                        label: "Two Facts and a Fib",
                        createdAt: "2020-02-24T04:23:39.000Z",
                        updatedAt: "2020-02-24T04:23:39.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 18,
                url: "https://www.youtube.com/embed/V70ARWYOdzQ",
                thumbnailFileId: 285,
                label: "Two Facts and a Fib! | Cassowary | The Wishmas Tree ",
                createdAt: "2020-02-24T03:16:29.000Z",
                updatedAt: "2020-02-27T01:31:30.000Z",
                thumbnailFile: {
                    id: 285,
                    size: 155266,
                    path: require("../images/Files/TFF_Cassowary.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"1146c21ee4f4ba4243c0b12b6b4fa18d"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-27T01:31:28.000Z",
                    updatedAt: "2020-02-27T01:31:28.000Z",
                },
            },
        },
        {
            id: 48,
            type: "video",
            content_id: 19,
            label: "Two Facts and a Fib - Dingo",
            title_copy_id: 50,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-23T04:16:48.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T04:16:48.000Z",
            updatedAt: "2020-02-25T22:15:14.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 177,
                    contentId: 48,
                    tagId: 14,
                    createdAt: "2020-02-24T04:37:12.000Z",
                    updatedAt: "2020-02-24T04:37:12.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 178,
                    contentId: 48,
                    tagId: 2,
                    createdAt: "2020-02-24T04:37:13.000Z",
                    updatedAt: "2020-02-24T04:37:13.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 179,
                    contentId: 48,
                    tagId: 25,
                    createdAt: "2020-02-24T04:37:14.000Z",
                    updatedAt: "2020-02-24T04:37:14.000Z",
                    tag: {
                        id: 25,
                        tag_id: "tff",
                        label: "Two Facts and a Fib",
                        createdAt: "2020-02-24T04:23:39.000Z",
                        updatedAt: "2020-02-24T04:23:39.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 19,
                url: "https://www.youtube.com/embed/yn_-AP6zXEc",
                thumbnailFileId: 287,
                label: "Two Facts and a Fib! | Dingo | The Wishmas Tree ",
                createdAt: "2020-02-24T03:16:36.000Z",
                updatedAt: "2020-02-27T01:37:51.000Z",
                thumbnailFile: {
                    id: 287,
                    size: 79116,
                    path: require("../images/Files/TFF_Dingo.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"e4e7ad36c01c4cbffba841b1bd5d3f77"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-27T01:37:49.000Z",
                    updatedAt: "2020-02-27T01:37:49.000Z",
                },
            },
        },
        {
            id: 49,
            type: "video",
            content_id: 20,
            label: "Two Facts and a Fib - Eagle",
            title_copy_id: 51,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-23T04:16:50.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T04:16:50.000Z",
            updatedAt: "2020-02-27T02:59:09.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 174,
                    contentId: 49,
                    tagId: 2,
                    createdAt: "2020-02-24T04:36:55.000Z",
                    updatedAt: "2020-02-24T04:36:55.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 175,
                    contentId: 49,
                    tagId: 14,
                    createdAt: "2020-02-24T04:36:56.000Z",
                    updatedAt: "2020-02-24T04:36:56.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 176,
                    contentId: 49,
                    tagId: 25,
                    createdAt: "2020-02-24T04:36:58.000Z",
                    updatedAt: "2020-02-24T04:36:58.000Z",
                    tag: {
                        id: 25,
                        tag_id: "tff",
                        label: "Two Facts and a Fib",
                        createdAt: "2020-02-24T04:23:39.000Z",
                        updatedAt: "2020-02-24T04:23:39.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 20,
                url: "https://www.youtube.com/embed/hZkPqu_kN-o",
                thumbnailFileId: 294,
                label: "Two Facts and a Fib! | Eagle | The Wishmas Tree ",
                createdAt: "2020-02-24T03:16:45.000Z",
                updatedAt: "2020-02-27T03:28:08.000Z",
                thumbnailFile: {
                    id: 294,
                    size: 93988,
                    path: require("../images/Files/TFF_Wedgeeagle.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"74dd133c47137f793d1c6241740c3bc1"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-27T03:28:03.000Z",
                    updatedAt: "2020-02-27T03:28:03.000Z",
                },
            },
        },
        {
            id: 50,
            type: "video",
            content_id: 21,
            label: "Two Facts and a Fib - Emu",
            title_copy_id: 52,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-03-26T04:16:00.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T04:16:51.000Z",
            updatedAt: "2020-03-20T01:50:19.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 171,
                    contentId: 50,
                    tagId: 14,
                    createdAt: "2020-02-24T04:36:37.000Z",
                    updatedAt: "2020-02-24T04:36:37.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 172,
                    contentId: 50,
                    tagId: 2,
                    createdAt: "2020-02-24T04:36:38.000Z",
                    updatedAt: "2020-02-24T04:36:38.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 173,
                    contentId: 50,
                    tagId: 25,
                    createdAt: "2020-02-24T04:36:40.000Z",
                    updatedAt: "2020-02-24T04:36:40.000Z",
                    tag: {
                        id: 25,
                        tag_id: "tff",
                        label: "Two Facts and a Fib",
                        createdAt: "2020-02-24T04:23:39.000Z",
                        updatedAt: "2020-02-24T04:23:39.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 21,
                url: "https://www.youtube.com/embed/CyFPrR-wdp0",
                thumbnailFileId: null,
                label: "Two Facts and a Fib! | Emu | The Wishmas Tree ",
                createdAt: "2020-02-24T03:16:52.000Z",
                updatedAt: "2020-02-24T03:16:52.000Z",
                thumbnailFile: null,
            },
        },
        {
            id: 51,
            type: "video",
            content_id: 22,
            label: "Two Facts and a Fib - Frilled Neck Lizard",
            title_copy_id: 53,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-03-31T04:16:00.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T04:16:53.000Z",
            updatedAt: "2020-03-20T01:50:37.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 168,
                    contentId: 51,
                    tagId: 14,
                    createdAt: "2020-02-24T04:36:21.000Z",
                    updatedAt: "2020-02-24T04:36:21.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 169,
                    contentId: 51,
                    tagId: 2,
                    createdAt: "2020-02-24T04:36:22.000Z",
                    updatedAt: "2020-02-24T04:36:22.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 170,
                    contentId: 51,
                    tagId: 25,
                    createdAt: "2020-02-24T04:36:24.000Z",
                    updatedAt: "2020-02-24T04:36:24.000Z",
                    tag: {
                        id: 25,
                        tag_id: "tff",
                        label: "Two Facts and a Fib",
                        createdAt: "2020-02-24T04:23:39.000Z",
                        updatedAt: "2020-02-24T04:23:39.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 22,
                url: "https://www.youtube.com/embed/1GwNzC0Vfso",
                thumbnailFileId: null,
                label: "Two Facts and a Fib! | Frilled Neck Lizard | The Wishmas Tree ",
                createdAt: "2020-02-24T03:17:03.000Z",
                updatedAt: "2020-02-24T03:17:03.000Z",
                thumbnailFile: null,
            },
        },
        {
            id: 52,
            type: "video",
            content_id: 23,
            label: "Two Facts and a Fib - Kangaroo",
            title_copy_id: 54,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-04-02T04:16:54.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T04:16:54.000Z",
            updatedAt: "2020-03-20T01:51:03.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 165,
                    contentId: 52,
                    tagId: 14,
                    createdAt: "2020-02-24T04:35:50.000Z",
                    updatedAt: "2020-02-24T04:35:50.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 166,
                    contentId: 52,
                    tagId: 2,
                    createdAt: "2020-02-24T04:35:52.000Z",
                    updatedAt: "2020-02-24T04:35:52.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 167,
                    contentId: 52,
                    tagId: 25,
                    createdAt: "2020-02-24T04:36:02.000Z",
                    updatedAt: "2020-02-24T04:36:02.000Z",
                    tag: {
                        id: 25,
                        tag_id: "tff",
                        label: "Two Facts and a Fib",
                        createdAt: "2020-02-24T04:23:39.000Z",
                        updatedAt: "2020-02-24T04:23:39.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 23,
                url: "https://www.youtube.com/embed/hODi2Oponi4",
                thumbnailFileId: null,
                label: "Two Facts and a Fib! | Kangaroo | The Wishmas Tree ",
                createdAt: "2020-02-24T03:17:12.000Z",
                updatedAt: "2020-02-24T03:17:12.000Z",
                thumbnailFile: null,
            },
        },
        {
            id: 53,
            type: "video",
            content_id: 24,
            label: "Two Facts and a Fib - Koala",
            title_copy_id: 55,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-04-07T04:16:55.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T04:16:55.000Z",
            updatedAt: "2020-03-20T01:51:25.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 162,
                    contentId: 53,
                    tagId: 25,
                    createdAt: "2020-02-24T04:35:34.000Z",
                    updatedAt: "2020-02-24T04:35:34.000Z",
                    tag: {
                        id: 25,
                        tag_id: "tff",
                        label: "Two Facts and a Fib",
                        createdAt: "2020-02-24T04:23:39.000Z",
                        updatedAt: "2020-02-24T04:23:39.000Z",
                    },
                },
                {
                    id: 163,
                    contentId: 53,
                    tagId: 2,
                    createdAt: "2020-02-24T04:35:37.000Z",
                    updatedAt: "2020-02-24T04:35:37.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 164,
                    contentId: 53,
                    tagId: 14,
                    createdAt: "2020-02-24T04:35:39.000Z",
                    updatedAt: "2020-02-24T04:35:39.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 24,
                url: "https://www.youtube.com/embed/Edfwz82_wcE",
                thumbnailFileId: null,
                label: "Two Facts and a Fib! | Koala | The Wishmas Tree ",
                createdAt: "2020-02-24T03:17:21.000Z",
                updatedAt: "2020-02-24T03:17:21.000Z",
                thumbnailFile: null,
            },
        },
        {
            id: 54,
            type: "video",
            content_id: 25,
            label: "Two Facts and a Fib - Wallaby",
            title_copy_id: 56,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-04-09T04:16:00.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T04:16:57.000Z",
            updatedAt: "2020-03-20T01:51:45.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 159,
                    contentId: 54,
                    tagId: 14,
                    createdAt: "2020-02-24T04:35:22.000Z",
                    updatedAt: "2020-02-24T04:35:22.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 160,
                    contentId: 54,
                    tagId: 25,
                    createdAt: "2020-02-24T04:35:23.000Z",
                    updatedAt: "2020-02-24T04:35:23.000Z",
                    tag: {
                        id: 25,
                        tag_id: "tff",
                        label: "Two Facts and a Fib",
                        createdAt: "2020-02-24T04:23:39.000Z",
                        updatedAt: "2020-02-24T04:23:39.000Z",
                    },
                },
                {
                    id: 161,
                    contentId: 54,
                    tagId: 2,
                    createdAt: "2020-02-24T04:35:26.000Z",
                    updatedAt: "2020-02-24T04:35:26.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 25,
                url: "https://www.youtube.com/embed/lN_NkTcvPs8",
                thumbnailFileId: null,
                label: "Two Facts and a Fib! | Wallaby | The Wishmas Tree ",
                createdAt: "2020-02-24T03:17:29.000Z",
                updatedAt: "2020-02-24T03:17:29.000Z",
                thumbnailFile: null,
            },
        },
        {
            id: 56,
            type: "video",
            content_id: 27,
            label: "Two Facts and a Fib - Wombat",
            title_copy_id: 58,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-04-16T04:16:00.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T04:17:00.000Z",
            updatedAt: "2020-03-20T01:52:03.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 153,
                    contentId: 56,
                    tagId: 25,
                    createdAt: "2020-02-24T04:34:31.000Z",
                    updatedAt: "2020-02-24T04:34:31.000Z",
                    tag: {
                        id: 25,
                        tag_id: "tff",
                        label: "Two Facts and a Fib",
                        createdAt: "2020-02-24T04:23:39.000Z",
                        updatedAt: "2020-02-24T04:23:39.000Z",
                    },
                },
                {
                    id: 154,
                    contentId: 56,
                    tagId: 2,
                    createdAt: "2020-02-24T04:34:33.000Z",
                    updatedAt: "2020-02-24T04:34:33.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 155,
                    contentId: 56,
                    tagId: 14,
                    createdAt: "2020-02-24T04:34:35.000Z",
                    updatedAt: "2020-02-24T04:34:35.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 27,
                url: "https://www.youtube.com/embed/cfqXLSpMPl4",
                thumbnailFileId: null,
                label: "Two Facts and a Fib! | Wombat | The Wishmas Tree ",
                createdAt: "2020-02-24T03:17:43.000Z",
                updatedAt: "2020-02-24T03:17:43.000Z",
                thumbnailFile: null,
            },
        },
        {
            id: 57,
            type: "video",
            content_id: 28,
            label: "Behind the Scenes - Dropbears",
            title_copy_id: 59,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-23T04:17:01.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T04:17:01.000Z",
            updatedAt: "2020-02-26T07:24:25.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 150,
                    contentId: 57,
                    tagId: 24,
                    createdAt: "2020-02-24T04:34:11.000Z",
                    updatedAt: "2020-02-24T04:34:11.000Z",
                    tag: {
                        id: 24,
                        tag_id: "bts",
                        label: "Behind the Scenes",
                        createdAt: "2020-02-24T04:23:23.000Z",
                        updatedAt: "2020-02-24T04:23:23.000Z",
                    },
                },
                {
                    id: 151,
                    contentId: 57,
                    tagId: 14,
                    createdAt: "2020-02-24T04:34:14.000Z",
                    updatedAt: "2020-02-24T04:34:14.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 152,
                    contentId: 57,
                    tagId: 2,
                    createdAt: "2020-02-24T04:34:15.000Z",
                    updatedAt: "2020-02-24T04:34:15.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 210,
                    contentId: 57,
                    tagId: 20,
                    createdAt: "2020-02-25T22:13:56.000Z",
                    updatedAt: "2020-02-25T22:13:56.000Z",
                    tag: {
                        id: 20,
                        tag_id: "dropbears",
                        label: "Dropbears",
                        createdAt: "2020-02-05T22:51:57.000Z",
                        updatedAt: "2020-02-05T22:51:57.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 28,
                url: "https://www.youtube.com/embed/lredGH2B33Q",
                thumbnailFileId: 286,
                label: "Behind the Scenes: Creating the Dropbears in The Wishmas Tree",
                createdAt: "2020-02-24T03:20:18.000Z",
                updatedAt: "2020-02-27T01:32:05.000Z",
                thumbnailFile: {
                    id: 286,
                    size: 53563,
                    path: require("../images/Files/BTS_Dropbears.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"5dc84d2d49409eab3df2f4d8fc616b4d"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-27T01:32:04.000Z",
                    updatedAt: "2020-02-27T01:32:04.000Z",
                },
            },
        },
        {
            id: 59,
            type: "video",
            content_id: 30,
            label: "Behind the Scenes - Swamplands",
            title_copy_id: 61,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-19T04:17:05.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T04:17:05.000Z",
            updatedAt: "2020-03-20T01:57:42.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 144,
                    contentId: 59,
                    tagId: 14,
                    createdAt: "2020-02-24T04:33:35.000Z",
                    updatedAt: "2020-02-24T04:33:35.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 145,
                    contentId: 59,
                    tagId: 2,
                    createdAt: "2020-02-24T04:33:37.000Z",
                    updatedAt: "2020-02-24T04:33:37.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 146,
                    contentId: 59,
                    tagId: 24,
                    createdAt: "2020-02-24T04:33:39.000Z",
                    updatedAt: "2020-02-24T04:33:39.000Z",
                    tag: {
                        id: 24,
                        tag_id: "bts",
                        label: "Behind the Scenes",
                        createdAt: "2020-02-24T04:23:23.000Z",
                        updatedAt: "2020-02-24T04:23:23.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 30,
                url: "https://www.youtube.com/embed/81EwbGjmyZw",
                thumbnailFileId: null,
                label: "Behind the Scenes: Secrets of the Swamplands in The Wishmas Tree",
                createdAt: "2020-02-24T03:20:34.000Z",
                updatedAt: "2020-02-24T03:20:34.000Z",
                thumbnailFile: null,
            },
        },
        {
            id: 60,
            type: "video",
            content_id: 31,
            label: "Behind the Scenes - Yarra",
            title_copy_id: 62,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-12T04:17:06.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T04:17:06.000Z",
            updatedAt: "2020-03-20T01:58:03.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 141,
                    contentId: 60,
                    tagId: 14,
                    createdAt: "2020-02-24T04:33:15.000Z",
                    updatedAt: "2020-02-24T04:33:15.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 142,
                    contentId: 60,
                    tagId: 24,
                    createdAt: "2020-02-24T04:33:17.000Z",
                    updatedAt: "2020-02-24T04:33:17.000Z",
                    tag: {
                        id: 24,
                        tag_id: "bts",
                        label: "Behind the Scenes",
                        createdAt: "2020-02-24T04:23:23.000Z",
                        updatedAt: "2020-02-24T04:23:23.000Z",
                    },
                },
                {
                    id: 143,
                    contentId: 60,
                    tagId: 2,
                    createdAt: "2020-02-24T04:33:18.000Z",
                    updatedAt: "2020-02-24T04:33:18.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 209,
                    contentId: 60,
                    tagId: 11,
                    createdAt: "2020-02-25T22:13:35.000Z",
                    updatedAt: "2020-02-25T22:13:35.000Z",
                    tag: {
                        id: 11,
                        tag_id: "yarra",
                        label: "Yarra",
                        createdAt: "2020-02-04T01:25:09.000Z",
                        updatedAt: "2020-02-04T01:25:09.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 31,
                url: "https://www.youtube.com/embed/TSdFre2f0zc",
                thumbnailFileId: null,
                label: "Behind the Scenes: Yarra the Kooky Lizard in The Wishmas Tree",
                createdAt: "2020-02-24T03:22:56.000Z",
                updatedAt: "2020-02-24T03:22:56.000Z",
                thumbnailFile: null,
            },
        },
        {
            id: 61,
            type: "video",
            content_id: 32,
            label: "Behind the Scenes - Writing",
            title_copy_id: 63,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-23T04:17:08.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T04:17:08.000Z",
            updatedAt: "2020-02-25T22:12:50.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 138,
                    contentId: 61,
                    tagId: 14,
                    createdAt: "2020-02-24T04:32:57.000Z",
                    updatedAt: "2020-02-24T04:32:57.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 139,
                    contentId: 61,
                    tagId: 24,
                    createdAt: "2020-02-24T04:32:59.000Z",
                    updatedAt: "2020-02-24T04:32:59.000Z",
                    tag: {
                        id: 24,
                        tag_id: "bts",
                        label: "Behind the Scenes",
                        createdAt: "2020-02-24T04:23:23.000Z",
                        updatedAt: "2020-02-24T04:23:23.000Z",
                    },
                },
                {
                    id: 140,
                    contentId: 61,
                    tagId: 2,
                    createdAt: "2020-02-24T04:33:01.000Z",
                    updatedAt: "2020-02-24T04:33:01.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 32,
                url: "https://www.youtube.com/embed/9dz5cfhmiL8",
                thumbnailFileId: 283,
                label: "Behind the Scenes of The Wishmas Tree | Writing",
                createdAt: "2020-02-24T03:23:08.000Z",
                updatedAt: "2020-02-27T01:08:47.000Z",
                thumbnailFile: {
                    id: 283,
                    size: 70957,
                    path: require("../images/Files/BTS_Writing.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"1623a722740ee73c9faedf2368fd5f64"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-27T01:02:32.000Z",
                    updatedAt: "2020-02-27T01:02:32.000Z",
                },
            },
        },
        {
            id: 62,
            type: "video",
            content_id: 33,
            label: "Behind the Scenes - Storyboarding",
            title_copy_id: 64,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-23T04:17:09.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T04:17:09.000Z",
            updatedAt: "2020-02-25T22:12:43.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 135,
                    contentId: 62,
                    tagId: 24,
                    createdAt: "2020-02-24T04:32:38.000Z",
                    updatedAt: "2020-02-24T04:32:38.000Z",
                    tag: {
                        id: 24,
                        tag_id: "bts",
                        label: "Behind the Scenes",
                        createdAt: "2020-02-24T04:23:23.000Z",
                        updatedAt: "2020-02-24T04:23:23.000Z",
                    },
                },
                {
                    id: 136,
                    contentId: 62,
                    tagId: 14,
                    createdAt: "2020-02-24T04:32:39.000Z",
                    updatedAt: "2020-02-24T04:32:39.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 137,
                    contentId: 62,
                    tagId: 2,
                    createdAt: "2020-02-24T04:32:40.000Z",
                    updatedAt: "2020-02-24T04:32:40.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 33,
                url: "https://www.youtube.com/embed/Z4NMTWZ5Rqc",
                thumbnailFileId: 284,
                label: "Behind the Scenes of The Wishmas Tree | Storyboarding",
                createdAt: "2020-02-24T03:23:15.000Z",
                updatedAt: "2020-02-27T01:12:26.000Z",
                thumbnailFile: {
                    id: 284,
                    size: 111858,
                    path: require("../images/Files/BTS_Storyboarding.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"e0a7380d398447872639790d99c97255"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-27T01:11:28.000Z",
                    updatedAt: "2020-02-27T01:11:28.000Z",
                },
            },
        },
        {
            id: 63,
            type: "video",
            content_id: 34,
            label: "Behind the Scenes - Concept Art ",
            title_copy_id: 65,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-10T04:17:10.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T04:17:10.000Z",
            updatedAt: "2020-03-20T01:58:18.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 132,
                    contentId: 63,
                    tagId: 24,
                    createdAt: "2020-02-24T04:32:04.000Z",
                    updatedAt: "2020-02-24T04:32:04.000Z",
                    tag: {
                        id: 24,
                        tag_id: "bts",
                        label: "Behind the Scenes",
                        createdAt: "2020-02-24T04:23:23.000Z",
                        updatedAt: "2020-02-24T04:23:23.000Z",
                    },
                },
                {
                    id: 133,
                    contentId: 63,
                    tagId: 2,
                    createdAt: "2020-02-24T04:32:07.000Z",
                    updatedAt: "2020-02-24T04:32:07.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 134,
                    contentId: 63,
                    tagId: 14,
                    createdAt: "2020-02-24T04:32:08.000Z",
                    updatedAt: "2020-02-24T04:32:08.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 34,
                url: "https://www.youtube.com/embed/rf-YaRdMMc8",
                thumbnailFileId: null,
                label: "Behind the Scenes of The Wishmas Tree | Concept Art ",
                createdAt: "2020-02-24T03:23:23.000Z",
                updatedAt: "2020-02-24T03:23:23.000Z",
                thumbnailFile: null,
            },
        },
        {
            id: 64,
            type: "video",
            content_id: 35,
            label: "Behind the Scenes - Rigging Characters ",
            title_copy_id: 66,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-17T04:17:12.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T04:17:12.000Z",
            updatedAt: "2020-03-20T01:58:30.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 126,
                    contentId: 64,
                    tagId: 24,
                    createdAt: "2020-02-24T04:24:28.000Z",
                    updatedAt: "2020-02-24T04:24:28.000Z",
                    tag: {
                        id: 24,
                        tag_id: "bts",
                        label: "Behind the Scenes",
                        createdAt: "2020-02-24T04:23:23.000Z",
                        updatedAt: "2020-02-24T04:23:23.000Z",
                    },
                },
                {
                    id: 127,
                    contentId: 64,
                    tagId: 14,
                    createdAt: "2020-02-24T04:24:30.000Z",
                    updatedAt: "2020-02-24T04:24:30.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 128,
                    contentId: 64,
                    tagId: 2,
                    createdAt: "2020-02-24T04:24:31.000Z",
                    updatedAt: "2020-02-24T04:24:31.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 35,
                url: "https://www.youtube.com/embed/cj9hnbhYKDg",
                thumbnailFileId: null,
                label: "Behind the Scenes of The Wishmas Tree | Rigging Characters ",
                createdAt: "2020-02-24T03:23:32.000Z",
                updatedAt: "2020-02-24T03:23:32.000Z",
                thumbnailFile: null,
            },
        },
        {
            id: 65,
            type: "video",
            content_id: 36,
            label: "Behind the Scenes - Animation",
            title_copy_id: 67,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-03-24T04:17:13.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T04:17:13.000Z",
            updatedAt: "2020-03-20T01:58:50.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 123,
                    contentId: 65,
                    tagId: 24,
                    createdAt: "2020-02-24T04:24:16.000Z",
                    updatedAt: "2020-02-24T04:24:16.000Z",
                    tag: {
                        id: 24,
                        tag_id: "bts",
                        label: "Behind the Scenes",
                        createdAt: "2020-02-24T04:23:23.000Z",
                        updatedAt: "2020-02-24T04:23:23.000Z",
                    },
                },
                {
                    id: 124,
                    contentId: 65,
                    tagId: 2,
                    createdAt: "2020-02-24T04:24:17.000Z",
                    updatedAt: "2020-02-24T04:24:17.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 125,
                    contentId: 65,
                    tagId: 14,
                    createdAt: "2020-02-24T04:24:19.000Z",
                    updatedAt: "2020-02-24T04:24:19.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 36,
                url: "https://www.youtube.com/embed/ytA4K_YpIGE",
                thumbnailFileId: null,
                label: "Behind the Scenes of The Wishmas Tree | Animation",
                createdAt: "2020-02-24T03:23:39.000Z",
                updatedAt: "2020-02-24T03:23:39.000Z",
                thumbnailFile: null,
            },
        },
        {
            id: 66,
            type: "video",
            content_id: 37,
            label: "Behind the Scenes - 3D Lighting ",
            title_copy_id: 68,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-03-30T14:00:00.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T04:17:15.000Z",
            updatedAt: "2020-03-20T01:59:11.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 120,
                    contentId: 66,
                    tagId: 24,
                    createdAt: "2020-02-24T04:23:57.000Z",
                    updatedAt: "2020-02-24T04:23:57.000Z",
                    tag: {
                        id: 24,
                        tag_id: "bts",
                        label: "Behind the Scenes",
                        createdAt: "2020-02-24T04:23:23.000Z",
                        updatedAt: "2020-02-24T04:23:23.000Z",
                    },
                },
                {
                    id: 121,
                    contentId: 66,
                    tagId: 2,
                    createdAt: "2020-02-24T04:23:58.000Z",
                    updatedAt: "2020-02-24T04:23:58.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 122,
                    contentId: 66,
                    tagId: 14,
                    createdAt: "2020-02-24T04:24:01.000Z",
                    updatedAt: "2020-02-24T04:24:01.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 37,
                url: "https://www.youtube.com/embed/O-vj5TTgmyM",
                thumbnailFileId: null,
                label: "Behind the Scenes of The Wishmas Tree | 3D Lighting ",
                createdAt: "2020-02-24T03:23:46.000Z",
                updatedAt: "2020-02-24T03:23:46.000Z",
                thumbnailFile: null,
            },
        },
        {
            id: 67,
            type: "video",
            content_id: 38,
            label: "Kids Create a Creature with an Animator",
            title_copy_id: 69,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-23T04:17:17.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T04:17:17.000Z",
            updatedAt: "2020-02-27T03:17:45.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 117,
                    contentId: 67,
                    tagId: 23,
                    createdAt: "2020-02-24T04:22:40.000Z",
                    updatedAt: "2020-02-24T04:22:40.000Z",
                    tag: {
                        id: 23,
                        tag_id: "taniadraws",
                        label: "Tania Draws",
                        createdAt: "2020-02-24T04:17:48.000Z",
                        updatedAt: "2020-02-24T04:17:48.000Z",
                    },
                },
                {
                    id: 118,
                    contentId: 67,
                    tagId: 2,
                    createdAt: "2020-02-24T04:22:42.000Z",
                    updatedAt: "2020-02-24T04:22:42.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 119,
                    contentId: 67,
                    tagId: 14,
                    createdAt: "2020-02-24T04:22:44.000Z",
                    updatedAt: "2020-02-24T04:22:44.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 38,
                url: "https://www.youtube.com/embed/hnbkewL3cgM",
                thumbnailFileId: 295,
                label: "Kids Create a Creature with an Animator | Animator Tania",
                createdAt: "2020-02-24T03:24:02.000Z",
                updatedAt: "2020-02-27T03:28:39.000Z",
                thumbnailFile: {
                    id: 295,
                    size: 65085,
                    path: require("../images/Files/Tania_Combineanimals.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"274d40edc3a53fb4740237bc4c67da87"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-27T03:28:37.000Z",
                    updatedAt: "2020-02-27T03:28:37.000Z",
                },
            },
        },
        {
            id: 68,
            type: "video",
            content_id: 39,
            label: "Kids Create Their Parents as an Animal!",
            title_copy_id: 70,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-23T04:17:18.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T04:17:18.000Z",
            updatedAt: "2020-02-27T03:17:56.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 114,
                    contentId: 68,
                    tagId: 23,
                    createdAt: "2020-02-24T04:22:21.000Z",
                    updatedAt: "2020-02-24T04:22:21.000Z",
                    tag: {
                        id: 23,
                        tag_id: "taniadraws",
                        label: "Tania Draws",
                        createdAt: "2020-02-24T04:17:48.000Z",
                        updatedAt: "2020-02-24T04:17:48.000Z",
                    },
                },
                {
                    id: 115,
                    contentId: 68,
                    tagId: 2,
                    createdAt: "2020-02-24T04:22:23.000Z",
                    updatedAt: "2020-02-24T04:22:23.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 116,
                    contentId: 68,
                    tagId: 14,
                    createdAt: "2020-02-24T04:22:26.000Z",
                    updatedAt: "2020-02-24T04:22:26.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 39,
                url: "https://www.youtube.com/embed/vqFaG5kxj7o",
                thumbnailFileId: 296,
                label: "Kids Create Their Parents as an Animal! | Animator Tania",
                createdAt: "2020-02-24T03:24:09.000Z",
                updatedAt: "2020-02-27T03:31:40.000Z",
                thumbnailFile: {
                    id: 296,
                    size: 65081,
                    path: require("../images/Files/Tania_Parents.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"9b4aeff41c4cbb5635c900e92abed231"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-27T03:30:26.000Z",
                    updatedAt: "2020-02-27T03:30:26.000Z",
                },
            },
        },
        {
            id: 69,
            type: "video",
            content_id: 40,
            label: "Kids Make a Scary Animal Friendly!",
            title_copy_id: 71,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-04-02T14:00:00.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T04:17:20.000Z",
            updatedAt: "2020-03-20T02:00:16.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 111,
                    contentId: 69,
                    tagId: 23,
                    createdAt: "2020-02-24T04:22:00.000Z",
                    updatedAt: "2020-02-24T04:22:00.000Z",
                    tag: {
                        id: 23,
                        tag_id: "taniadraws",
                        label: "Tania Draws",
                        createdAt: "2020-02-24T04:17:48.000Z",
                        updatedAt: "2020-02-24T04:17:48.000Z",
                    },
                },
                {
                    id: 112,
                    contentId: 69,
                    tagId: 14,
                    createdAt: "2020-02-24T04:22:02.000Z",
                    updatedAt: "2020-02-24T04:22:02.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 113,
                    contentId: 69,
                    tagId: 2,
                    createdAt: "2020-02-24T04:22:04.000Z",
                    updatedAt: "2020-02-24T04:22:04.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 40,
                url: "https://www.youtube.com/embed/t0cmXWmscio",
                thumbnailFileId: null,
                label: "Kids Make a Scary Animal Friendly! | Animator Tania",
                createdAt: "2020-02-24T03:24:15.000Z",
                updatedAt: "2020-02-24T03:24:15.000Z",
                thumbnailFile: null,
            },
        },
        {
            id: 70,
            type: "video",
            content_id: 41,
            label: "Kids describe their ULTIMATE treehouse!",
            title_copy_id: 72,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-03-26T14:00:00.000Z",
            forceLarge: false,
            createdAt: "2020-02-24T04:17:22.000Z",
            updatedAt: "2020-03-20T02:00:03.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 108,
                    contentId: 70,
                    tagId: 23,
                    createdAt: "2020-02-24T04:21:48.000Z",
                    updatedAt: "2020-02-24T04:21:48.000Z",
                    tag: {
                        id: 23,
                        tag_id: "taniadraws",
                        label: "Tania Draws",
                        createdAt: "2020-02-24T04:17:48.000Z",
                        updatedAt: "2020-02-24T04:17:48.000Z",
                    },
                },
                {
                    id: 109,
                    contentId: 70,
                    tagId: 2,
                    createdAt: "2020-02-24T04:21:50.000Z",
                    updatedAt: "2020-02-24T04:21:50.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
                {
                    id: 110,
                    contentId: 70,
                    tagId: 14,
                    createdAt: "2020-02-24T04:21:52.000Z",
                    updatedAt: "2020-02-24T04:21:52.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 41,
                url: "https://www.youtube.com/embed/r-r3EFngb1g",
                thumbnailFileId: null,
                label: "Kids describe their ULTIMATE treehouse! | Animator Tania",
                createdAt: "2020-02-24T03:24:22.000Z",
                updatedAt: "2020-02-24T03:24:22.000Z",
                thumbnailFile: null,
            },
        },
        {
            id: 71,
            type: "imageGallery",
            content_id: 6,
            label: "Gallery - Yarra",
            title_copy_id: 73,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-25T04:49:30.000Z",
            forceLarge: false,
            createdAt: "2020-02-26T04:49:30.000Z",
            updatedAt: "2020-02-26T04:50:37.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 216,
                    contentId: 71,
                    tagId: 26,
                    createdAt: "2020-02-26T04:50:25.000Z",
                    updatedAt: "2020-02-26T04:50:25.000Z",
                    tag: {
                        id: 26,
                        tag_id: "gallery",
                        label: "Gallery",
                        createdAt: "2020-02-26T04:50:05.000Z",
                        updatedAt: "2020-02-26T04:50:05.000Z",
                    },
                },
                {
                    id: 217,
                    contentId: 71,
                    tagId: 11,
                    createdAt: "2020-02-26T04:50:27.000Z",
                    updatedAt: "2020-02-26T04:50:27.000Z",
                    tag: {
                        id: 11,
                        tag_id: "yarra",
                        label: "Yarra",
                        createdAt: "2020-02-04T01:25:09.000Z",
                        updatedAt: "2020-02-04T01:25:09.000Z",
                    },
                },
                {
                    id: 218,
                    contentId: 71,
                    tagId: 15,
                    createdAt: "2020-02-26T04:50:31.000Z",
                    updatedAt: "2020-02-26T04:50:31.000Z",
                    tag: {
                        id: 15,
                        tag_id: "stick",
                        label: "Stick",
                        createdAt: "2020-02-04T01:26:00.000Z",
                        updatedAt: "2020-02-04T01:26:00.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 6,
                label: "Yarra",
                createdAt: "2020-02-26T04:49:02.000Z",
                updatedAt: "2020-02-26T04:49:02.000Z",
                imageGalleryImages: [
                    {
                        id: 25,
                        imageGalleryId: 6,
                        imageId: 43,
                        createdAt: "2020-02-26T04:49:07.000Z",
                        updatedAt: "2020-02-26T04:49:07.000Z",
                        image: {
                            id: 43,
                            url: null,
                            fileId: 258,
                            thumbnailFileId: 259,
                            label: "gallery_yarra_1",
                            createdAt: "2020-02-26T04:35:08.000Z",
                            updatedAt: "2020-02-26T04:46:16.000Z",
                            thumbnailFile: {
                                id: 259,
                                size: 49329,
                                path: require("../images/Files/gallery_yarra_1_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"28962a77e6bf6a62a77be4dd61150977"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:46:15.000Z",
                                updatedAt: "2020-02-26T04:46:15.000Z",
                            },
                            file: {
                                id: 258,
                                size: 244694,
                                path: require("../images/Files/gallery_yarra_1.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"3dc9604a9f6edb6e0a8fe23194cacca5"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:46:12.000Z",
                                updatedAt: "2020-02-26T04:46:12.000Z",
                            },
                        },
                    },
                    {
                        id: 26,
                        imageGalleryId: 6,
                        imageId: 44,
                        createdAt: "2020-02-26T04:49:12.000Z",
                        updatedAt: "2020-02-26T04:49:12.000Z",
                        image: {
                            id: 44,
                            url: null,
                            fileId: 260,
                            thumbnailFileId: 261,
                            label: "gallery_yarra_2",
                            createdAt: "2020-02-26T04:35:10.000Z",
                            updatedAt: "2020-02-26T04:46:26.000Z",
                            thumbnailFile: {
                                id: 261,
                                size: 34313,
                                path: require("../images/Files/gallery_yarra_2_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"52750d96ee364759ae551bca7f7c7a1d"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:46:25.000Z",
                                updatedAt: "2020-02-26T04:46:25.000Z",
                            },
                            file: {
                                id: 260,
                                size: 147456,
                                path: require("../images/Files/gallery_yarra_2.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"ae7c0a5db9f5fd65681cc2b3cbdf535a"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:46:22.000Z",
                                updatedAt: "2020-02-26T04:46:22.000Z",
                            },
                        },
                    },
                    {
                        id: 27,
                        imageGalleryId: 6,
                        imageId: 45,
                        createdAt: "2020-02-26T04:49:15.000Z",
                        updatedAt: "2020-02-26T04:49:15.000Z",
                        image: {
                            id: 45,
                            url: null,
                            fileId: 262,
                            thumbnailFileId: 263,
                            label: "gallery_yarra_3",
                            createdAt: "2020-02-26T04:35:13.000Z",
                            updatedAt: "2020-02-26T04:46:42.000Z",
                            thumbnailFile: {
                                id: 263,
                                size: 42054,
                                path: require("../images/Files/gallery_yarra_3_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"12a13bfa4f11d2b1a28fa3e78c9674b9"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:46:40.000Z",
                                updatedAt: "2020-02-26T04:46:40.000Z",
                            },
                            file: {
                                id: 262,
                                size: 178469,
                                path: require("../images/Files/gallery_yarra_3.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"a78ab6f44c9c700643efd1eda5967c1a"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:46:38.000Z",
                                updatedAt: "2020-02-26T04:46:38.000Z",
                            },
                        },
                    },
                    {
                        id: 28,
                        imageGalleryId: 6,
                        imageId: 46,
                        createdAt: "2020-02-26T04:49:19.000Z",
                        updatedAt: "2020-02-26T04:49:19.000Z",
                        image: {
                            id: 46,
                            url: null,
                            fileId: 265,
                            thumbnailFileId: 266,
                            label: "gallery_yarra_4",
                            createdAt: "2020-02-26T04:35:15.000Z",
                            updatedAt: "2020-02-26T04:47:08.000Z",
                            thumbnailFile: {
                                id: 266,
                                size: 30404,
                                path: require("../images/Files/gallery_yarra_4_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"d40d95c437e40e130395a78c52236dee"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:47:07.000Z",
                                updatedAt: "2020-02-26T04:47:07.000Z",
                            },
                            file: {
                                id: 265,
                                size: 134425,
                                path: require("../images/Files/gallery_yarra_4.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"1b06768382503030ac15bafbbb9829e9"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:47:04.000Z",
                                updatedAt: "2020-02-26T04:47:04.000Z",
                            },
                        },
                    },
                    {
                        id: 29,
                        imageGalleryId: 6,
                        imageId: 47,
                        createdAt: "2020-02-26T04:49:23.000Z",
                        updatedAt: "2020-02-26T04:49:23.000Z",
                        image: {
                            id: 47,
                            url: null,
                            fileId: 268,
                            thumbnailFileId: 267,
                            label: "gallery_yarra_5",
                            createdAt: "2020-02-26T04:35:18.000Z",
                            updatedAt: "2020-02-26T04:47:22.000Z",
                            thumbnailFile: {
                                id: 267,
                                size: 35177,
                                path: require("../images/Files/gallery_yarra_5_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"5a9f860252b0272ac0e1b7b48378906e"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:47:18.000Z",
                                updatedAt: "2020-02-26T04:47:18.000Z",
                            },
                            file: {
                                id: 268,
                                size: 191321,
                                path: require("../images/Files/gallery_yarra_5.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"7cc3aa2aff61932a2e28b29a292c0b2d"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:47:21.000Z",
                                updatedAt: "2020-02-26T04:47:21.000Z",
                            },
                        },
                    },
                    {
                        id: 30,
                        imageGalleryId: 6,
                        imageId: 48,
                        createdAt: "2020-02-26T04:49:26.000Z",
                        updatedAt: "2020-02-26T04:49:26.000Z",
                        image: {
                            id: 48,
                            url: null,
                            fileId: 270,
                            thumbnailFileId: 269,
                            label: "gallery_yarra_6",
                            createdAt: "2020-02-26T04:35:21.000Z",
                            updatedAt: "2020-02-26T04:47:32.000Z",
                            thumbnailFile: {
                                id: 269,
                                size: 28581,
                                path: require("../images/Files/gallery_yarra_6_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"7675a9c726c573c1b4fff3d2f99b9fe5"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:47:28.000Z",
                                updatedAt: "2020-02-26T04:47:28.000Z",
                            },
                            file: {
                                id: 270,
                                size: 123491,
                                path: require("../images/Files/gallery_yarra_6.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"e4eb1c929b99e1820a395671cdabdcd1"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:47:31.000Z",
                                updatedAt: "2020-02-26T04:47:31.000Z",
                            },
                        },
                    },
                ],
            },
        },
        {
            id: 72,
            type: "imageGallery",
            content_id: 5,
            label: "Gallery - West Street",
            title_copy_id: 74,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-25T04:51:17.000Z",
            forceLarge: false,
            createdAt: "2020-02-26T04:51:17.000Z",
            updatedAt: "2020-02-26T04:51:25.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 219,
                    contentId: 72,
                    tagId: 26,
                    createdAt: "2020-02-26T04:51:28.000Z",
                    updatedAt: "2020-02-26T04:51:28.000Z",
                    tag: {
                        id: 26,
                        tag_id: "gallery",
                        label: "Gallery",
                        createdAt: "2020-02-26T04:50:05.000Z",
                        updatedAt: "2020-02-26T04:50:05.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 5,
                label: "Gallery - West Street",
                createdAt: "2020-02-26T04:48:57.000Z",
                updatedAt: "2020-02-26T04:51:15.000Z",
                imageGalleryImages: [
                    {
                        id: 31,
                        imageGalleryId: 5,
                        imageId: 37,
                        createdAt: "2020-02-26T04:50:50.000Z",
                        updatedAt: "2020-02-26T04:50:50.000Z",
                        image: {
                            id: 37,
                            url: null,
                            fileId: 246,
                            thumbnailFileId: 247,
                            label: "gallery_west_street_1",
                            createdAt: "2020-02-26T04:34:45.000Z",
                            updatedAt: "2020-02-26T04:45:00.000Z",
                            thumbnailFile: {
                                id: 247,
                                size: 34182,
                                path: require("../images/Files/gallery_west_street_1_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"1a7ac9476b3567175ff6975a8a99cd0e"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:44:59.000Z",
                                updatedAt: "2020-02-26T04:44:59.000Z",
                            },
                            file: {
                                id: 246,
                                size: 243470,
                                path: require("../images/Files/gallery_west_street_1.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"3c15f4a31eba09839e65408801ccfb29"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:44:57.000Z",
                                updatedAt: "2020-02-26T04:44:57.000Z",
                            },
                        },
                    },
                    {
                        id: 32,
                        imageGalleryId: 5,
                        imageId: 38,
                        createdAt: "2020-02-26T04:50:53.000Z",
                        updatedAt: "2020-02-26T04:50:53.000Z",
                        image: {
                            id: 38,
                            url: null,
                            fileId: 248,
                            thumbnailFileId: 249,
                            label: "gallery_west_street_2",
                            createdAt: "2020-02-26T04:34:48.000Z",
                            updatedAt: "2020-02-26T04:45:12.000Z",
                            thumbnailFile: {
                                id: 249,
                                size: 45538,
                                path: require("../images/Files/gallery_west_street_2_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"1e78ce3dcccd30fc32fff1f3fe81a764"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:45:11.000Z",
                                updatedAt: "2020-02-26T04:45:11.000Z",
                            },
                            file: {
                                id: 248,
                                size: 307525,
                                path: require("../images/Files/gallery_west_street_2.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"1ca2c1de63edad53ead377c76368df05"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:45:08.000Z",
                                updatedAt: "2020-02-26T04:45:08.000Z",
                            },
                        },
                    },
                    {
                        id: 33,
                        imageGalleryId: 5,
                        imageId: 39,
                        createdAt: "2020-02-26T04:50:57.000Z",
                        updatedAt: "2020-02-26T04:50:57.000Z",
                        image: {
                            id: 39,
                            url: null,
                            fileId: 250,
                            thumbnailFileId: 251,
                            label: "gallery_west_street_3",
                            createdAt: "2020-02-26T04:34:50.000Z",
                            updatedAt: "2020-02-26T04:45:22.000Z",
                            thumbnailFile: {
                                id: 251,
                                size: 40316,
                                path: require("../images/Files/gallery_west_street_3_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"b97ad5aa4510361f90d6e6b2a7cc15e1"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:45:21.000Z",
                                updatedAt: "2020-02-26T04:45:21.000Z",
                            },
                            file: {
                                id: 250,
                                size: 268375,
                                path: require("../images/Files/gallery_west_street_3.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"b5d9325b41599c724505eecd62b5b206"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:45:19.000Z",
                                updatedAt: "2020-02-26T04:45:19.000Z",
                            },
                        },
                    },
                    {
                        id: 34,
                        imageGalleryId: 5,
                        imageId: 40,
                        createdAt: "2020-02-26T04:51:01.000Z",
                        updatedAt: "2020-02-26T04:51:01.000Z",
                        image: {
                            id: 40,
                            url: null,
                            fileId: 253,
                            thumbnailFileId: 252,
                            label: "gallery_west_street_4",
                            createdAt: "2020-02-26T04:34:52.000Z",
                            updatedAt: "2020-02-26T04:45:36.000Z",
                            thumbnailFile: {
                                id: 252,
                                size: 33939,
                                path: require("../images/Files/gallery_west_street_4_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"4ebf518578ef32ecb158c6022582215f"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:45:34.000Z",
                                updatedAt: "2020-02-26T04:45:34.000Z",
                            },
                            file: {
                                id: 253,
                                size: 229416,
                                path: require("../images/Files/gallery_west_street_4.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"4c1bbe7d06e30c6514e7be84b608b8b0"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:45:35.000Z",
                                updatedAt: "2020-02-26T04:45:35.000Z",
                            },
                        },
                    },
                    {
                        id: 35,
                        imageGalleryId: 5,
                        imageId: 41,
                        createdAt: "2020-02-26T04:51:05.000Z",
                        updatedAt: "2020-02-26T04:51:05.000Z",
                        image: {
                            id: 41,
                            url: null,
                            fileId: 255,
                            thumbnailFileId: 254,
                            label: "gallery_west_street_5",
                            createdAt: "2020-02-26T04:34:56.000Z",
                            updatedAt: "2020-02-26T04:45:48.000Z",
                            thumbnailFile: {
                                id: 254,
                                size: 40295,
                                path: require("../images/Files/gallery_west_street_5_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"0897b491fbedf135f07eae46dc228394"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:45:46.000Z",
                                updatedAt: "2020-02-26T04:45:46.000Z",
                            },
                            file: {
                                id: 255,
                                size: 262947,
                                path: require("../images/Files/gallery_west_street_5.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"a3ff7447e6de9ec90d0ff3c2cc73645b"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:45:47.000Z",
                                updatedAt: "2020-02-26T04:45:47.000Z",
                            },
                        },
                    },
                    {
                        id: 36,
                        imageGalleryId: 5,
                        imageId: 42,
                        createdAt: "2020-02-26T04:51:10.000Z",
                        updatedAt: "2020-02-26T04:51:10.000Z",
                        image: {
                            id: 42,
                            url: null,
                            fileId: 256,
                            thumbnailFileId: 257,
                            label: "gallery_west_street_6",
                            createdAt: "2020-02-26T04:34:58.000Z",
                            updatedAt: "2020-02-26T04:46:01.000Z",
                            thumbnailFile: {
                                id: 257,
                                size: 40085,
                                path: require("../images/Files/gallery_west_street_6_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"b12fad9357d4ab94fbc9b16b64d7accd"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:46:00.000Z",
                                updatedAt: "2020-02-26T04:46:00.000Z",
                            },
                            file: {
                                id: 256,
                                size: 264302,
                                path: require("../images/Files/gallery_west_street_6.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"e2c07d555dca64e4eb30a77bbb8968fe"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:45:58.000Z",
                                updatedAt: "2020-02-26T04:45:58.000Z",
                            },
                        },
                    },
                ],
            },
        },
        {
            id: 73,
            type: "imageGallery",
            content_id: 4,
            label: "Gallery - Treehouse",
            title_copy_id: 75,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-25T04:52:07.000Z",
            forceLarge: false,
            createdAt: "2020-02-26T04:52:07.000Z",
            updatedAt: "2020-02-26T04:52:16.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 220,
                    contentId: 73,
                    tagId: 26,
                    createdAt: "2020-02-26T04:52:18.000Z",
                    updatedAt: "2020-02-26T04:52:18.000Z",
                    tag: {
                        id: 26,
                        tag_id: "gallery",
                        label: "Gallery",
                        createdAt: "2020-02-26T04:50:05.000Z",
                        updatedAt: "2020-02-26T04:50:05.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 4,
                label: "Gallery - Treehouse",
                createdAt: "2020-02-26T04:48:52.000Z",
                updatedAt: "2020-02-26T04:52:05.000Z",
                imageGalleryImages: [
                    {
                        id: 37,
                        imageGalleryId: 4,
                        imageId: 31,
                        createdAt: "2020-02-26T04:51:47.000Z",
                        updatedAt: "2020-02-26T04:51:47.000Z",
                        image: {
                            id: 31,
                            url: null,
                            fileId: 234,
                            thumbnailFileId: 235,
                            label: "gallery_treehouse_1",
                            createdAt: "2020-02-26T04:34:22.000Z",
                            updatedAt: "2020-02-26T04:43:38.000Z",
                            thumbnailFile: {
                                id: 235,
                                size: 35717,
                                path: require("../images/Files/gallery_treehouse_1_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"30c257987106524fab0513e985ae24cc"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:43:37.000Z",
                                updatedAt: "2020-02-26T04:43:37.000Z",
                            },
                            file: {
                                id: 234,
                                size: 266387,
                                path: require("../images/Files/gallery_treehouse_1.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"e419717c245cc8c979da32a917ebd0b1"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:43:33.000Z",
                                updatedAt: "2020-02-26T04:43:33.000Z",
                            },
                        },
                    },
                    {
                        id: 38,
                        imageGalleryId: 4,
                        imageId: 32,
                        createdAt: "2020-02-26T04:51:50.000Z",
                        updatedAt: "2020-02-26T04:51:50.000Z",
                        image: {
                            id: 32,
                            url: null,
                            fileId: 236,
                            thumbnailFileId: 237,
                            label: "gallery_treehouse_2",
                            createdAt: "2020-02-26T04:34:24.000Z",
                            updatedAt: "2020-02-26T04:43:50.000Z",
                            thumbnailFile: {
                                id: 237,
                                size: 33939,
                                path: require("../images/Files/gallery_treehouse_2_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"653f7c75fc970916bca18a66ad8bce1b"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:43:48.000Z",
                                updatedAt: "2020-02-26T04:43:48.000Z",
                            },
                            file: {
                                id: 236,
                                size: 236138,
                                path: require("../images/Files/gallery_treehouse_2.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"ee56aa5ef52b2cb3cc088d3611db210b"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:43:45.000Z",
                                updatedAt: "2020-02-26T04:43:45.000Z",
                            },
                        },
                    },
                    {
                        id: 39,
                        imageGalleryId: 4,
                        imageId: 33,
                        createdAt: "2020-02-26T04:51:53.000Z",
                        updatedAt: "2020-02-26T04:51:53.000Z",
                        image: {
                            id: 33,
                            url: null,
                            fileId: 239,
                            thumbnailFileId: 238,
                            label: "gallery_treehouse_3",
                            createdAt: "2020-02-26T04:34:27.000Z",
                            updatedAt: "2020-02-26T04:44:02.000Z",
                            thumbnailFile: {
                                id: 238,
                                size: 27505,
                                path: require("../images/Files/gallery_treehouse_3_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"bfb66ac5cd475934ea302fcf8c4aac5c"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:43:57.000Z",
                                updatedAt: "2020-02-26T04:43:57.000Z",
                            },
                            file: {
                                id: 239,
                                size: 205241,
                                path: require("../images/Files/gallery_treehouse_3.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"94454db6079571860642feee3792bacd"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:44:01.000Z",
                                updatedAt: "2020-02-26T04:44:01.000Z",
                            },
                        },
                    },
                    {
                        id: 40,
                        imageGalleryId: 4,
                        imageId: 34,
                        createdAt: "2020-02-26T04:51:56.000Z",
                        updatedAt: "2020-02-26T04:51:56.000Z",
                        image: {
                            id: 34,
                            url: null,
                            fileId: 241,
                            thumbnailFileId: 240,
                            label: "gallery_treehouse_4",
                            createdAt: "2020-02-26T04:34:29.000Z",
                            updatedAt: "2020-02-26T04:44:13.000Z",
                            thumbnailFile: {
                                id: 240,
                                size: 25507,
                                path: require("../images/Files/gallery_treehouse_4_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"3e9dcca7e0fb34a8dd07af9b8d70e5fd"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:44:09.000Z",
                                updatedAt: "2020-02-26T04:44:09.000Z",
                            },
                            file: {
                                id: 241,
                                size: 187610,
                                path: require("../images/Files/gallery_treehouse_4.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"dab10858100f4c22dd6397ef913b1ba0"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:44:12.000Z",
                                updatedAt: "2020-02-26T04:44:12.000Z",
                            },
                        },
                    },
                    {
                        id: 41,
                        imageGalleryId: 4,
                        imageId: 35,
                        createdAt: "2020-02-26T04:51:59.000Z",
                        updatedAt: "2020-02-26T04:51:59.000Z",
                        image: {
                            id: 35,
                            url: null,
                            fileId: 242,
                            thumbnailFileId: 243,
                            label: "gallery_treehouse_5",
                            createdAt: "2020-02-26T04:34:32.000Z",
                            updatedAt: "2020-02-26T04:44:23.000Z",
                            thumbnailFile: {
                                id: 243,
                                size: 45958,
                                path: require("../images/Files/gallery_treehouse_5_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"2d2a13bfb16eb22246077a486f5dd035"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:44:22.000Z",
                                updatedAt: "2020-02-26T04:44:22.000Z",
                            },
                            file: {
                                id: 242,
                                size: 325699,
                                path: require("../images/Files/gallery_treehouse_5.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"961d56315638487c66b7a203e520dd5f"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:44:19.000Z",
                                updatedAt: "2020-02-26T04:44:19.000Z",
                            },
                        },
                    },
                    {
                        id: 42,
                        imageGalleryId: 4,
                        imageId: 36,
                        createdAt: "2020-02-26T04:52:01.000Z",
                        updatedAt: "2020-02-26T04:52:01.000Z",
                        image: {
                            id: 36,
                            url: null,
                            fileId: 245,
                            thumbnailFileId: 244,
                            label: "gallery_treehouse_6",
                            createdAt: "2020-02-26T04:34:34.000Z",
                            updatedAt: "2020-02-26T04:44:35.000Z",
                            thumbnailFile: {
                                id: 244,
                                size: 52028,
                                path: require("../images/Files/gallery_treehouse_6_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"f00fd8b96e7831d5fe6a9ae32792d46f"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:44:31.000Z",
                                updatedAt: "2020-02-26T04:44:31.000Z",
                            },
                            file: {
                                id: 245,
                                size: 381201,
                                path: require("../images/Files/gallery_treehouse_6.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"914dd8d18ecd23eed0256708ffff55fa"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:44:33.000Z",
                                updatedAt: "2020-02-26T04:44:33.000Z",
                            },
                        },
                    },
                ],
            },
        },
        {
            id: 74,
            type: "imageGallery",
            content_id: 3,
            label: "Gallery - The Wild",
            title_copy_id: 76,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-25T04:52:48.000Z",
            forceLarge: false,
            createdAt: "2020-02-26T04:52:48.000Z",
            updatedAt: "2020-02-26T04:52:55.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 221,
                    contentId: 74,
                    tagId: 26,
                    createdAt: "2020-02-26T04:52:58.000Z",
                    updatedAt: "2020-02-26T04:52:58.000Z",
                    tag: {
                        id: 26,
                        tag_id: "gallery",
                        label: "Gallery",
                        createdAt: "2020-02-26T04:50:05.000Z",
                        updatedAt: "2020-02-26T04:50:05.000Z",
                    },
                },
                {
                    id: 222,
                    contentId: 74,
                    tagId: 19,
                    createdAt: "2020-02-26T04:53:00.000Z",
                    updatedAt: "2020-02-26T04:53:00.000Z",
                    tag: {
                        id: 19,
                        tag_id: "thewild",
                        label: "The Wild",
                        createdAt: "2020-02-05T22:51:47.000Z",
                        updatedAt: "2020-02-05T22:51:47.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 3,
                label: "Gallery - The Wild",
                createdAt: "2020-02-26T04:48:45.000Z",
                updatedAt: "2020-02-26T04:52:28.000Z",
                imageGalleryImages: [
                    {
                        id: 43,
                        imageGalleryId: 3,
                        imageId: 25,
                        createdAt: "2020-02-26T04:52:31.000Z",
                        updatedAt: "2020-02-26T04:52:31.000Z",
                        image: {
                            id: 25,
                            url: null,
                            fileId: 222,
                            thumbnailFileId: 223,
                            label: "gallery_wild_1",
                            createdAt: "2020-02-26T04:33:39.000Z",
                            updatedAt: "2020-02-26T04:39:06.000Z",
                            thumbnailFile: {
                                id: 223,
                                size: 49698,
                                path: require("../images/Files/gallery_wild_1_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"a020bb5cfc1b83d114379c6003815813"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:39:04.000Z",
                                updatedAt: "2020-02-26T04:39:04.000Z",
                            },
                            file: {
                                id: 222,
                                size: 231543,
                                path: require("../images/Files/gallery_wild_1.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"449348b024b4b0baedfbdd866039858f"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:39:02.000Z",
                                updatedAt: "2020-02-26T04:39:02.000Z",
                            },
                        },
                    },
                    {
                        id: 44,
                        imageGalleryId: 3,
                        imageId: 26,
                        createdAt: "2020-02-26T04:52:34.000Z",
                        updatedAt: "2020-02-26T04:52:34.000Z",
                        image: {
                            id: 26,
                            url: null,
                            fileId: 224,
                            thumbnailFileId: 225,
                            label: "gallery_wild_2",
                            createdAt: "2020-02-26T04:33:41.000Z",
                            updatedAt: "2020-02-26T04:39:16.000Z",
                            thumbnailFile: {
                                id: 225,
                                size: 40953,
                                path: require("../images/Files/gallery_wild_2_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"3c0c55cb0b25c6d1521ac9466527b781"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:39:15.000Z",
                                updatedAt: "2020-02-26T04:39:15.000Z",
                            },
                            file: {
                                id: 224,
                                size: 210606,
                                path: require("../images/Files/gallery_wild_2.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"aea430765db3c63c90c7967e18e12cd4"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:39:12.000Z",
                                updatedAt: "2020-02-26T04:39:12.000Z",
                            },
                        },
                    },
                    {
                        id: 45,
                        imageGalleryId: 3,
                        imageId: 27,
                        createdAt: "2020-02-26T04:52:37.000Z",
                        updatedAt: "2020-02-26T04:52:37.000Z",
                        image: {
                            id: 27,
                            url: null,
                            fileId: 226,
                            thumbnailFileId: 227,
                            label: "gallery_wild_3",
                            createdAt: "2020-02-26T04:33:44.000Z",
                            updatedAt: "2020-02-26T04:39:27.000Z",
                            thumbnailFile: {
                                id: 227,
                                size: 37313,
                                path: require("../images/Files/gallery_wild_3_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"7ccae31482c2fdf8649ce0578924085f"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:39:26.000Z",
                                updatedAt: "2020-02-26T04:39:26.000Z",
                            },
                            file: {
                                id: 226,
                                size: 194874,
                                path: require("../images/Files/gallery_wild_3.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"242280802b7f16e5288319d1a5aae324"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:39:23.000Z",
                                updatedAt: "2020-02-26T04:39:23.000Z",
                            },
                        },
                    },
                    {
                        id: 46,
                        imageGalleryId: 3,
                        imageId: 28,
                        createdAt: "2020-02-26T04:52:40.000Z",
                        updatedAt: "2020-02-26T04:52:40.000Z",
                        image: {
                            id: 28,
                            url: null,
                            fileId: 229,
                            thumbnailFileId: 228,
                            label: "gallery_wild_4",
                            createdAt: "2020-02-26T04:33:47.000Z",
                            updatedAt: "2020-02-26T04:42:53.000Z",
                            thumbnailFile: {
                                id: 228,
                                size: 36444,
                                path: require("../images/Files/gallery_wild_4_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"5c63600a35f82b07157e5ac569cc107f"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:39:35.000Z",
                                updatedAt: "2020-02-26T04:39:35.000Z",
                            },
                            file: {
                                id: 229,
                                size: 167684,
                                path: require("../images/Files/gallery_wild_4.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"877719425852a208fd0b3186c70d30b3"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:39:41.000Z",
                                updatedAt: "2020-02-26T04:39:41.000Z",
                            },
                        },
                    },
                    {
                        id: 47,
                        imageGalleryId: 3,
                        imageId: 29,
                        createdAt: "2020-02-26T04:52:42.000Z",
                        updatedAt: "2020-02-26T04:52:42.000Z",
                        image: {
                            id: 29,
                            url: null,
                            fileId: 231,
                            thumbnailFileId: 230,
                            label: "gallery_wild_5",
                            createdAt: "2020-02-26T04:33:50.000Z",
                            updatedAt: "2020-02-26T04:43:04.000Z",
                            thumbnailFile: {
                                id: 230,
                                size: 28372,
                                path: require("../images/Files/gallery_wild_5_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"30a03e367aff51a4a176b7df23e835ce"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:43:00.000Z",
                                updatedAt: "2020-02-26T04:43:00.000Z",
                            },
                            file: {
                                id: 231,
                                size: 127700,
                                path: require("../images/Files/gallery_wild_5.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"dc45222c5acb5cc9a7cd90b10a7f65ac"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:43:03.000Z",
                                updatedAt: "2020-02-26T04:43:03.000Z",
                            },
                        },
                    },
                    {
                        id: 48,
                        imageGalleryId: 3,
                        imageId: 30,
                        createdAt: "2020-02-26T04:52:45.000Z",
                        updatedAt: "2020-02-26T04:52:45.000Z",
                        image: {
                            id: 30,
                            url: null,
                            fileId: 232,
                            thumbnailFileId: 233,
                            label: "gallery_wild_6",
                            createdAt: "2020-02-26T04:33:52.000Z",
                            updatedAt: "2020-02-26T04:43:17.000Z",
                            thumbnailFile: {
                                id: 233,
                                size: 26548,
                                path: require("../images/Files/gallery_wild_6_thumb.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"c9a22714b9afd5564b76beb67d651503"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:43:16.000Z",
                                updatedAt: "2020-02-26T04:43:16.000Z",
                            },
                            file: {
                                id: 232,
                                size: 123423,
                                path: require("../images/Files/gallery_wild_6.jpg"),
                                bucketId: "tfsc-hub-file-bucket",
                                eTag: '"561206e5975fbae01d12a03124d7099a"',
                                region: "ap-southeast-2",
                                credit: null,
                                createdAt: "2020-02-26T04:43:12.000Z",
                                updatedAt: "2020-02-26T04:43:12.000Z",
                            },
                        },
                    },
                ],
            },
        },
        {
            id: 75,
            type: "downloadable",
            content_id: 11,
            label: "Study Guide",
            title_copy_id: 77,
            titleTextCopyId: null,
            priority: 30,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-25T05:30:51.000Z",
            forceLarge: false,
            createdAt: "2020-02-26T05:30:51.000Z",
            updatedAt: "2020-02-26T05:31:02.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 226,
                    contentId: 75,
                    tagId: 14,
                    createdAt: "2020-02-26T05:31:06.000Z",
                    updatedAt: "2020-02-26T05:31:06.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 227,
                    contentId: 75,
                    tagId: 6,
                    createdAt: "2020-02-26T05:33:12.000Z",
                    updatedAt: "2020-02-26T05:33:12.000Z",
                    tag: {
                        id: 6,
                        tag_id: "downloadable",
                        label: "Downloadable",
                        createdAt: "2019-12-18T03:23:49.000Z",
                        updatedAt: "2019-12-18T03:23:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 11,
                url: "",
                label: "Study Guide",
                thumbnailFileId: 272,
                fileId: 271,
                createdAt: "2020-02-26T05:29:37.000Z",
                updatedAt: "2020-02-26T05:30:48.000Z",
                file: {
                    id: 271,
                    size: 9350952,
                    path: require("../images/Files/study_guide.pdf"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"70ecdf72839f61a3bd5127c796e60da0-2"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-26T05:30:44.000Z",
                    updatedAt: "2020-02-26T05:30:44.000Z",
                },
                thumbnailFile: {
                    id: 272,
                    size: 79803,
                    path: require("../images/Files/study_guide_thumb.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"5ec836f93a70e42d0cab2c0d44868876"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-26T05:30:47.000Z",
                    updatedAt: "2020-02-26T05:30:47.000Z",
                },
            },
        },
        {
            id: 76,
            type: "locProfile",
            content_id: 3,
            label: "The Wild",
            title_copy_id: 82,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-27T01:49:00.000Z",
            forceLarge: false,
            createdAt: "2020-02-27T01:50:41.000Z",
            updatedAt: "2020-02-27T01:58:25.000Z",
            titleTextCopy: null,
            contentTags: [],
            linkedContent: {
                id: 3,
                positionX: 1400,
                positionY: 1100,
                label: "The Wild",
                thumbnailFileId: 228,
                iconFileId: 189,
                description:
                    "Once known as a scary and forbidden place, now Sanctuary City accepts all creatures who come from the The Wild. Full of cheeky Drop Bears, singing Cane Toads and other unique beasts, The Wild is a place for exploration and reconnection with nature and animals from all walks of life. As Kerry, Yarra and Petra venture through on their quest to save the Wishmas Tree, they pass through many distinct landscapes including; the Forbidden Swamp (home to Augustus the Cane Toad), the Dropbear Camp (where they find their ferocious friend Bernard), and the Misty Muds where mystical blue lava bubbles shrouds the earth. The Wild is also home to the fabled sister Wishmas Tree, where Kerry must go to restore Sanctuary City and the dying Wishmas Tree. \n\nSanctuary Fact: The design of The Misty Muds are based on the Giant’s Causeway in Northern Ireland!",
                imageFileId: 229,
                createdAt: "2020-02-27T01:49:15.000Z",
                updatedAt: "2020-02-27T02:02:36.000Z",
                thumbnailFile: {
                    id: 228,
                    size: 36444,
                    path: require("../images/Files/gallery_wild_4_thumb.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"5c63600a35f82b07157e5ac569cc107f"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-26T04:39:35.000Z",
                    updatedAt: "2020-02-26T04:39:35.000Z",
                },
                iconFile: {
                    id: 189,
                    size: 5233,
                    path: require("../images/Files/mapIcon.png"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"59b8c483a5f5817ec71510a88725d641"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-26T00:52:25.000Z",
                    updatedAt: "2020-02-26T00:52:25.000Z",
                },
                file: {
                    id: 229,
                    size: 167684,
                    path: require("../images/Files/gallery_wild_4.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"877719425852a208fd0b3186c70d30b3"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-26T04:39:41.000Z",
                    updatedAt: "2020-02-26T04:39:41.000Z",
                },
                locationProfileContent: [
                    {
                        id: 9,
                        contentId: 74,
                        locationProfileId: 3,
                        createdAt: "2020-02-27T01:54:12.000Z",
                        updatedAt: "2020-02-27T01:54:12.000Z",
                        content: {
                            id: 74,
                            type: "imageGallery",
                            content_id: 3,
                            label: "Gallery - The Wild",
                            title_copy_id: 76,
                            titleTextCopyId: null,
                            priority: 0,
                            expands: false,
                            isPublic: 1,
                            publishedAt: "2020-02-25T04:52:48.000Z",
                            forceLarge: false,
                            createdAt: "2020-02-26T04:52:48.000Z",
                            updatedAt: "2020-02-26T04:52:55.000Z",
                        },
                    },
                ],
            },
        },
        {
            id: 77,
            type: "locProfile",
            content_id: 4,
            label: "West Street",
            title_copy_id: 83,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-02-27T01:49:00.000Z",
            forceLarge: false,
            createdAt: "2020-02-27T01:50:56.000Z",
            updatedAt: "2020-02-27T02:07:30.000Z",
            titleTextCopy: null,
            contentTags: [],
            linkedContent: {
                id: 4,
                positionX: 1925,
                positionY: 650,
                label: "West Street",
                thumbnailFileId: 249,
                iconFileId: 189,
                description:
                    "West Street is the cultural hub of Sanctuary City, featuring food stalls, music, museums and galleries. Top picks for locals include the “Bite Club” noodle bar and the “Wonders of the Wild” museum where school groups go to learn about the world outside their walls. West Street is the walkway through to the business precinct of the city (known as Sanctuary Plaza) and provides a fun, social space for busy working residents to unwind during the day or night! \n\nBefore venturing into The Wild herself, Kerry loved to visit the museum to admire the scenes and displays imagined up by the curators to scare off residents thinking of leaving the city walls. These days, she’s an expert on The Wild and its many amazing creatures and places, just ask her about it!   \n\nSanctuary Fact: Once known as “Terrors of the Wild”, the name of the museum was changed after the opening of the gates in the spirit of inclusivity and to celebrate the welcoming of new animals into the city.",
                imageFileId: 248,
                createdAt: "2020-02-27T01:49:44.000Z",
                updatedAt: "2020-02-27T02:08:23.000Z",
                thumbnailFile: {
                    id: 249,
                    size: 45538,
                    path: require("../images/Files/gallery_west_street_2_thumb.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"1e78ce3dcccd30fc32fff1f3fe81a764"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-26T04:45:11.000Z",
                    updatedAt: "2020-02-26T04:45:11.000Z",
                },
                iconFile: {
                    id: 189,
                    size: 5233,
                    path: require("../images/Files/mapIcon.png"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"59b8c483a5f5817ec71510a88725d641"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-26T00:52:25.000Z",
                    updatedAt: "2020-02-26T00:52:25.000Z",
                },
                file: {
                    id: 248,
                    size: 307525,
                    path: require("../images/Files/gallery_west_street_2.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"1ca2c1de63edad53ead377c76368df05"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-02-26T04:45:08.000Z",
                    updatedAt: "2020-02-26T04:45:08.000Z",
                },
                locationProfileContent: [
                    {
                        id: 10,
                        contentId: 72,
                        locationProfileId: 4,
                        createdAt: "2020-02-27T02:05:31.000Z",
                        updatedAt: "2020-02-27T02:05:31.000Z",
                        content: {
                            id: 72,
                            type: "imageGallery",
                            content_id: 5,
                            label: "Gallery - West Street",
                            title_copy_id: 74,
                            titleTextCopyId: null,
                            priority: 0,
                            expands: false,
                            isPublic: 1,
                            publishedAt: "2020-02-25T04:51:17.000Z",
                            forceLarge: false,
                            createdAt: "2020-02-26T04:51:17.000Z",
                            updatedAt: "2020-02-26T04:51:25.000Z",
                        },
                    },
                ],
            },
        },
        {
            id: 78,
            type: "video",
            content_id: 42,
            label: "The Wishmas Tree Trailer",
            title_copy_id: 84,
            titleTextCopyId: null,
            priority: 100,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-03-19T00:19:18.000Z",
            forceLarge: false,
            createdAt: "2020-03-26T00:19:18.000Z",
            updatedAt: "2020-03-26T00:20:36.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 229,
                    contentId: 78,
                    tagId: 14,
                    createdAt: "2020-03-26T00:19:47.000Z",
                    updatedAt: "2020-03-26T00:19:47.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 230,
                    contentId: 78,
                    tagId: 2,
                    createdAt: "2020-03-26T00:19:50.000Z",
                    updatedAt: "2020-03-26T00:19:50.000Z",
                    tag: {
                        id: 2,
                        tag_id: "video",
                        label: "Video",
                        createdAt: "2019-12-18T03:19:24.000Z",
                        updatedAt: "2019-12-18T03:19:24.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 42,
                url: "https://www.youtube.com/embed/bHa5Qb1UrfA",
                thumbnailFileId: 299,
                label: "The Wishmas Tree Trailer",
                createdAt: "2020-03-26T00:19:12.000Z",
                updatedAt: "2020-03-26T00:52:09.000Z",
                thumbnailFile: {
                    id: 299,
                    size: 27753,
                    path: require("../images/Files/twt_trailer_thumb.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"8f191b96c3eca451c2a54398d5633074"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-03-26T00:52:06.000Z",
                    updatedAt: "2020-03-26T00:52:06.000Z",
                },
            },
        },
        {
            id: 79,
            type: "downloadable",
            content_id: 12,
            label: "DIY Recipe - Misty Muds Bath Bombs",
            title_copy_id: 85,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-03-30T00:37:54.000Z",
            forceLarge: false,
            createdAt: "2020-03-31T00:37:54.000Z",
            updatedAt: "2020-03-31T00:39:20.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 231,
                    contentId: 79,
                    tagId: 18,
                    createdAt: "2020-03-31T00:39:07.000Z",
                    updatedAt: "2020-03-31T00:39:07.000Z",
                    tag: {
                        id: 18,
                        tag_id: "recipe",
                        label: "Recipe",
                        createdAt: "2020-02-04T01:26:33.000Z",
                        updatedAt: "2020-02-04T01:26:33.000Z",
                    },
                },
                {
                    id: 232,
                    contentId: 79,
                    tagId: 8,
                    createdAt: "2020-03-31T00:39:11.000Z",
                    updatedAt: "2020-03-31T00:39:11.000Z",
                    tag: {
                        id: 8,
                        tag_id: "diy",
                        label: "DIY",
                        createdAt: "2020-02-04T01:14:13.000Z",
                        updatedAt: "2020-02-04T01:14:13.000Z",
                    },
                },
                {
                    id: 265,
                    contentId: 79,
                    tagId: 6,
                    createdAt: "2020-03-31T00:45:32.000Z",
                    updatedAt: "2020-03-31T00:45:32.000Z",
                    tag: {
                        id: 6,
                        tag_id: "downloadable",
                        label: "Downloadable",
                        createdAt: "2019-12-18T03:23:49.000Z",
                        updatedAt: "2019-12-18T03:23:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 12,
                url: "",
                label: "DIY Recipe - Misty Muds Bath Bombs",
                thumbnailFileId: 300,
                fileId: 301,
                createdAt: "2020-03-31T00:33:19.000Z",
                updatedAt: "2020-03-31T00:35:25.000Z",
                file: {
                    id: 301,
                    size: 1936132,
                    path: require("../images/Files/diy_recipe_bath_bombs.pdf"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"481d975317c387d999f019eaddcd14cb"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-03-31T00:35:24.000Z",
                    updatedAt: "2020-03-31T00:35:24.000Z",
                },
                thumbnailFile: {
                    id: 300,
                    size: 178107,
                    path: require("../images/Files/diy_recipe_bath_bombs_thumbnail.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"7414d133045d23e6d549e8348b8fc30d"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-03-31T00:35:20.000Z",
                    updatedAt: "2020-03-31T00:35:20.000Z",
                },
            },
        },
        {
            id: 80,
            type: "downloadable",
            content_id: 13,
            label: "DIY Recipe - Bio Bird Feeder",
            title_copy_id: 86,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-03-30T00:38:02.000Z",
            forceLarge: false,
            createdAt: "2020-03-31T00:38:02.000Z",
            updatedAt: "2020-03-31T00:39:36.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 233,
                    contentId: 80,
                    tagId: 8,
                    createdAt: "2020-03-31T00:39:30.000Z",
                    updatedAt: "2020-03-31T00:39:30.000Z",
                    tag: {
                        id: 8,
                        tag_id: "diy",
                        label: "DIY",
                        createdAt: "2020-02-04T01:14:13.000Z",
                        updatedAt: "2020-02-04T01:14:13.000Z",
                    },
                },
                {
                    id: 234,
                    contentId: 80,
                    tagId: 18,
                    createdAt: "2020-03-31T00:39:32.000Z",
                    updatedAt: "2020-03-31T00:39:32.000Z",
                    tag: {
                        id: 18,
                        tag_id: "recipe",
                        label: "Recipe",
                        createdAt: "2020-02-04T01:26:33.000Z",
                        updatedAt: "2020-02-04T01:26:33.000Z",
                    },
                },
                {
                    id: 264,
                    contentId: 80,
                    tagId: 6,
                    createdAt: "2020-03-31T00:45:25.000Z",
                    updatedAt: "2020-03-31T00:45:25.000Z",
                    tag: {
                        id: 6,
                        tag_id: "downloadable",
                        label: "Downloadable",
                        createdAt: "2019-12-18T03:23:49.000Z",
                        updatedAt: "2019-12-18T03:23:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 13,
                url: "",
                label: "DIY Recipe - Bio Bird Feeder",
                thumbnailFileId: 302,
                fileId: 303,
                createdAt: "2020-03-31T00:33:29.000Z",
                updatedAt: "2020-03-31T00:35:41.000Z",
                file: {
                    id: 303,
                    size: 358746,
                    path: require("../images/Files/diy_recipe_bio_bird_feeder.pdf"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"adaf24b4657dda8866fbfa6f8ff4ea16"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-03-31T00:35:40.000Z",
                    updatedAt: "2020-03-31T00:35:40.000Z",
                },
                thumbnailFile: {
                    id: 302,
                    size: 154093,
                    path: require("../images/Files/diy_recipe_bio_bird_feeder_thumbnail_alt.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"9241e0fa9862fdcd1fc4c4f352dfb930"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-03-31T00:35:36.000Z",
                    updatedAt: "2020-03-31T00:35:36.000Z",
                },
            },
        },
        {
            id: 81,
            type: "downloadable",
            content_id: 14,
            label: "DIY Recipe - Crocodile Cookies",
            title_copy_id: 87,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-03-30T00:38:06.000Z",
            forceLarge: false,
            createdAt: "2020-03-31T00:38:06.000Z",
            updatedAt: "2020-03-31T00:39:48.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 235,
                    contentId: 81,
                    tagId: 8,
                    createdAt: "2020-03-31T00:39:43.000Z",
                    updatedAt: "2020-03-31T00:39:43.000Z",
                    tag: {
                        id: 8,
                        tag_id: "diy",
                        label: "DIY",
                        createdAt: "2020-02-04T01:14:13.000Z",
                        updatedAt: "2020-02-04T01:14:13.000Z",
                    },
                },
                {
                    id: 236,
                    contentId: 81,
                    tagId: 18,
                    createdAt: "2020-03-31T00:39:45.000Z",
                    updatedAt: "2020-03-31T00:39:45.000Z",
                    tag: {
                        id: 18,
                        tag_id: "recipe",
                        label: "Recipe",
                        createdAt: "2020-02-04T01:26:33.000Z",
                        updatedAt: "2020-02-04T01:26:33.000Z",
                    },
                },
                {
                    id: 263,
                    contentId: 81,
                    tagId: 6,
                    createdAt: "2020-03-31T00:45:18.000Z",
                    updatedAt: "2020-03-31T00:45:18.000Z",
                    tag: {
                        id: 6,
                        tag_id: "downloadable",
                        label: "Downloadable",
                        createdAt: "2019-12-18T03:23:49.000Z",
                        updatedAt: "2019-12-18T03:23:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 14,
                url: "",
                label: "DIY Recipe - Crocodile Cookies",
                thumbnailFileId: 318,
                fileId: 319,
                createdAt: "2020-03-31T00:33:36.000Z",
                updatedAt: "2020-03-31T00:37:47.000Z",
                file: {
                    id: 319,
                    size: 1662835,
                    path: require("../images/Files/diy_recipe_crocodile_cookies.pdf"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"d7c27cf80a1931f9c7f17d50c7585ffa"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-03-31T00:37:46.000Z",
                    updatedAt: "2020-03-31T00:37:46.000Z",
                },
                thumbnailFile: {
                    id: 318,
                    size: 146620,
                    path: require("../images/Files/diy_recipe_crocodile_cookies_thumbnail.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"25f3a4efde39238ff88e70ac1fd4eb70"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-03-31T00:37:42.000Z",
                    updatedAt: "2020-03-31T00:37:42.000Z",
                },
            },
        },
        {
            id: 82,
            type: "downloadable",
            content_id: 15,
            label: "DIY Recipe - Dropbear Mobile",
            title_copy_id: 88,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-03-30T00:38:10.000Z",
            forceLarge: false,
            createdAt: "2020-03-31T00:38:10.000Z",
            updatedAt: "2020-03-31T00:40:05.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 237,
                    contentId: 82,
                    tagId: 8,
                    createdAt: "2020-03-31T00:39:58.000Z",
                    updatedAt: "2020-03-31T00:39:58.000Z",
                    tag: {
                        id: 8,
                        tag_id: "diy",
                        label: "DIY",
                        createdAt: "2020-02-04T01:14:13.000Z",
                        updatedAt: "2020-02-04T01:14:13.000Z",
                    },
                },
                {
                    id: 238,
                    contentId: 82,
                    tagId: 18,
                    createdAt: "2020-03-31T00:40:00.000Z",
                    updatedAt: "2020-03-31T00:40:00.000Z",
                    tag: {
                        id: 18,
                        tag_id: "recipe",
                        label: "Recipe",
                        createdAt: "2020-02-04T01:26:33.000Z",
                        updatedAt: "2020-02-04T01:26:33.000Z",
                    },
                },
                {
                    id: 239,
                    contentId: 82,
                    tagId: 20,
                    createdAt: "2020-03-31T00:40:01.000Z",
                    updatedAt: "2020-03-31T00:40:01.000Z",
                    tag: {
                        id: 20,
                        tag_id: "dropbears",
                        label: "Dropbears",
                        createdAt: "2020-02-05T22:51:57.000Z",
                        updatedAt: "2020-02-05T22:51:57.000Z",
                    },
                },
                {
                    id: 262,
                    contentId: 82,
                    tagId: 6,
                    createdAt: "2020-03-31T00:45:11.000Z",
                    updatedAt: "2020-03-31T00:45:11.000Z",
                    tag: {
                        id: 6,
                        tag_id: "downloadable",
                        label: "Downloadable",
                        createdAt: "2019-12-18T03:23:49.000Z",
                        updatedAt: "2019-12-18T03:23:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 15,
                url: "",
                label: "DIY Recipe - Dropbear Mobile",
                thumbnailFileId: 316,
                fileId: 317,
                createdAt: "2020-03-31T00:33:42.000Z",
                updatedAt: "2020-03-31T00:37:33.000Z",
                file: {
                    id: 317,
                    size: 1908931,
                    path: require("../images/Files/diy_recipe_dropbear_mobile.pdf"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"cc2e8c56ff722d18b5b000a24892460b"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-03-31T00:37:32.000Z",
                    updatedAt: "2020-03-31T00:37:32.000Z",
                },
                thumbnailFile: {
                    id: 316,
                    size: 136857,
                    path: require("../images/Files/diy_recipe_dropbear_mobile_thumbnail_alt.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"66793cc5df2128235ac7b7f2b0fb453b"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-03-31T00:37:27.000Z",
                    updatedAt: "2020-03-31T00:37:27.000Z",
                },
            },
        },
        {
            id: 83,
            type: "downloadable",
            content_id: 16,
            label: "DIY Recipe - Dropbear Snot",
            title_copy_id: 89,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-03-30T00:38:16.000Z",
            forceLarge: false,
            createdAt: "2020-03-31T00:38:16.000Z",
            updatedAt: "2020-03-31T00:40:22.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 240,
                    contentId: 83,
                    tagId: 8,
                    createdAt: "2020-03-31T00:40:12.000Z",
                    updatedAt: "2020-03-31T00:40:12.000Z",
                    tag: {
                        id: 8,
                        tag_id: "diy",
                        label: "DIY",
                        createdAt: "2020-02-04T01:14:13.000Z",
                        updatedAt: "2020-02-04T01:14:13.000Z",
                    },
                },
                {
                    id: 242,
                    contentId: 83,
                    tagId: 18,
                    createdAt: "2020-03-31T00:40:17.000Z",
                    updatedAt: "2020-03-31T00:40:17.000Z",
                    tag: {
                        id: 18,
                        tag_id: "recipe",
                        label: "Recipe",
                        createdAt: "2020-02-04T01:26:33.000Z",
                        updatedAt: "2020-02-04T01:26:33.000Z",
                    },
                },
                {
                    id: 243,
                    contentId: 83,
                    tagId: 20,
                    createdAt: "2020-03-31T00:40:19.000Z",
                    updatedAt: "2020-03-31T00:40:19.000Z",
                    tag: {
                        id: 20,
                        tag_id: "dropbears",
                        label: "Dropbears",
                        createdAt: "2020-02-05T22:51:57.000Z",
                        updatedAt: "2020-02-05T22:51:57.000Z",
                    },
                },
                {
                    id: 261,
                    contentId: 83,
                    tagId: 6,
                    createdAt: "2020-03-31T00:45:05.000Z",
                    updatedAt: "2020-03-31T00:45:05.000Z",
                    tag: {
                        id: 6,
                        tag_id: "downloadable",
                        label: "Downloadable",
                        createdAt: "2019-12-18T03:23:49.000Z",
                        updatedAt: "2019-12-18T03:23:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 16,
                url: "",
                label: "DIY Recipe - Dropbear Snot",
                thumbnailFileId: 314,
                fileId: 315,
                createdAt: "2020-03-31T00:33:48.000Z",
                updatedAt: "2020-03-31T00:37:18.000Z",
                file: {
                    id: 315,
                    size: 2132645,
                    path: require("../images/Files/diy_recipe_dropbear_snot.pdf"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"1e0d2638d2c3177371b8a8b1d73eb414"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-03-31T00:37:17.000Z",
                    updatedAt: "2020-03-31T00:37:17.000Z",
                },
                thumbnailFile: {
                    id: 314,
                    size: 153828,
                    path: require("../images/Files/diy_recipe_dropbear_snot_thumbnail.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"aee05973e11d99d1ee08054e59e53f3e"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-03-31T00:37:13.000Z",
                    updatedAt: "2020-03-31T00:37:13.000Z",
                },
            },
        },
        {
            id: 84,
            type: "downloadable",
            content_id: 17,
            label: "DIY Recipe - Natural Gummy Dropbears",
            title_copy_id: 90,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-03-30T00:38:20.000Z",
            forceLarge: false,
            createdAt: "2020-03-31T00:38:20.000Z",
            updatedAt: "2020-03-31T00:40:43.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 244,
                    contentId: 84,
                    tagId: 8,
                    createdAt: "2020-03-31T00:40:37.000Z",
                    updatedAt: "2020-03-31T00:40:37.000Z",
                    tag: {
                        id: 8,
                        tag_id: "diy",
                        label: "DIY",
                        createdAt: "2020-02-04T01:14:13.000Z",
                        updatedAt: "2020-02-04T01:14:13.000Z",
                    },
                },
                {
                    id: 245,
                    contentId: 84,
                    tagId: 18,
                    createdAt: "2020-03-31T00:40:38.000Z",
                    updatedAt: "2020-03-31T00:40:38.000Z",
                    tag: {
                        id: 18,
                        tag_id: "recipe",
                        label: "Recipe",
                        createdAt: "2020-02-04T01:26:33.000Z",
                        updatedAt: "2020-02-04T01:26:33.000Z",
                    },
                },
                {
                    id: 246,
                    contentId: 84,
                    tagId: 20,
                    createdAt: "2020-03-31T00:40:40.000Z",
                    updatedAt: "2020-03-31T00:40:40.000Z",
                    tag: {
                        id: 20,
                        tag_id: "dropbears",
                        label: "Dropbears",
                        createdAt: "2020-02-05T22:51:57.000Z",
                        updatedAt: "2020-02-05T22:51:57.000Z",
                    },
                },
                {
                    id: 260,
                    contentId: 84,
                    tagId: 6,
                    createdAt: "2020-03-31T00:44:54.000Z",
                    updatedAt: "2020-03-31T00:44:54.000Z",
                    tag: {
                        id: 6,
                        tag_id: "downloadable",
                        label: "Downloadable",
                        createdAt: "2019-12-18T03:23:49.000Z",
                        updatedAt: "2019-12-18T03:23:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 17,
                url: "",
                label: "DIY Recipe - Natural Gummy Dropbears",
                thumbnailFileId: 312,
                fileId: 313,
                createdAt: "2020-03-31T00:34:06.000Z",
                updatedAt: "2020-03-31T00:37:03.000Z",
                file: {
                    id: 313,
                    size: 2098619,
                    path: require("../images/Files/diy_recipe_gummy_bears.pdf"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"65dc4497d95707568a7fc193d92c44e0"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-03-31T00:37:02.000Z",
                    updatedAt: "2020-03-31T00:37:02.000Z",
                },
                thumbnailFile: {
                    id: 312,
                    size: 152273,
                    path: require("../images/Files/diy_recipe_gummy_bears_thumbnail.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"8e67f68c73339cba41d70a046dfa30b5"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-03-31T00:36:55.000Z",
                    updatedAt: "2020-03-31T00:36:55.000Z",
                },
            },
        },
        {
            id: 85,
            type: "downloadable",
            content_id: 18,
            label: "DIY Recipe - Koala Cookies",
            title_copy_id: 91,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-03-30T00:38:24.000Z",
            forceLarge: false,
            createdAt: "2020-03-31T00:38:24.000Z",
            updatedAt: "2020-03-31T00:40:59.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 247,
                    contentId: 85,
                    tagId: 8,
                    createdAt: "2020-03-31T00:40:50.000Z",
                    updatedAt: "2020-03-31T00:40:50.000Z",
                    tag: {
                        id: 8,
                        tag_id: "diy",
                        label: "DIY",
                        createdAt: "2020-02-04T01:14:13.000Z",
                        updatedAt: "2020-02-04T01:14:13.000Z",
                    },
                },
                {
                    id: 248,
                    contentId: 85,
                    tagId: 18,
                    createdAt: "2020-03-31T00:40:55.000Z",
                    updatedAt: "2020-03-31T00:40:55.000Z",
                    tag: {
                        id: 18,
                        tag_id: "recipe",
                        label: "Recipe",
                        createdAt: "2020-02-04T01:26:33.000Z",
                        updatedAt: "2020-02-04T01:26:33.000Z",
                    },
                },
                {
                    id: 259,
                    contentId: 85,
                    tagId: 6,
                    createdAt: "2020-03-31T00:44:47.000Z",
                    updatedAt: "2020-03-31T00:44:47.000Z",
                    tag: {
                        id: 6,
                        tag_id: "downloadable",
                        label: "Downloadable",
                        createdAt: "2019-12-18T03:23:49.000Z",
                        updatedAt: "2019-12-18T03:23:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 18,
                url: "",
                label: "DIY Recipe - Koala Cookies",
                thumbnailFileId: 310,
                fileId: 311,
                createdAt: "2020-03-31T00:34:12.000Z",
                updatedAt: "2020-03-31T00:36:45.000Z",
                file: {
                    id: 311,
                    size: 2037870,
                    path: require("../images/Files/diy_recipe_koala_cookies.pdf"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"a8c110383e804940bf7a500096440f81"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-03-31T00:36:44.000Z",
                    updatedAt: "2020-03-31T00:36:44.000Z",
                },
                thumbnailFile: {
                    id: 310,
                    size: 151364,
                    path: require("../images/Files/diy_recipe_koala_cookies_thumbnail.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"b21b7870490b2c8410374a02f8554b80"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-03-31T00:36:38.000Z",
                    updatedAt: "2020-03-31T00:36:38.000Z",
                },
            },
        },
        {
            id: 86,
            type: "downloadable",
            content_id: 19,
            label: "DIY Recipe - Swamp-in-a-Mug Cakes",
            title_copy_id: 92,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-03-30T00:38:29.000Z",
            forceLarge: false,
            createdAt: "2020-03-31T00:38:29.000Z",
            updatedAt: "2020-03-31T00:41:09.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 249,
                    contentId: 86,
                    tagId: 8,
                    createdAt: "2020-03-31T00:41:12.000Z",
                    updatedAt: "2020-03-31T00:41:12.000Z",
                    tag: {
                        id: 8,
                        tag_id: "diy",
                        label: "DIY",
                        createdAt: "2020-02-04T01:14:13.000Z",
                        updatedAt: "2020-02-04T01:14:13.000Z",
                    },
                },
                {
                    id: 250,
                    contentId: 86,
                    tagId: 18,
                    createdAt: "2020-03-31T00:41:13.000Z",
                    updatedAt: "2020-03-31T00:41:13.000Z",
                    tag: {
                        id: 18,
                        tag_id: "recipe",
                        label: "Recipe",
                        createdAt: "2020-02-04T01:26:33.000Z",
                        updatedAt: "2020-02-04T01:26:33.000Z",
                    },
                },
                {
                    id: 258,
                    contentId: 86,
                    tagId: 6,
                    createdAt: "2020-03-31T00:44:41.000Z",
                    updatedAt: "2020-03-31T00:44:41.000Z",
                    tag: {
                        id: 6,
                        tag_id: "downloadable",
                        label: "Downloadable",
                        createdAt: "2019-12-18T03:23:49.000Z",
                        updatedAt: "2019-12-18T03:23:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 19,
                url: "",
                label: "DIY Recipe - Swamp-in-a-Mug Cakes",
                thumbnailFileId: 308,
                fileId: 309,
                createdAt: "2020-03-31T00:34:27.000Z",
                updatedAt: "2020-03-31T00:36:29.000Z",
                file: {
                    id: 309,
                    size: 1836674,
                    path: require("../images/Files/diy_recipe_mug_cakes.pdf"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"52efb5bce665cb9bf21d72251ba64825"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-03-31T00:36:28.000Z",
                    updatedAt: "2020-03-31T00:36:28.000Z",
                },
                thumbnailFile: {
                    id: 308,
                    size: 154938,
                    path: require("../images/Files/diy_recipe_mug_cakes_thumbnail.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"832bf76f10b0861eaa69a84dbedbd95b"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-03-31T00:36:23.000Z",
                    updatedAt: "2020-03-31T00:36:23.000Z",
                },
            },
        },
        {
            id: 87,
            type: "downloadable",
            content_id: 20,
            label: "DIY Recipe - Tiny Turtles",
            title_copy_id: 93,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-03-30T00:38:35.000Z",
            forceLarge: false,
            createdAt: "2020-03-31T00:38:35.000Z",
            updatedAt: "2020-03-31T00:41:23.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 251,
                    contentId: 87,
                    tagId: 8,
                    createdAt: "2020-03-31T00:41:26.000Z",
                    updatedAt: "2020-03-31T00:41:26.000Z",
                    tag: {
                        id: 8,
                        tag_id: "diy",
                        label: "DIY",
                        createdAt: "2020-02-04T01:14:13.000Z",
                        updatedAt: "2020-02-04T01:14:13.000Z",
                    },
                },
                {
                    id: 252,
                    contentId: 87,
                    tagId: 18,
                    createdAt: "2020-03-31T00:41:27.000Z",
                    updatedAt: "2020-03-31T00:41:27.000Z",
                    tag: {
                        id: 18,
                        tag_id: "recipe",
                        label: "Recipe",
                        createdAt: "2020-02-04T01:26:33.000Z",
                        updatedAt: "2020-02-04T01:26:33.000Z",
                    },
                },
                {
                    id: 257,
                    contentId: 87,
                    tagId: 6,
                    createdAt: "2020-03-31T00:44:35.000Z",
                    updatedAt: "2020-03-31T00:44:35.000Z",
                    tag: {
                        id: 6,
                        tag_id: "downloadable",
                        label: "Downloadable",
                        createdAt: "2019-12-18T03:23:49.000Z",
                        updatedAt: "2019-12-18T03:23:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 20,
                url: "",
                label: "DIY Recipe - Tiny Turtles",
                thumbnailFileId: 306,
                fileId: 307,
                createdAt: "2020-03-31T00:34:32.000Z",
                updatedAt: "2020-03-31T00:36:12.000Z",
                file: {
                    id: 307,
                    size: 2126264,
                    path: require("../images/Files/diy_recipe_tiny_turtles.pdf"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"018b2f59792c2454de7ced38a8feebd5"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-03-31T00:36:10.000Z",
                    updatedAt: "2020-03-31T00:36:10.000Z",
                },
                thumbnailFile: {
                    id: 306,
                    size: 193230,
                    path: require("../images/Files/diy_recipe_tiny_turtles_thumbnail.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"6f4e4f7eaff7b9a644ef73d5ea190631"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-03-31T00:36:06.000Z",
                    updatedAt: "2020-03-31T00:36:06.000Z",
                },
            },
        },
        {
            id: 88,
            type: "downloadable",
            content_id: 21,
            label: "DIY Recipe - Wishmas Flower",
            title_copy_id: 94,
            titleTextCopyId: null,
            priority: 0,
            expands: false,
            isPublic: 1,
            publishedAt: "2020-03-30T00:38:39.000Z",
            forceLarge: false,
            createdAt: "2020-03-31T00:38:39.000Z",
            updatedAt: "2020-03-31T00:41:42.000Z",
            titleTextCopy: null,
            contentTags: [
                {
                    id: 253,
                    contentId: 88,
                    tagId: 8,
                    createdAt: "2020-03-31T00:41:34.000Z",
                    updatedAt: "2020-03-31T00:41:34.000Z",
                    tag: {
                        id: 8,
                        tag_id: "diy",
                        label: "DIY",
                        createdAt: "2020-02-04T01:14:13.000Z",
                        updatedAt: "2020-02-04T01:14:13.000Z",
                    },
                },
                {
                    id: 254,
                    contentId: 88,
                    tagId: 18,
                    createdAt: "2020-03-31T00:41:35.000Z",
                    updatedAt: "2020-03-31T00:41:35.000Z",
                    tag: {
                        id: 18,
                        tag_id: "recipe",
                        label: "Recipe",
                        createdAt: "2020-02-04T01:26:33.000Z",
                        updatedAt: "2020-02-04T01:26:33.000Z",
                    },
                },
                {
                    id: 255,
                    contentId: 88,
                    tagId: 14,
                    createdAt: "2020-03-31T00:41:37.000Z",
                    updatedAt: "2020-03-31T00:41:37.000Z",
                    tag: {
                        id: 14,
                        tag_id: "wishmastree",
                        label: "Wishmas Tree",
                        createdAt: "2020-02-04T01:25:49.000Z",
                        updatedAt: "2020-02-04T01:25:49.000Z",
                    },
                },
                {
                    id: 256,
                    contentId: 88,
                    tagId: 6,
                    createdAt: "2020-03-31T00:44:28.000Z",
                    updatedAt: "2020-03-31T00:44:28.000Z",
                    tag: {
                        id: 6,
                        tag_id: "downloadable",
                        label: "Downloadable",
                        createdAt: "2019-12-18T03:23:49.000Z",
                        updatedAt: "2019-12-18T03:23:49.000Z",
                    },
                },
            ],
            linkedContent: {
                id: 21,
                url: "",
                label: "DIY Recipe - Wishmas Flower",
                thumbnailFileId: 304,
                fileId: 305,
                createdAt: "2020-03-31T00:34:42.000Z",
                updatedAt: "2020-03-31T00:35:57.000Z",
                file: {
                    id: 305,
                    size: 2209564,
                    path: require("../images/Files/diy_recipe_wishmas_flower.pdf"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"587e68179dca06877eb1cc6ed950a036"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-03-31T00:35:56.000Z",
                    updatedAt: "2020-03-31T00:35:56.000Z",
                },
                thumbnailFile: {
                    id: 304,
                    size: 172597,
                    path: require("../images/Files/diy_recipe_wishmas_flower_thumbnail.jpg"),
                    bucketId: "tfsc-hub-file-bucket",
                    eTag: '"4d499a9800ee2a5c6db333f8e0464c16"',
                    region: "ap-southeast-2",
                    credit: null,
                    createdAt: "2020-03-31T00:35:52.000Z",
                    updatedAt: "2020-03-31T00:35:52.000Z",
                },
            },
        },
    ],
};
